<template>
  <b-modal v-model=isModalVisibleComputed
           id="remove-monthly-newsletter-modal"
           header-class="text24-blue mt-3"
           @ok="handleRemoveModalOk"
           :title="t('SURE_TO_REMOVE')">
    <template v-slot:modal-header-close>
      <b-img :src="(iconX)"></b-img>
    </template>
    <template v-slot:modal-footer="">
      <b-button @click="hideModal">
        {{ t("CANCEL") }}
      </b-button>
      <b-button variant="blue" type="submit" @click="handleRemoveModalOk">
        {{ t("YES") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import {computed, inject, onBeforeUnmount, onMounted, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import eventBus from "@/util/eventBus";
import {toast} from "vue3-toastify";
import {useRouter} from "vue-router";
import iconX from "@/assets/icon/icon_x.svg?inline";


const axios = inject("axios");
const { t } = useI18n();
const router = useRouter();

onMounted(() => {
  eventBus.$on("show-remove-monthly-newsletter-modal", showRemoveNewsModal);
})
onBeforeUnmount(() => {
  eventBus.$off("show-remove-monthly-newsletter-modal", showRemoveNewsModal);
})

const props = defineProps(['isVisible']);
const emits = defineEmits(['update-value'])
const isModalVisible = ref(false)
const newsletterIdNum = ref('')
const {isVisible} = toRefs(props);

const isModalVisibleComputed = computed(() => isModalVisible.value = props.isVisible)

const showRemoveNewsModal = (newsletterId) => {
  isModalVisible.value = props.isVisible
  newsletterIdNum.value = newsletterId

}
async function handleRemoveModalOk(bvModalEvt) {
  bvModalEvt.preventDefault();
  try {
    const response = await axios.delete(`/news/${newsletterIdNum.value}`);
    eventBus.$emit("FETCH_MONTHLY_NEWS");
    if (response && response.status === 200) {
      makeToast(
          t("MONTHLY_NEWSLETTER_DELETED"),
          "success",
          t("OPERATION_SUCCESSFUL"),
      );
    }
    hideModal();
  } catch (e) {
    console.log(e);
  }
}
function hideModal() {
  isModalVisible.value = false
  emits('update-value', false)
}
const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};

</script>
