<template>
  <b-modal v-model="isLanguageModalVisible"
           content-class="p-2 rounded-0"
           body-class="p-2 border-0"
           class="modal-content"
           id="language-modal"
           ref="languageModalRef"
           size="xl"
           role="dialog"
           :cancel-disabled="true"
           :ok-disabled="true"
           header-class="p-2 border-0 text18-black"
           footer-class="p-2 border-0"
           centered
           hide-footer>
    <div v-if="auth.hasRole('TRAINEE')">
      <div v-if=auth.getRVK()>
        <h5>Dear beneficiary of international protection,</h5>
        <p>On this page you can register for international protection basic training, and Estonian language courses on A1, A2 and B1 levels. Both basic and language training are obligatory for you as a part of adaptation programme.<br><br>Please note that if you have already passed A1 or A2 level Estonian language training elsewhere (i.e. not provided by Settle in Estonia adaptation programme, for example, at the University, through Estonian Unemployment Insurance Fund, or with the language training provider of your choosing) and would like to continue on the next level, you need to present your certificate issued at the end of the previous course in digital format via email at <a href="info@settleinestonia.ee">info@settleinestonia.ee</a> and we will add the certificate to the Settle in Estonian database. You will receive respective confirmation, upon which you will be able to register for the next available language course on this page.</p>
        <p>{{ 'Thank you for your patience.' }}</p>
        <br>
        <h5>Уважаемый получатель статуса международной защиты,</h5>
        <p>На этой странице вы можете зарегистрироваться на обязательные для вас курс программы адаптации для международной защиты и курсы эстонского языка на уровень А1, А2 или В1.<br><br>Пожалуйста, обратите внимание, что если вы уже прошли обязательное языковое обучение на уровень А1 или А2 вне программы адаптации Settle in Estonia (например, в университете, через Кассу по безработице, или в языковой школе) и хотите зарегистрироваться на следующий курс, то вам необходимо предоставить сертификат об окончании прошлого курса в электронном виде по электронной почте <a href="info@settleinestonia.ee">info@settleinestonia.ee</a>. После регистрации Вашего сертификата в базе данных Settle in Estonia, Вам будет отправлено подтверждение, и только после этого вы увидите на этой странице доступные вам курсы следующего уровня.'</p>
        <p>{{ 'Спасибо.' }}</p>
      </div>
      <div  v-if=auth.getTemp()>
        <h5>Dear beneficiary of temporary protection,</h5>
        <p>On this page you can register for temporary protection basic training, and Estonian language courses on A1, A2 and B1 levels. Both basic and A1 language training are obligatory for you as a part of adaptation programme, A2 and B1 language training are complimentary.
          <br><br>Please note that if you have already passed A1 or A2 level Estonian language training elsewhere (i.e. not provided by Settle in Estonia adaptation programme, for example, at the University, through Estonian Unemployment Insurance Fund, or with the language training provider of your choosing) and would like to continue on the next level, you need to present your certificate issued at the end of the previous course in digital format via email at <a href="info@settleinestonia.ee">info@settleinestonia.ee</a> and we will add the certificate to the Settle in Estonian database. You will receive respective confirmation, upon which you will be able to register for the next available language course on this page.</p>

        <p>{{ 'Thank you for your patience.' }}</p>
        <br>
        <h5>Уважаемый получатель статуса временной защиты,</h5>
        <p>На этой странице вы можете зарегистрироваться на обязательные для вас базовый курс программы адаптации и курсы эстонского языка на уровень А1, А2 и В1. Участие в языковых курсах уровня А2 и В1 для вас добровольно.<br><br>Пожалуйста, обратите внимание, что если вы уже прошли обязательное языковое обучение на уровень А1 или А2 вне программы адаптации Settle in Estonia (например, в университете, через Кассу по безработице, или в языковой школе) и хотите зарегистрироваться на следующий курс, то вам необходимо предоставить сертификат об окончании прошлого курса в электронном виде по электронной почте <a href="info@settleinestonia.ee">info@settleinestonia.ee</a>. После регистрации Вашего сертификата в базе данных Settle in Estonia, Вам будет отправлено подтверждение, и только после этого вы увидите на этой странице доступные вам курсы следующего уровня.'</p>
        <p>{{ 'Спасибо.' }}</p>
      </div>
<!--      <div  v-if=isGeneralTrainee>-->
<!--        <h5>Dear beneficiary of general protection,</h5>-->
<!--        <p>On this page you can register for temporary protection basic training, and Estonian language courses on A1, A2 and B1 levels. Both basic and A1 language training are obligatory for you as a part of adaptation programme, A2 and B1 language training are complimentary.-->
<!--          <br><br>Please note that if you have already passed A1 or A2 level Estonian language training elsewhere (i.e. not provided by Settle in Estonia adaptation programme, for example, at the University, through Estonian Unemployment Insurance Fund, or with the language training provider of your choosing) and would like to continue on the next level, you need to present your certificate issued at the end of the previous course in digital format via email at <a href="info@settleinestonia.ee">info@settleinestonia.ee</a> and we will add the certificate to the Settle in Estonian database. You will receive respective confirmation, upon which you will be able to register for the next available language course on this page.</p>-->

<!--        <p>{{ 'Thank you for your patience.' }}</p>-->
<!--        <br>-->
<!--        <h5>Уважаемый получатель статуса общей защиты,</h5>-->
<!--        <p>На этой странице вы можете зарегистрироваться на обязательные для вас базовый курс программы адаптации и курсы эстонского языка на уровень А1, А2 и В1. Участие в языковых курсах уровня А2 и В1 для вас добровольно.<br><br>Пожалуйста, обратите внимание, что если вы уже прошли обязательное языковое обучение на уровень А1 или А2 вне программы адаптации Settle in Estonia (например, в университете, через Кассу по безработице, или в языковой школе) и хотите зарегистрироваться на следующий курс, то вам необходимо предоставить сертификат об окончании прошлого курса в электронном виде по электронной почте <a href="info@settleinestonia.ee">info@settleinestonia.ee</a>. После регистрации Вашего сертификата в базе данных Settle in Estonia, Вам будет отправлено подтверждение, и только после этого вы увидите на этой странице доступные вам курсы следующего уровня.'</p>-->
<!--        <p>{{ 'Спасибо.' }}</p>-->
<!--      </div>-->
    </div>

  </b-modal>


</template>
<script setup>

import {useI18n} from "vue-i18n";
import {inject, onBeforeUnmount, onMounted, ref, watch} from "vue";
import EventBus from "@/util/eventBus";

const auth = inject("auth");

const languageModalRef = ref(false);
const {t} = useI18n();
const props = defineProps({
  isModalVisible: Boolean
});
const isLanguageModalVisible = ref(false);

onMounted(() => {
  EventBus.$on("show-language-modal", () => languageModalRef.value.show());
})

onBeforeUnmount(() => {
  EventBus.$off("show-language-modal");
})
// const isGeneralTrainee = () => {
//   return (!auth.getTemp() && !auth.getRVK());
// }

watch(
    () => props.isModalVisible,
    (newVal) => {
      isLanguageModalVisible.value = newVal;
    },
);


</script>
