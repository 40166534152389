<template>
  <b-modal
    id="cancel-training-registration-modal"
    ref="cancelModalRef"
    @ok="ok"
  >
    <b-card class="h-50">
      <p class="my-4 question">{{ t("SURE_TO_CANCEL") }}</p>
    </b-card>

    <template v-slot:modal-footer="{ ok, cancel }">
      <div class="w-100">
        <div class="btn-group positionRight">
          <b-button
            class="btn btn-outline-success btn-lg"
            type="submit"
            @click="ok"
          >
            <b-icon-forward-fill></b-icon-forward-fill> {{ t("YES") }}
          </b-button>
        </div>

        <div class="btn-group">
          <b-button class="btn btn-warning btn-lg" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> {{ t("NO") }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script setup>

import EventBus from "@/util/eventBus";
import {inject, onBeforeMount, onBeforeUnmount, ref} from "vue";
import { useI18n } from "vue-i18n";
import { toast } from "vue3-toastify";

const {t} = useI18n()
const cancelModalRef = ref(null)
const axios = inject("axios");
const groupData = ref(null);

onBeforeMount(()=> {
  EventBus.$on(    "show-registration-cancellation-modal", showRegistrationCancellationModal);
})
onBeforeUnmount(()=> {
  EventBus.$off(
    "show-registration-cancellation-modal",
    showRegistrationCancellationModal,
  );
})

function showRegistrationCancellationModal(group) {
  groupData.value = group;
  cancelModalRef.value.show("cancel-training-registration-modal");
}
function cancel() {
  cancelModalRef.value.hide("cancel-training-registration-modal");
}
async function ok(bvModalEvt) {
  bvModalEvt.preventDefault();
  await cancelTrainingRegistration();
}
async function cancelTrainingRegistration() {
  try {
    const response = await axios.post(
      `/training/group/${groupData.value.id}/cancel-register`,
      groupData.value.events,
    );
    EventBus.$emit("FETCH_REGISTERED");
    EventBus.$emit("FETCH_AVAILABLE");
    EventBus.$emit('cancalationSuccess');
    if (response && response.status === 200) {
      makeToast(t("OPERATION_SUCCESSFUL"), "success", t("CANCELED"));
    }
    cancel();
  } catch (e) {
    cancelModalRef.value.hide("cancel-training-registration-modal");
    console.log(e);
  }
}
const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    title: title,
    variant: variant,
    autoHideDelay: 5000,
  });
};
</script>

<style lang="less">
.question {
  color: #ff0000;
  font-weight: bold;
  padding: 3%;
}
.positionRight {
  float: right;
}
</style>
