<template>
  <b-container>
    <div class="text24-blue mt-3">
      {{ t("FIELD_RESEARCH") }}
      <b-button
          variant="blue"
          @click="openNewFileWindow()"
          :hidden="!isContentManager()">
        <b-icon-plus></b-icon-plus>
        {{ t("ADD_NEW") }}
      </b-button>
    </div>
    <br />
    <add-new-file
      v-if="newStudiesWindow"
      :fileIdentifier="fileIdentifier"
    ></add-new-file>

    <div id="link-container" class="mt-3 px-3 pb-3">
      <div v-for="item in files" :key="item.id" class="pt-3">
        <a
          class="text18-blue bold"
          @click="downloadFile(item.id, item.fileName)"
        >
          {{ item.fileName }}
          <b-icon-cloud-download></b-icon-cloud-download>
        </a>
      </div>
    </div>
  </b-container>
</template>

<script setup>

import {inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import AddNewFile from "@/views/components/AddNewFile.vue";

const axios = inject("axios");
const {t} = useI18n();
const auth = inject("auth");
const newStudiesWindow = ref(null);
const fileIdentifier = "FIELD_RESEARCH";
const files = ref([]);

onMounted(() => {
  EventBus.$on("FETCH_NEW_FILES", fetchData);
  fetchData()
});

onBeforeUnmount(() => {
  EventBus.$off("FETCH_NEW_FILES", fetchData);
});

const isContentManager = () => {
  return auth.hasRole("CONTENT_MANAGER");
};


function openNewFileWindow() {
  newStudiesWindow.value = !newStudiesWindow.value;
  return newStudiesWindow.value;
}
async function fetchData() {
  try {
    const resp = await axios.get(`/file?fileIdentifier=${fileIdentifier}`);
    if (resp.data && resp) {
      files.value = resp.data;
      return files.value;
    }
  } catch (e) {
    console.log(e);
  }
}
const downloadFile = async (id, name) => {
  try {
    const resp = await axios.get(`/file/${id}`, { responseType: "blob" });
    if (resp) {
      const fileURL = window.URL.createObjectURL(
          new Blob([resp.data], {type: `${resp.data.type}`}),
      );
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", name);
      document.body.appendChild(fileLink);

      fileLink.click();
    }
  } catch (e) {
    console.log(e);
  }
};
</script>
