<template>
  <div v-if="moduleData" class="container-fluid section-p70">
    <b-row>
      <b-col class="title-blue" cols="auto">
        {{ t("MANAGE_MODULE") }} - {{ moduleData.code }}
      </b-col>
      <b-col align-self="center">
        <b-button
            v-if="isAdmin()"
            variant="blue"
            @click="toggleModuleArchive()"
        >
          {{ isArchived() ? t("ARCHIVED") : t("ARCHIVE") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col xl="3">
        <b-card style="text-align: left">
          <b-card-title>{{ t("BASE_DATA") }}</b-card-title>
          <b-form id="moduleForm" ref="form">
            <b-form-group label-for="code">
              <label class="form-label">{{ t("MODULE_CODE") }}</label>
              <b-form-input
                  id="code"
                  :value="moduleData.code"
                  disabled
                  @input="moduleData.code = formatCode($event)"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group label-for="type">
              <label class="form-label">{{ t("MODULE_TYPE") }}*</label>
              <b-form-input
                  id="type"
                  :value="t(moduleData.type)"
                  class="form-control"
                  disabled
                  name="type"
                  type="text"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group label-for="rvk">
              <label class="form-label">{{
                  t("INTERNATIONAL_PROTECTION")
                }}</label>
              <b-form-checkbox
                  id="rvk"
                  v-model="moduleData.rvk"
                  :value="moduleData.rvk"
                  disabled
                  name="rvk"
              >
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label-for="temp">
              <label class="form-label">{{ t("TEMPORARY_PROTECTION") }}</label>
              <b-form-checkbox
                  id="temp"
                  v-model="moduleData.temp"
                  :value="moduleData.temp"
                  disabled
                  name="temp"
                  @input="moduleData.temp = formatCode($event)"
              >
              </b-form-checkbox>
            </b-form-group>
            <label class="form-label">{{ t("TRANSLATIONS") }}</label>
            <b-form-group
                v-for="locale in $i18n.availableLocales"
                :key="locale"
                :label="locale"
                :label-for="locale"
                label-cols="auto"
            >
              <b-input
                  :id="locale"
                  v-model="moduleData.translations[locale]"
                  :disabled="isArchived()"
                  required
                  type="text"
              ></b-input>
            </b-form-group>
          </b-form>
          <b-button
              v-if="isAdmin()"
              :disabled="isArchived()"
              style="width: 100%"
              variant="blue"
              @click="updateData"
          >
            {{ t("SAVE") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col xl="9">
        <b-tabs active-nav-item-class="bold" card>
          <!-- Training group table tab -->
          <b-tab
              :title="t('TRAINING_GROUPS')"
              active
              title-link-class="text16-black"
          >
            <b-row>
              <b-col class="text-right">
                <b-button
                    v-if="isGroupAddButtonVisible"
                    variant="blue"
                    @click="showGroupCreationModal"
                >
                  {{ t("ADD") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <loader :active="loaderActive" :message="t('PLEASE_WAIT')"/>
                <b-table
                    :borderless="true"
                    :current-page="currentGroupsPage"
                    :empty-text="t('NO_RESULTS')"
                    :fields="fields"
                    :items="groups"
                    :per-page="perPage"
                    show-empty
                    striped
                >
                  <template v-slot:cell(action)="{ item }">
                    <div class="align-items-center">
                      <b-button
                          :to="{
                          name: 'ManageGroup',
                          params: { id: `${item.id}` },
                        }"
                      >
                        {{ t("VIEW") }}
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                    v-if="groups && groups.length > perPage"
                    v-model="currentGroupsPage"
                    :per-page="perPage"
                    :total-rows="groups ? groups.length : 0"
                    align="center"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>
          <!-- Trainer table tab -->
          <b-tab :title="t('TRAINER')" title-link-class="text16-black">
            <b-row>
              <b-col class="text-right">
                <b-button
                    v-if="isAdmin()"
                    v-model="addTrainer"
                    :disabled="isArchived()"
                    variant="blue"
                    @click="toggleSearchForm"
                >{{ t("ADD") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form v-if="addTrainer">
                  <b-form-group label-for="search-trainers">
                    <label class="form-label">{{ t("SEARCH_TIP2") }}</label>
                    <b-form-input
                        id="search-trainers"
                        v-model="searchValue"
                        label="Search"
                        minlength="3"
                        type="text"
                        v-on:input="searchTrainer()"
                    ></b-form-input>
                  </b-form-group>
                  <b-form v-if="searchValue && searchValue.length >= 3">
                    <div v-for="trainer in searchResult" :key="trainer.id">
                      <b-row>
                        <b-link
                            @click="submitTrainer(trainer.id)"
                            v-on:mouseenter="hover = true"
                            v-on:mouseleave="hover = false"
                        >
                          {{ trainer.lastName + " " + trainer.firstName }}
                        </b-link>
                      </b-row>
                    </div>
                    <b-form-group class="space"></b-form-group>
                    <p v-show="hover" class="italic">{{ t("REGISTER") }}</p>
                  </b-form>
                </b-form>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                    :borderless="true"
                    :current-page="currentTrainerPage"
                    :empty-text="t('NO_RESULTS')"
                    :fields="trainerFields"
                    :items="trainers"
                    :per-page="perPage"
                    show-empty
                    striped
                >
                  <template v-slot:cell(action)="{ item }">
                    <div class="text-center">
                      <img
                          v-if="isAdmin"
                          :disabled="isArchived()"
                          :src="iconRemove"
                          alt="icon Remove with X"
                          class="clickable"
                          @click="removeTrainer(item.id)"
                      />
                    </div>
                  </template>
                </b-table>
                <b-pagination
                    v-if="trainers && trainers.length > perPage"
                    v-model="currentTrainerPage"
                    :per-page="perPage"
                    :total-rows="trainers ? trainers.length : 0"
                    align="center"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <group-creation-modal
        :module="moduleData"
        :trainers="trainers"
        @added-group="addGroup"
        :is-admin="isAdmin()"
    ></group-creation-modal>
  </div>
</template>

<script setup>
import EventBus from "@/util/eventBus";
import groupCreationModal from "@/views/components/modals/groupCreationModal.vue";
import Loader from "@/components/Loader.vue";
import {computed, inject, onBeforeMount, onBeforeUnmount, ref} from "vue";
import removeIcon from "@/assets/icon/icon_x.svg";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useDateFormat} from "@/mixins/dateFormatMixin2.ts";
import {toast} from "vue3-toastify";

const props = defineProps(["module", "school"]);
const axios = inject("axios");
const route = useRoute();
const {t, locale} = useI18n();
const auth = inject("auth");
const {dateFormat} = useDateFormat();
const moduleData = ref(null);
const groups = ref(null);
const trainers = ref(null);
const iconRemove = removeIcon;
const perPage = ref(15);
const currentGroupsPage = ref(1);
const currentTrainerPage = ref(1);
const addTrainer = ref(false);
const searchValue = ref("");
const searchResult = ref([]);
const hover = ref(false);
const loaderActive = ref(false);
const isGroupAddButtonVisible = ref(false);


onBeforeMount(() => {
  EventBus.$on("GROUP_SEARCH", getGroups);
  getGroups();
  getTrainers();
  getModule();
  checkCanCreateGroup();
});

onBeforeUnmount(() => {
  EventBus.$off("GROUP_SEARCH", getGroups);
});

const trainerFields = computed(() => {
  return [
    {key: "name", label: t("NAME"), sortable: true, formatter: getFullName},
    {key: "phone", label: t("PHONE"), sortable: true},
    {key: "email", label: t("EMAIL"), sortable: true},
    {key: "action", label: "", sortable: false},
  ];
});
const showGroupCreationModal = () => {
  EventBus.$emit("show-group-creation-modal");
};

const fields = computed(() => {
  return [
    { key: 'businessName', label: t("SCHOOL"), sortable: true },
    {key: "title", label: t("GROUP_TITLE"), sortable: true},
    {key: "startEvent", label: t("START"), sortable: true, formatter: getGroupStart},
    {key: "endEvent", label: t("END"), sortable: true, formatter: getGroupStart},
    {key: "language", label: t("LANGUAGE"), sortable: true},
    {key: "location", label: t("LOCATION"), sortable: true},
    {key: "groupSize", label: t("OCCUPANCY"), sortable: true, formatter: getGroupSize},
    {key: "status", label: t("STATUS"), sortable: true, formatter: getTranslatedStatus},
    {key: "action", label: "", sortable: false},
  ];
});

const isAdmin = () => {
  return auth.isAuthenticated && auth.hasRole("ADMIN");
};
const isArchived = () => {
  return moduleData.value.archived;
};

async function checkCanCreateGroup() {
  try {
    const resp = await axios.get(`/training/${route.params.id}/canCreateGroup`);
    isGroupAddButtonVisible.value = resp.data.result;
  } catch (error) {
    console.error(error);
  }
}


const getModule = async () => {
  try {
    const resp = await axios.get(`/training/${route.params.id}`);
    if (resp.data && resp) {
      moduleData.value = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const isSchoolManager = () => {
  return auth.isAuthenticated && auth.hasRole("SCHOOL_MANAGER");
};
const schoolAdminEndpoint = `/school/${route.params.id}/school_trainers`
const adminEndpoint = `/training/${route.params.id}/trainers`

const teachersEndpoint = computed(() => {
  if (isSchoolManager()) {
    return schoolAdminEndpoint
  } else {
    return adminEndpoint
  }
})
const getTrainers = async () => {
  try {
    const response = await axios.get(
        teachersEndpoint.value,
    );
    if (response.data && response) {
      trainers.value = response.data;
      return trainers.value;
    }
  } catch (e) {
    console.log(e);
  }
};

function showLoader() {
  loaderActive.value = true;
}
function hideLoader() {
  loaderActive.value = false;
}

const getGroups = async () => {
  showLoader();
  try {
    const resp = await axios.get(`/training/${route.params.id}/groups`);
    if (resp.data && resp) {
      groups.value = resp.data;
      hideLoader();
    }
  } catch (error) {
    console.error(error);
  }
};

async function toggleModuleArchive() {
  try {
    const response = await axios.post(
        `/training/${moduleData.value.id}/archive`,
    );
    if (response?.data) {
      moduleData.value = response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

const getGroupStart = (value) => {
  return dateFormat(value);
};

const getGroupSize = (value, key, item) => {
  return (
      (item.numberOfTrainees ? item.numberOfTrainees : 0) + "/" + item.groupSize
  );
};

const getTranslatedStatus = (value) => {
  return t(value);
};

const getFullName = (value, key, item) => {
  return item.firstName + " " + item.lastName;
};

const toggleSearchForm = () => {
  addTrainer.value = !addTrainer.value;
};

async function searchTrainer() {
  try {
    if (searchValue.value.length >= 3) {
      const response = await axios.post(
          `/account/search/all_trainers?roles=TRAINER&searchValue=${searchValue.value}&fields=SSN,NAME`,
      );
      searchResult.value = response.data;
    }
  } catch (error) {
    console.error(error);
  }
}
async function submitTrainer(id) {
  try {
    const response = await axios.post(
        `/training/${route.params.id}/trainer/${id}`,
    );
    if (response.data && response) {
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));
      trainers.value.push(response.data);
      searchValue.value = "";
      addTrainer.value = false;
      await getTrainers()
    }
  } catch (error) {
    console.error(error);
  }
}

async function removeTrainer(id) {
  try {
    const response = await axios.delete(
        `/training/${route.params.id}/trainer/${id}`,
    );
    if (response && response.status === 200) {
      makeToast(t("REMOVED"), "success", t("OPERATION_SUCCESSFUL"));
      await getTrainers();
    }
  } catch (error) {
    console.error(error);
  }
}

async function updateData() {
  try {
    const response = await axios.patch(
        `/training/${route.params.id}`,
        moduleData.value,
    );
    if (response?.data) {
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));
      moduleData.value = response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

function addGroup(group) {
  groups.value.push(group);
  getGroups()
}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};

const formatCode = (value) => {
  return value.toUpperCase().replace(" ", "_");
};
</script>


<style lang="less" scoped>
.table-header-btn {
  margin-right: 2%;
  height: 30px;
}

.form-control:disabled {
  background-color: #bae6e8;
  border: 0;
}

.red {
  color: red;
}

.add-me {
  background-color: white;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
</style>
