<template>
  <div>
    <b-container fluid="sm">
      <div class="row justify-content-center section" style="margin: 0 auto">
        <div v-if="token2">
          <b-card class="mb-4">
            <h3 class="text24-blue">{{ t("CHOOSE_PASSWORD") }}</h3>
            <p id="password-policy">
              {{ t("SAFE_PW") }}:<br/>
              - {{ t("MIN_15_CHARS") }}<br/>
              - {{ t("CHARS_NUMS") }}<br/>
              - {{ t("LC_UC") }}<br/>
              - {{ t("NO_PERSONAL_DATA") }}<br/>
              - {{ t("NO_VOCABULARY") }}<br/>
            </p>
            <b-form novalidate>
              <b-row class="mb-2">
                <b-col>
                  <b-input
                      type="password"
                      :placeholder="t('PASSWORD')"
                      name="password1"
                      id="password1"
                      v-model="form.passwordFirst"
                      :state="isStrongPassword || null"
                  ></b-input>
                  <b-form-invalid-feedback v-show="!isStrongPassword && startValidation">
                    {{ t("INVALID_PW_PASSWORD") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col>
                  <b-input
                      required
                      name="password2"
                      id="password2"
                      type="password"
                      :placeholder="t('PASSWORD')"
                      :state="isPasswordValid || null"
                      v-model="form.passwordSecond"
                  ></b-input>
                  <b-form-invalid-feedback v-show="!isPasswordMatches && startValidation">
                    {{ t("PW_MISMATCH") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mb-2 mt-4">
              <b-col>
                <b-button :disabled="!isPasswordValid" variant="blue" @click="setPassword" style="width: 100%">
                  {{ t("SAVE") }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <b-alert variant="danger" :show="expired">{{
          t("TOKEN_EXPIRED")
        }}</b-alert>
      </div>
      <div class="separator-line"></div>
      <div class="row justify-content-center mt-4">
        <b-btn :to="{ name: 'Login' }" variant="white" class="mb-4">{{
          t("BACK")
        }}</b-btn>
      </div>
    </b-container>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute, useRouter} from 'vue-router';
import {toast} from 'vue3-toastify';

const router = useRouter();
const {t} = useI18n();
const axios = inject('axios');
const route = useRoute();
const token2 = ref(false);
const expired = ref(false);
const form = ref({
  passwordFirst: '',
  passwordSecond: '',
});
const startValidation = ref(false);

onMounted(async () => {
  if (!route.query.token) {
    await router.push({path: '/'});
  } else {
    await checkToken(route.query.token);
  }
});

const isStrongPassword = computed(() => {
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[^\w\s]).{15,}$/.test(form.value.passwordFirst);
});

const isPasswordMatches = computed(() => {
  return form.value.passwordFirst === form.value.passwordSecond;
});

const isPasswordValid = computed(() => {
  return isStrongPassword.value && isPasswordMatches.value;
});

const checkToken = async (token) => {
  try {
    const response = await axios.get(`/account/token-valid/${token}`);
    token2.value = response.status === 200;
    expired.value = response.status === 204;
  } catch (e) {
    console.error(e);
  }
};

const setPassword = async () => {
  startValidation.value = true;
  if (!isPasswordValid.value) {
    toast(t("CHECK_INPUT"), {autoHideDelay: 5000, variant: "danger"});
    return;
  }
  try {
    const response = await axios.put(`/account/password-reset/${route.query.token}`, {
      password: form.value.passwordFirst,
    });
    if (response.status === 200) {
      toast(t("OPERATION_SUCCESSFUL"), {autoHideDelay: 5000, variant: "success"});
      form.value.passwordFirst = "";
      form.value.passwordSecond = "";
    }
  } catch (e) {
    console.error(e);
  }
};
</script>
