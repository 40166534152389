<template>
  <b-container
      v-if="group.trainees && group.trainees.length"
      id="abacus-container"
      fluid
  >
    <b-row>
      <b-table
          :fields="fields"
          :items="tableItems"
          bordered
          responsive
          small
          sticky-header="740px"
      >
        <template v-slot:thead-top>
          <b-tr v-if="group.training.type === 'RECURRING'">
            <b-th colspan="3"></b-th>
            <b-th
                v-for="event in group.events"
                :key="event.id"
                colspan="2"
                sticky-column
            >
              {{ dateFormat(event.startTime) }}
            </b-th>
            <b-th v-if="showTransferredColumn" colspan="2" sticky-column>{{
                t("TRANSFERRED")
              }}
            </b-th>
            <b-th colspan="4" sticky-column>{{ t("TOTAL") }}</b-th>
          </b-tr>
        </template>
        <template v-slot:cell(view)="{ item }">
          <div style="text-align: center">
            <b-button :to="{ name: 'Trainee', params: { id: `${item.id}` } }">
              {{ t("TRAINING_CARD") }}
            </b-button>
          </div>
        </template>
        <template
          v-for="col in attendanceColumns"
          v-slot:[cell(col)]="{ item }"
        >
          <div :key="col">
            <input
                v-model="item[col]"
                :disabled="isArchived || col.startsWith('transferred')"
                :placeholder="col.endsWith('present') ? '-' : '0'"
                class="attendance-input"
                type="text"
                @keydown="formHasBeenChanged = true"
            />
          </div>
        </template>
        <template v-slot:cell(hoursHomework)="{ item }">
          <div
              v-if="item['homeworkLimited']"
              v-b-tooltip.hover
              :title="t('HOMEWORK_LIMIT')"
              class="red-text"
          >
            {{ item.hoursHomework }}
          </div>
          <div v-else>
            {{ item.hoursHomework }}
          </div>
        </template>
        <template v-slot:cell(present)="{ item }">
          <b-form-checkbox
              v-model="item.present"
              :disabled="isArchived"
              size="lg"
              @change="formHasBeenChanged = true"
          >
          </b-form-checkbox>
        </template>
        <template
          v-if="group.training.type === 'RECURRING'"
          v-slot:table-caption
        >
          <small class="red">* {{ t("TRAINEE_NOTIFIED_ABSENT") }}</small>
          <br />
          <small class="red">* {{ t("INTERRUPTED_NOTIFIED") }}</small>
        </template>
      </b-table>
    </b-row>
    <b-row>

      <b-col class="text-right">
        <b-button
            v-if="isAdminOrSchoolManagerOrSchoolManager"
            :disabled="!formHasBeenChanged || isArchived || blockedWhileSaving || auth.hasRole('VIEWER')"
            variant="blue"
            @click="submitAttendance"
        >
          {{ t("SAVE") }}
        </b-button>

      </b-col>
    </b-row>
  </b-container>
</template>

<script setup>
import {useDateFormat} from "@/mixins/dateFormatMixin2.ts";
import {computed, inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {toast} from "vue3-toastify";

const groupData = ref(null);
const {dateFormat} = useDateFormat();
const axios = inject("axios");
const route = useRoute();
const {t} = useI18n();
const auth = inject("auth");
const formHasBeenChanged = ref(false);
const attendances = ref(null);
const fields = ref([]);
const tableItems = ref([]);
const attendanceColumns = ref([]);
const showTransferredColumn = ref(false);
const transferredAttendances = ref([]);
const blockedWhileSaving = ref(false);
const props = defineProps(["group"]);


onMounted(async () => {
  if (!props.group) {
  } else {
    groupData.value = props.group;
  }
  await getAttendances();
  createTable();

})

const isArchived = computed(() => {
  return props.group.status === "ARCHIVED";
});

function isAdminOrSchoolManagerOrSchoolManager() {
  return (auth.hasRole("ADMIN") || auth.hasRole("SCHOOL_MANAGER") || auth.hasRole("TRAINER"))
}


function cell(name) {
  return `cell(${name})`;
}

async function getAttendances() {
  try {
    const resp = await axios.get(
        `/attendance/group/?groupId=${route.params.id}`,
    );
    if (resp.data && resp) {
      return (attendances.value = resp.data);
    }
  } catch (e) {
    console.log(e);
  }
}

async function submitAttendance() {
  blockedWhileSaving.value = true;

  if (groupData.value.training.type === "RECURRING") {
    attendances.value = createLanguageAttendanceRequestBody();

  } else {
    attendances.value = createSingleEventAttendanceRequestBody();
  }
  try {
    const resp = await axios.post(
        "/attendance/group/" + groupData.value.id,
        attendances.value,
    );
    if (resp && resp.status === 201) {
      attendances.value = resp.data.concat(transferredAttendances.value);
      transferredAttendances.value = [];
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));
      createTable();
      await getAttendances()

      blockedWhileSaving.value = false;
      formHasBeenChanged.value = false;
    }
  } catch (e) {
    console.log(e);
  }
}

function createLanguageAttendanceRequestBody() {

  const attendances = [];
  const getHoursValue = (value) => {
    return /^(?!0\d)\d*(\.\d+)?$/.test(value) ? parseFloat(value) : 0;
  };
  const getStatusValue = (row, eventId) => {
    if (
        row[eventId + "_present"] === "T" ||
        row[eventId + "_present"] === "t"
    ) {
      return "ABSENT_NOTIFIED";
    } else if (
        row[eventId + "_present"] === "K" ||
        row[eventId + "_present"] === "k"
    ) {
      return "INTERRUPTED_NOTIFIED";
    } else if (
        /^(?!0\d)\d*(\.\d+)?$/.test(row[eventId + "_present"]) &&
        row[eventId + "_present"] > 0
    ) {
      return "PRESENT";
    }
    return "ABSENT";
  };
  tableItems.value.forEach((row) => {
    groupData.value.events.forEach((event) => {

      attendances.push({
        trainingEventId: event.id,
        accountId: row.id,
        hoursPresent: getHoursValue(row[event.id + "_present"]),
        hoursHomework: getHoursValue(row[event.id + "_homework"]),
        status: getStatusValue(row, event.id),
      });
    });
  });

  return attendances;
}

function createSingleEventAttendanceRequestBody() {
  const attendances = [];
  tableItems.value.forEach((row) => {
    groupData.value.events.forEach((event) => {
      attendances.push({
        trainingEventId: event.id,
        accountId: row.id,
        hoursPresent: null,
        hoursHomework: null,
        status: row.present ? "PRESENT" : "ABSENT",
      });
    });
  });
  return attendances;
}

function createTable() {
  // Initialize fields with the first 3 columns
  fields.value = [
    {
      key: "name",
      label: t("NAME"),
      sortable: true,
      stickyColumn: true,
      tdClass: "background-blue",
      thClass: "text-left",
    },
    {
      key: "ssn",
      label: t("SSN"),
      sortable: true,
      thClass: "text-left",
    },
    {key: "view", label: ""},
  ];
  // Initialize rows with all trainees
  const traineeDataMap = new Map();
  groupData.value.trainees.forEach((trainee) =>
      traineeDataMap.set(trainee.trainee.id, {
        id: trainee.trainee.id,
        name: trainee.trainee.firstName + " " + trainee.trainee.lastName,
        ssn: trainee.trainee.ssn,
        status: t("NOT_PASSED"),
      }),
  );

  if (groupData.value.training.type === "RECURRING") {
    createLanguageTable(traineeDataMap);
  } else {
    createSingleEventTable(traineeDataMap);
  }

  // Lookup no longer required, transform to array.
  tableItems.value = Array.from(traineeDataMap.values());

  // Append status column to the end of the table
  fields.value.push({
    key: "status",
    label: t("STATUS"),
    sortable: true,
    tdClass: getStatusCellColor,
    formatter: getStatusValue,
  });
}

function createLanguageTable(traineeDataMap) {
  groupData.value.trainees.forEach((trainee) => {
    const k = traineeDataMap.get(trainee.trainee.id);
    k.hoursPresent = 0;
    k.hoursHomework = 0;
    k.homeworkLimited = false;
    k.hours = 0;
  });

  // Add attendance info to the rows. Iterate attendance list and tie it with appropriate trainee.
  attendances.value.forEach((attendance) => {
    const dataObj = traineeDataMap.get(attendance.accountId);
    if (!dataObj) {
      return;
    }

    let prefix;
    if (attendance.trainingEvent) {
      prefix = attendance.trainingEvent.id;
    } else {
      prefix = "transferred";
      showTransferredColumn.value = true;
      transferredAttendances.value.push(attendance);
    }

    if (attendance.status === "PRESENT") {
      dataObj[prefix + "_present"] = attendance.hoursPresent;
      dataObj.hoursPresent += attendance.hoursPresent;
    } else if (attendance.status === "ABSENT_NOTIFIED") {
      dataObj[prefix + "_present"] = "T";
    } else if (attendance.status === "INTERRUPTED_NOTIFIED") {
      dataObj[prefix + "_present"] = "K";
    }
    if (attendance.hoursHomework > 0) {
      dataObj[prefix + "_homework"] = attendance.hoursHomework;
      dataObj.hoursHomework += attendance.hoursHomework;
    }
    dataObj.hours = dataObj.hoursPresent + Math.min(dataObj.hoursHomework, groupData.value.homeworks);
  });

  // Update fields array with 2 columns for each event, one for present hours and one for homework hours.
  // Also create a list for dynamic template generation.
  if (groupData.value.events) {
    groupData.value.events.forEach((event) => {
      fields.value.push({key: event.id + "_present", label: t("PRESENT")});
      attendanceColumns.value.push(event.id + "_present");
      fields.value.push({
        key: event.id + "_homework",
        label: t("HOMEWORK"),
      });
      attendanceColumns.value.push(event.id + "_homework");
    });
  }
  if (showTransferredColumn.value) {
    fields.value.push({key: "transferred_present", label: t("PRESENT")});
    attendanceColumns.value.push("transferred_present");
    fields.value.push({key: "transferred_homework", label: t("HOMEWORK")});
    attendanceColumns.value.push("transferred_homework");
  }

  // Append 3 summary columns to the end of the table
  fields.value.push({
    key: "hoursPresent",
    label: t("PRESENT"),
    sortable: true,
  });
  fields.value.push({
    key: "hoursHomework",
    label: t("HOMEWORK"),
    sortable: true,
  });
  fields.value.push({
    key: "hours",
    label: t("HOURS"),
    sortable: true,
  });
}

function createSingleEventTable(traineeDataMap) {
  // Add attendance info to the rows. Iterate attendance list and tie it with appropriate trainee.
  attendances.value.forEach((attendance) => {
    const dataObj = traineeDataMap.get(attendance.accountId);
    if (!dataObj) {
      return;
    }
    dataObj.present = attendance.status === "PRESENT";
  });
  fields.value.push({key: "present", label: t("PRESENT")});
}

function getStatusValue(value, key, item) {
  if (
      groupData.value.training.type === "RECURRING"
  ) {
    item.status =
        item.hours >= groupData.value.lessons ? t("PASSED") : t("NOT_PASSED");
  } else {
    item.status = item.present ? t("PASSED") : t("NOT_PASSED");
  }
  return item.status;
}

function getStatusCellColor(value, key, item) {
  return item.status === t("PASSED") ? "green-text" : "red-text";
}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};
</script>

<style lang="scss" scoped>
@import "@/scss/custom.scss";
.form-control:disabled {
  background-color: #bae6e8;
  border: 0;
}

.attendance-input {
  border: none;
  border-bottom: 1px solid #dee2e6;
  background: transparent;
  outline: none;
  width: 40px;
  text-align: center;
}

#abacus-container ::v-deep(.background-blue) {
  background-color: $color-blue;
  @include text16($color-white);
}

#abacus-container :deep(.green-text) {
  color: green;
}

#abacus-container :deep(.red-text) {
  color: red;
}

:deep(td:nth-child(n + 3)),
:deep(th) {
  text-align: center;
}

#abacus-container :deep(th) {
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

//.fixed_headers {
//  height: 790px;
//  table-layout: fixed;
//  border-collapse: collapse;
//}
</style>
