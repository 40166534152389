<template>
  <div id="map-container"  v-html="counties"></div>
</template>

<script setup>

import {onBeforeUnmount, onMounted, ref} from "vue";
import counties from "@/assets/counties.svg?raw";

const selected = ref(null);
const emits = defineEmits(["change", "input"]);
// when remove model - then contacts appear while hover, but click doesn't work
const model = ref({
  event: "change",
});

onMounted(() => {
  initListeners();
});
onBeforeUnmount(() => {
  removeListeners();
});

const initListeners = () => {
  const paths = document.getElementById("map-container").querySelectorAll("path");
  paths.forEach((p) => {
    p.addEventListener("mouseenter", emitHover);
    p.addEventListener("click", onClick);
  });
};

const removeListeners = () => {
  const paths = document.getElementById("map-container").querySelectorAll("path");
  paths.forEach((p) => {
    p.removeEventListener("mouseenter", emitHover);
    p.removeEventListener("click", onClick);
  });
};
const emitHover = (event) => {
  emits("input", event.target.id);
};

const onClick = (event) => {
  emits("change", event.target.id);
  addColor(event);
};

const addColor = (event) => {

  if (selected.value && selected.value !== event.target) {
    emits("remove", selected.value.classList.toggle("is-active"));
  }
  event.target.classList.toggle("is-active");
  selected.value = event.target;
};
</script>

<style lang="scss" scoped>
:deep(path:hover) {
  fill: blue;
}

#map-container:deep(.is-active)  {
  fill: darkorange;
}
</style>
