<template>
  <b-container>
    <b-row class="section">
      <b-col cols="4">
        <div class="title-blue">{{ t("LOCAL_MUNICIPALITIES") }}</div>
        <county-contact :contacts="countyInfo[selectedCounty]"></county-contact>
      </b-col>
      <b-col cols="8">
        <country-map v-model="selectedCounty" />
      </b-col>
    </b-row>
    <separator class="section"></separator>
    <b-container id="important-numbers">
      <div class="row pl-3">
        <div class="col">
          <text-block
            content-id="importantNumbers"
            v-on:save="save"
            :content="content.elements.importantNumbers"
          >
          </text-block>
        </div>
      </div>
    </b-container>
    <separator class="section"></separator>
    <b-container id="countie-numbers">
      <div class="row pl-3">
        <div class="col">
          <text-block
            content-id="countieNumbers"
            v-on:save="save"
            :content="content.elements.countieNumbers"
          >
          </text-block>
        </div>
      </div>
    </b-container>
    <separator class="section"></separator>
    <b-container id="universities">
      <div class="row pl-3">
        <div class="col">
          <text-block
            content-id="universities"
            v-on:save="save"
            :content="content.elements.universities"
          >
          </text-block>
        </div>
      </div>
    </b-container>
    <separator class="section"></separator>
    <b-container id="emergency-contacts">
      <div class="row pb-4 pl-3">
        <div class="col">
          <text-block
            content-id="emergencyContacts"
            v-on:save="save"
            :content="content.elements.emergencyContacts"
          >
          </text-block>
        </div>
      </div>
    </b-container>
  </b-container>
</template>

<script setup>
import {computed, inject, onBeforeMount, onBeforeUnmount, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {usePageContent} from "@/mixins/pageContentMixin2";

import CountyContact from "@/views/components/CountyContact.vue";
import Separator from "@/views/components/Separator.vue";
import CountryMap from "@/views/components/Map.vue";
import TextBlock from "@/views/components/TextBlock.vue";


const {getPageContent, savePageContent} = usePageContent();
const axios = inject("axios");
const {t, tm, locale} = useI18n();
const content = ref({elements: {}});
const pageName = "contacts";
const selectedCounty = ref(null);

onBeforeMount(() => {
  EventBus.$on("locale", loadPageContent);
  loadPageContent();
});
onBeforeUnmount(() => {
  EventBus.$off("locale", loadPageContent);
});

async function loadPageContent() {
  content.value = await getPageContent(axios, pageName, locale.value);
}

const save = (elem) => {
  savePageContent(axios, pageName, locale.value, elem.element, elem.value);
};

const countyInfo = computed(() => {
  return getCountyInfo();
});

const getCountyInfo = () => {
  return {
    harjumaa: tm("COUNTY_INFO.HARJUMAA"),
    saaremaa: tm("COUNTY_INFO.SAAREMAA"),
    hiiumaa: tm("COUNTY_INFO.HIIUMAA"),
    läänemaa: tm("COUNTY_INFO.LÄÄNEMAA"),
    pärnumaa: tm("COUNTY_INFO.PÄRNUMAA"),
    raplamaa: tm("COUNTY_INFO.RAPLAMAA"),
    järvamaa: tm("COUNTY_INFO.JÄRVAMAA"),
    viljandimaa: tm("COUNTY_INFO.VILJANDIMAA"),
    läänevirumaa: tm("COUNTY_INFO.LÄÄNE_VIRUMAA"),
    idavirumaa: tm("COUNTY_INFO.IDA_VIRUMAA"),
    jõgevamaa: tm("COUNTY_INFO.JÕGEVAMAA"),
    tartumaa: tm("COUNTY_INFO.TARTUMAA"),
    valgamaa: tm("COUNTY_INFO.VALGAMAA"),
    põlvamaa: tm("COUNTY_INFO.PÕLVAMAA"),
    võrumaa: tm("COUNTY_INFO.VÕRUMAA"),
  };
};
</script>

<style lang="scss" scoped>
.section {
  padding-top: 88px;
}
</style>
