const phoneReg = /^\d+$/;
const dialCodeEst = "372";

export function getMaxPhoneLength(dialCode: string) {
    dialCode = (dialCode || "").replace("+", "").trim();
    return dialCode === dialCodeEst ? 8 : (15 - dialCode.length);
}

export function validatePhone(dialCode: string, phone: string) {
    return !!dialCode && !!phone && phoneReg.test(phone) && phone.length >= 6 && phone.length <= getMaxPhoneLength(dialCode);
}
