<template>
  <b-container class="login" fluid="sm">
    <div class="row">
      <div class="login-form mw-100" style="float: none; margin: 0 auto">
        <div class="login-header">{{ t("LOG_IN") }}</div>
        <div class="header-separator">
          <div class="separator-line"></div>
        </div>
        <b-tabs
            content-class=""
            justified
            class="nav-tabs"
            active-nav-item-class="bold"
        >
          <b-tab
              active
              title-link-class="text16-black"
              class="nav-link"
              :title="t('IDENTIFIER')"
          >
            <b-button variant="blue" class="enter-btn" v-on:click="login">{{
                t("ENTER")
              }}
            </b-button>
          </b-tab>
          <b-tab
              :title="t('PASSWORD')"
              title-link-class="text16-black"
              class="nav-link"
          >
            <div v-if="!showResetForm">
              <b-row class="mb-2">
                <b-col>
                  <form @submit.prevent="passwordLogin">
                    <b-input-group>
                      <b-input
                          type="text"
                          :placeholder="t('SSN')"
                          oninput="this.value = this.value.replace(/\s/g, '')"
                          autofocus
                          class="mb-2 form-control"
                          v-model="creds.username"
                      ></b-input>
                    </b-input-group>
                    <b-input-group>
                      <b-input
                          :placeholder="t('PASSWORD')"
                          :type="!passwordHidden ? 'password' : 'text'"
                          class="form-control rounded"
                          v-model="creds.password"
                      ></b-input>
                      <b-input-group-append>
              <span v-if="creds.password !== ''" class="input-group-text">
                <b-icon-eye-slash v-if="!passwordHidden" @click="toggleShowPassword"></b-icon-eye-slash>
                <b-icon-eye v-if="passwordHidden" @click="toggleShowPassword"></b-icon-eye></span>
                      </b-input-group-append>
                    </b-input-group>
                  </form>
                </b-col>

              </b-row>
              <b-row class="mb-2 error-message">
                <b-col v-if="!activatedAccount"
                >
                  {{ t("NOT_ACTIVE_ACCOUNT2") }}
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col>
                  <b-button
                      variant="blue"
                      class="enter-btn"
                      v-on:click="passwordLogin()"
                  >{{ t("ENTER") }}
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="text-center">
                  <b-link class="text16-blue" v-on:click="showResetForm = true">{{
                      t("FORGOT_PASSWORD")
                    }}
                  </b-link>
                </b-col>
              </b-row>
            </div>
            <reset-password
                :showResetForm="showResetForm"
                @hide-form="resetPasswordHandler"
            ></reset-password>
          </b-tab>
        </b-tabs>
        <div class="header-separator">
          <div class="separator-line"></div>
        </div>
        <div class="col mb-2">
          <div class="no-account-txt">{{ t("NO_ACCOUNT2") }}<br/></div>
          <div class="no-account-txt">
            <router-link to="/login/register">{{
                t("REGISTER_HERE")
              }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script setup>
import ResetPassword from "@/views/components/ResetPassword.vue";
import {toast} from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import {useI18n} from "vue-i18n";
import {inject, ref} from "vue";
import {useRoute} from "vue-router";

const {t, tm} = useI18n();
const auth = inject("auth");
const axios = inject("axios");
const route = useRoute();


const showResetForm = ref(false);
const creds = ref({
  username: "",
  password: "",
});
const oAuthEndpoint = import.meta.env.VITE_OAUTH_ENDPOINT;
const activatedAccount = ref(true);
const passwordHidden = ref(false);
const resetPasswordHandler = () => {
  showResetForm.value = false
}

function toggleShowPassword() {
  passwordHidden.value = !passwordHidden.value;
}

async function passwordLogin() {
  try {
    const response = await axios.post("/login/plain", creds.value, {
      errorHandle: false,
    });
    if (response && response.data) {
      await auth.login(response.data.token);
    }
  } catch (e) {
    console.error(e);

    if (e.response.data.message === "NOT_ACTIVATED_ACCOUNT") {
      activatedAccount.value = false;
    }
  }
  creds.value.password = "";
}

const getOAuthURL = () => {
  return oAuthEndpoint + "?" + new Date().getTime();
};

const login = () => {
  window.location.href = getOAuthURL();
};


</script>

<style scoped>
.login {
  margin-top: 64px;
}

.login-form {
  display: flex;
  flex-direction: column;
  position: static;
  width: 550px;
  left: 418px;
  top: 20px;

  flex: none;
  order: 0;
  align-self: center;
  margin: 40px;
}

.error-message {
  color: #ff0000;
}

.no-account-txt {
  justify-content: center;
  font-family: Aino, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #323334;
}

.nav-tabs .active {
  font-size: 16px;
  border: 0;
}

.nav-tabs {
  background: #f0f1f2;
  border: 0;
}

.form-control {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background: white;
  border-bottom-color: #f0f1f2;
}

.login-header {
  position: static;
  width: 400px;
  height: 55px;
  font-family: "Aino Headline";
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 115%;
  text-align: center;
  text-transform: lowercase;
  color: #0000f0;
  flex: none;
  order: 0;
  align-self: center;
}

.header-separator {
  width: 550px;
}
</style>
