<template>
  <div>
    <Editor
        :key="localContent ? localContent.length : 0"
        v-if="isContentManager()"
        inline=""
        :initial-value="localContent"
        v-model="localContent"
        :init="init"
    />
    <div v-else v-html="localContent"></div>
  </div>
</template>

<script setup>
import Editor from "@tinymce/tinymce-vue";
import {computed, inject, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/media';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import '@/scss/custom.scss';

const { t, locale } = useI18n();
const auth = inject("auth");

const props = defineProps({
  content: String,
  contentId: String,
  index: Number,
  title: {
    type: Boolean,
    default: false,
  },
  white: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["save"]);
const localContent = ref(props.content);

watch(
    () => props.content,
    (value) => {
      localContent.value = value;
    },
    { deep: true },
);

const formats = {
  titleWhite: { block: "div", classes: "title-white" },
  titleBlue: { block: "div", classes: "title-blue" },
  text14White: { block: "div", classes: "text14-white" },
  text16White: { block: "div", classes: "text16-white" },
  text18White: { block: "div", classes: "text18-white" },
  text24White: { block: "div", classes: "text24-white" },
  text14Black: { block: "div", classes: "text14-black" },
  text16Black: { block: "div", classes: "text16-black" },
  text18Black: { block: "div", classes: "text18-black" },
  text24Black: { block: "div", classes: "text24-black" },
  text16Blue: { block: "div", classes: "text16-blue" },
  text18Blue: { block: "div", classes: "text18-blue" },
  text24Blue: { block: "div", classes: "text24-blue" },
  text14Gray: { block: "div", classes: "text14-gray" },
  text14LightGray: { block: "div", classes: "text14-light-gray" },
  bold: { selector: "div", classes: "bold" },
};
const styleFormats = [
  { title: "Valge/white pealkiri", format: "titleWhite" },
  { title: "Sinine/blue pealkiri", format: "titleBlue" },
  { title: "14 valge/white", format: "text14White" },
  { title: "16 valge/white", format: "text16White" },
  { title: "18 valge/white", format: "text18White" },
  { title: "24 valge/white", format: "text24White" },
  { title: "14 must/black", format: "text14Black" },
  { title: "16 must/black", format: "text16Black" },
  { title: "18 must/black", format: "text18Black" },
  { title: "24 must/black", format: "text24Black" },
  { title: "16 sinine/blue", format: "text16Blue" },
  { title: "18 sinine/blue", format: "text18Blue" },
  { title: "24 sinine/blue", format: "text24Blue" },
  { title: "14 hall/gray", format: "text14Gray" },
  { title: "14 helehall/light-gray", format: "text14LightGray" },
];

const plugins = [
  'autolink',
  'lists',
  'link',
  'image',
  'searchreplace',
  'media',
  'table',
  'fullscreen',
  'help',
  'wordcount',
  'code'
];
const toolbar = [
  "undo redo | styleselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent hr | help | saveBtn"];

const isContentManager = () => {
  return auth.hasRole("CONTENT_MANAGER");
};
const init = computed(() => {

  const defaultSize = props.title ? "title" : "text18";
  const defaultColor = props.white ? "white" : (props.title ? "blue" : "black");
  const saveFunc = save;
  const btnText = "Salvesta sisu";
  return {
    // eslint-disable-next-line @typescript-eslint/camelcase
    paste_data_images: true,
    // eslint-disable-next-line @typescript-eslint/camelcase
    forced_root_block: "div",
    placeholder: "PLACEHOLDER",
    formats: formats,
    // // eslint-disable-next-line @typescript-eslint/camelcase
    style_formats: styleFormats,
    // eslint-disable-next-line @typescript-eslint/camelcase
    preview_styles: false,
    plugins: plugins,
    toolbar: toolbar,
    base_url: '/tinymce',
    branding: false,
    setup(editor) {
      editor.on('init', () => {

        editor.getBody().style.paddingTop = '50px';
      });
      editor.ui.registry.addButton("saveBtn", {
        text: btnText,
        onAction: function () {
          const textValue = editor.bodyElement.textContent.replace("\n", " ");
          saveFunc(textValue, editor.getContent());
        },
      });
    },
    init_instance_callback(editor) {
      editor.dom.select("div").forEach(function (el) {
        if (!el.classList.length) {
          el.classList.add(defaultSize + "-" + defaultColor);
        }
      });
    },
  };
});

const save = (textValue, value) => {
  if (props.content !== textValue) {
    emit("save", { element: props.contentId, value });
  }
};

const originalAddEventListener = EventTarget.prototype.addEventListener;
EventTarget.prototype.addEventListener = function(type, listener, options) {
  if (type === 'scroll' || type === 'touchstart' || type === 'touchmove' || type === 'wheel') {
    if (typeof options === 'object' && options !== null) {
      options.passive = true;
    } else if (typeof options === 'boolean') {
      options = { capture: options, passive: true };
    }
  }
  return originalAddEventListener.call(this, type, listener, options);
};
</script>

<style lang="scss" scoped>
.editable-content {
  margin-top: 50px;
}
</style>
