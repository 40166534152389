<template>
  <b-modal
      id="school-module-modal"
      ref="schoolModuleModalRef"
      :title="t('ALLOW_MODULES')"
      header-class="text18-black bold"
      @ok.prevent="handleOk"
      role="dialog"
      size="lg"
  >
    <template #modal-header-close>
      <b-img :src="removeIcon"></b-img>
    </template>
    <b-row>
      <div class="col">
        <b-checkbox-group v-model="selectedTrainings" id="checkbox">
          <ul>
            <li v-for="training in trainings" :key="training.id">
              <b-checkbox :value="training.id">
                {{ training.code }}
              </b-checkbox>
              {{ training.name }}
            </li>
          </ul>
        </b-checkbox-group>
      </div>
    </b-row>
    <template #modal-footer>
      <b-button variant="blue" @click="addModules">
        {{ t('CONFIRM') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import { ref, inject, onMounted, onBeforeUnmount} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';
import EventBus from '@/util/eventBus';
import removeIcon from '@/assets/icon/icon_x.svg';

const axios = inject('axios');
const props = defineProps({
  trainings: Array
});
const emit = defineEmits(['update-complete']);
const { t } = useI18n();
const route = useRoute();
const schoolModuleModalRef = ref(null);
const selectedTrainings = ref([]);

onMounted(() => {
  EventBus.$on('show-school-module-modal', () => schoolModuleModalRef.value.show());
});
onBeforeUnmount(() => {
  EventBus.$off('show-school-module-modal');
});
function handleOk(event) {
  event.preventDefault();
}
async function addModules() {
  try {
    const resp = await axios.post(`/school/${route.params.id}/add-module`, selectedTrainings.value);
    toast(t('ADDED_SCHOOL'), { variant: 'success', title: t('OPERATION_SUCCESSFUL') });
    emit('update-complete', true);
    schoolModuleModalRef.value.hide();
  } catch (error) {
    console.error(error);
    emit('update-complete', true);
  }
}
</script>
<style>
ul {
  list-style-type: none;
}

.label {
  margin-left: 50px;
  padding-left: 50px;
  margin-right: 50px;
}
</style>
