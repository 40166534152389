<template>
  <div>
    <b-container>
      <b-row class="section-header">
        <b-col>
          <div class="title-blue">{{ t("TEMP_MODULE") }}</div>
        </b-col>
      </b-row>
      <b-row class="section">
        <b-col cols="12">
          <text-block
            content-id="introBlock"
            v-on:save="save"
            :content="content.elements.introBlock"
          ></text-block>
        </b-col>
<!--        <b-col cols="8">-->
<!--          <module-page-calendar-->
<!--            :module="'TEMPORARY_PROTECTION_TRAINING'"-->
<!--          ></module-page-calendar>-->
<!--        </b-col>-->
      </b-row>
    </b-container>

    <div class="section" id="audience">
      <b-container>
        <b-row>
          <b-col cols="6">
            <text-block
              content-id="infoBlock1"
              v-on:save="save"
              :content="content.elements.infoBlock1"
            ></text-block>
          </b-col>
          <b-col cols="6">
            <text-block
              content-id="infoBlock2"
              v-on:save="save"
              :content="content.elements.infoBlock2"
            ></text-block>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="section">
      <b-row v-b-toggle.benefitsCollapse class="py-3">
        <b-col cols="auto">
          <div
            class="text24-blue"
            style="font-size: 32px"
            v-b-toggle.benefitsCollapse
          >
            {{ t("BENEFITS") }}
          </div>
        </b-col>
        <b-col cols="1" class="ml-auto text-right">
          <b-icon-chevron-down style="color: blue" class="closed"></b-icon-chevron-down>
          <b-icon-chevron-up style="color: blue" class="opened"></b-icon-chevron-up>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-collapse visible id="benefitsCollapse" class="mt-4">
            <text-and-picture
              :data="benefitsBlock"
              :picture-left="true"
            ></text-and-picture>
          </b-collapse>
        </b-col>
      </b-row>

      <b-row v-b-toggle.methodsCollapse class="py-3">
        <b-col cols="auto">
          <div
            class="text24-blue"
            style="font-size: 32px"
            v-b-toggle.methodsCollapse
          >
            {{ t("TRAINING_METHODS") }}
          </div>
        </b-col>
        <b-col cols="1" class="ml-auto text-right">
          <b-icon-chevron-down style="color: blue" class="closed"></b-icon-chevron-down>
          <b-icon-chevron-up style="color: blue" class="opened"></b-icon-chevron-up>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-collapse visible id="methodsCollapse" class="mt-4">
            <text-and-picture :data="methodsBlock"></text-and-picture>
          </b-collapse>
        </b-col>
      </b-row>

      <b-row v-b-toggle.structureCollapse class="py-3">
        <b-col cols="auto">
          <div
            class="text24-blue"
            style="font-size: 32px"
            v-b-toggle.structureCollapse
          >
            {{ t("STRUCT") }}
          </div>
        </b-col>
        <b-col cols="1" class="ml-auto text-right">
          <b-icon-chevron-down style="color: blue" class="closed"></b-icon-chevron-down>
          <b-icon-chevron-up style="color: blue" class="opened"></b-icon-chevron-up>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-collapse visible id="structureCollapse" class="mt-4">
            <text-block
              content-id="structureBlock"
              v-on:save="save"
              :content="content.elements.structureBlock"
            ></text-block>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>

    <div
      class="section"
      style="background-color: #d2c3d4; background-size: cover"
    >
      <b-container>
        <b-row>
          <b-col cols="6">
            <text-and-picture
              :data="curatorsBlock1"
              :picture-left="true"
            ></text-and-picture>
          </b-col>
          <b-col cols="6">
            <text-and-picture
              :data="curatorsBlock2"
              :picture-left="true"
            ></text-and-picture>
          </b-col>
        </b-row>
      </b-container>
      <div>
        <b-container>
          <b-row>
            <b-col cols="2">
              <img
                  alt="Ministry of Culture"
                  id="culture"
                  :src="picture[locale]"
              />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {usePageContent} from "@/mixins/pageContentMixin2";

import TextAndPicture from "@/views/components/TextAndPicture.vue";
import TextBlock from "@/views/components/TextBlock.vue";
import modulePageCalendar from "@/views/components/modulePageCalendar.vue";
import pictureEn from "@/assets/kultuurimin_3lovi_eng_pms.svg";
import pictureRus from "@/assets/kultuurimin_3lovi_eng_pms.svg";
import pictureEst from "@/assets/kultuurimin_3lovi_est.svg";
import training from "@/assets/training.svg";
import training_bg from "@/assets/training_bg.svg";
import icon_cert from "@/assets/icon/icon_cert.svg";
import men from "@/assets/men.svg";
import men_bg from "@/assets/men_bg.svg";
import insa_icon from "@/assets/insa.png";
import logo_esf_eng from "@/assets/logo_esf_eng.jpg";

const {getPageContent, savePageContent} = usePageContent();
const axios = inject("axios");
const {t, locale} = useI18n();
const content = ref({elements: {}});
const pageName = "temporaryProtectionModule";
const insa = ref(insa_icon);
const esf = ref(logo_esf_eng);
const picture = {
  EST: pictureEst,
  ENG: pictureEn,
  RUS: pictureRus,
};

onMounted(() => {
  EventBus.$on("locale", loadPageContent);
  loadPageContent();
});
onBeforeUnmount(() => {
  EventBus.$off("locale", loadPageContent);
});

async function loadPageContent() {
  content.value = await getPageContent(axios, pageName, locale.value);
}

const save = (elem) => {
  savePageContent(axios, pageName, locale.value, elem.element, elem.value);
}

const benefitsBlock = computed(() => {
  return {
    page: pageName,
    element: "benefitsBlock",
    image: {
      image: men,
      mask: {
        src: men_bg,
        style: "position: absolute; right: -387px; top: -19px;",
      },
    },
    text1: content.value ? content.value.elements.benefitsBlockText1 : "",
    text2: content.value ? content.value.elements.benefitsBlockText2 : "",
  };
});
const methodsBlock = computed(() => {
  return {
    page: pageName,
    element: "methodsBlock",
    image: {
      image: training,
      mask: {
        src: training_bg,
        style: "position: absolute; right: -311px; top: -22px;",
      },
    },
    text1: content.value ? content.value.elements.methodsBlockText1 : "",
    text2: content.value ? content.value.elements.methodsBlockText2 : "",
  };
});
const curatorsBlock1 = computed(() => {
  return {
    page: pageName,
    element: "curatorsBlock1",
    image: { image: icon_cert },
    text1: content.value ? content.value.elements.curatorsBlock1Text1 : "",
    text2: content.value ? content.value.elements.curatorsBlock1Text2 : "",
  };
});

const curatorsBlock2 = computed(() => {
  return {
    page: pageName,
    element: "curatorsBlock2",
    image: { image: icon_cert },
    text1: content.value ? content.value.elements.curatorsBlock2Text1 : "",
    text2: content.value ? content.value.elements.curatorsBlock2Text2 : "",
  };
});

</script>

<style scoped lang="scss">
.collapsed > div > .opened,
:not(.collapsed) > div > .closed {
  display: none;
}
#audience {
  background: url("@/assets/audience_gradience.svg");
  background-size: cover;
}
#culture {
  position: absolute;
  display: flex;
  justify-content: space-around;
  padding: 0;
  left: 460%;
  transform: translate(-50%, -50%);
  width: 330px;
  height: 130px;
  margin-top: 45px;
}
</style>
