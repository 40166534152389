<template>
  <div id="home-container" class="pl-20">
    <div id="jumbotron-container">
      <b-container>
        <b-row>
          <b-col cols lg="7">
            <text-block
              content-id="jumbotronHeader"
              v-on:save="save"
              :content="content.elements.jumbotronHeader"
            ></text-block>
            <text-block
              content-id="jumbotronText"
              v-on:save="save"
              :content="content.elements.jumbotronText"
            ></text-block>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div id="section-links" class="section">
      <b-container>
        <b-row>
          <b-col>
            <b-row>
              <b-col class="text-right">
                <text-block
                  content-id="linksHeader1"
                  v-on:save="save"
                  :content="content.elements.linksHeader1"
                ></text-block>
                <text-block
                  content-id="links1"
                  v-on:save="save"
                  :content="content.elements.links1"
                ></text-block>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <img
              id="question-mark"
              src="@/assets/icon/icon_questionmark_large.svg"
            />
          </b-col>
          <b-col>
            <b-row>
              <b-col cols="12">
                <text-block
                  content-id="linksHeader2"
                  v-on:save="save"
                  :content="content.elements.linksHeader2"
                ></text-block>
                <text-block
                  content-id="links2"
                  v-on:save="save"
                  :content="content.elements.links2"
                ></text-block>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div id="section-news" class="section">
      <b-container>
        <b-row>
          <b-col cols="12">
            <img class="mw-100" src="@/assets/family.jpg" />
          </b-col>
          <b-col cols="12" class="mt-4">
            <a
              class="news-headline"
              v-bind:href="t('CIRCULAR_ECONOMY_IN_ESTONIA_LINK')"
              target="_blank"
              rel="noopener noreferrer"
              >{{ t("CIRCULAR_ECONOMY_IN_ESTONIA") }}</a
            >
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div id="section-contacts" class="section">
      <b-container>
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <div class="text24-black">{{ t("IMPORTANT_CONTACTS") }}</div>
              </b-col>
            </b-row>
            <b-row id="important-contacts" class="mt-4 mb-n4">
              <b-col
                cols="4"
                v-for="contact in contacts"
                :key="contact.title"
                class="white-space"
              >
                <b-row>
                  <b-col cols="auto">
                    <img :src="contact.icon" />
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <div class="title-blue">{{ contact.title }}</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="text16-black">
                          {{ contact.description }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script setup>
import {computed, inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {usePageContent} from "@/mixins/pageContentMixin2";
import TextBlock from "@/views/components/TextBlock.vue";
import doctorIcon from "@/assets/icon_doctor.svg";
import exclamationIcon from "@/assets/icon_exclamation.svg";
import conversationIcon from "@/assets/icon_conversation.svg";


const {getPageContent, savePageContent} = usePageContent();
const axios = inject("axios");
const {t, locale} = useI18n();
const content = ref({elements: {}});
const pageName = "home";

onMounted(() => {
  EventBus.$on("locale", loadPageContent);
  loadPageContent();
});

onBeforeUnmount(() => {
  EventBus.$off("locale", loadPageContent);
});

async function loadPageContent() {
  content.value = await getPageContent(axios, pageName, locale.value);
}

const save = (elem) => {
  savePageContent(axios, pageName, locale.value, elem.element, elem.value);
};
const contacts = computed(() => {
  return getContacts();
});


const getContacts = () => {
  return [
    {
      title: "1220",
      description: t("HOMEPAGE_IMPORTANT_CONTACTS.FAMILY_DOCTOR"),
      icon: doctorIcon,
    },
    {
      title: "1247",
      description: t("HOMEPAGE_IMPORTANT_CONTACTS.CRISIS_HOTLINE"),
      icon: exclamationIcon,
    },
    {
      title: "6123500",
      description: t("HOMEPAGE_IMPORTANT_CONTACTS.MIGRATION_CONSULTANT"),
      icon: conversationIcon,
    },
  ];
};
</script>
<style lang="scss" scoped>
#jumbotron-container {
  background:
    linear-gradient(270deg, rgba(0, 0, 150, 0.2) 0%, #000096 73.46%),
    url("@/assets/home_pic1.png") no-repeat 282px 0;
  background-size: cover;
  padding-top: 240px;
  padding-bottom: 146px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -112px;
}

#btn-signup {
  padding: 16px;
  background-color: transparent;
}

#section-links {
  background-color: $color-blue;
}

#section-contacts {
  background-color: $color-yellow;
}

@media screen and (max-width: 768px) {
  #important-contacts {
    display: inline-flex;
    flex-direction: row !important;
  }
  #question-mark {
    height: 150px;
    width: 150px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .row {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .news-headline {
    font-size: 32px;
    line-height: 38px;
    color: $color-blue;
  }
  .white-space {
    white-space: pre-line;
    min-height: 410px;
    min-width: 150px;
    margin-bottom: 2rem;
  }
}
</style>
