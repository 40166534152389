<template>
  <b-modal v-model=isModalVisibleComputed
           id="update-monthly-news-modal"
           size="md"
           @ok="handleOk"
           header-class="text24-blue mt-3"
           role="dialog"
           :ok-hidden="true"
           :title="t('UPDATE_MONTHLY_NEWSLETTER')"
  >
    <template v-slot:modal-header-close>
      <b-img :src="(iconX)"></b-img>
    </template>
    <vue-date-picker
        id="newsDate"
        size="lg"
        :monthLabels="monthLabels"
        v-model="updatedNewsDate"
        class="date-position"
        :place-holder="t('CHOOSE_DATE')"
    ></vue-date-picker>
    <template v-if="newsletterCur">
      <b-form-group
          v-for="url in newsletterCur.urls"
          :key="url.id"
          class="label-style"
          label-for="groupLang"
          label-cols-sm="1"
      >
        <template v-slot:label>{{ url.lang }}</template>
        <b-form-input
            id="groupLang"
          v-model="url.url"
          v-bind:class="missingValueBorderHighlight(url.url)"
          placeholder="URL"
        >
        </b-form-input>
      </b-form-group>
    </template>
    <template v-slot:modal-footer>
      <b-button @click="cancel">{{ t("CANCEL") }}</b-button>
      <b-button variant="blue" @click="handleOk" :disabled="updateDisabled">
        {{ t("SAVE") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import '@vuepic/vue-datepicker/dist/main.css';
import {highlightStyle} from "@/mixins/highlightStyleMixin2";
import {computed,  inject, onBeforeUnmount, onMounted, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {toast} from "vue3-toastify"
import iconX from "@/assets/icon/icon_x.svg?inline";
import VueDatePicker from '@vuepic/vue-datepicker';

const {missingValueTextHighlight, missingValueBorderHighlight} = highlightStyle();
const axios = inject("axios");
const {t} = useI18n();

const updatedNewsDate = ref(null)
const props = defineProps(['isVisibleUpdateModal']);
const emits = defineEmits(['update-value'])
const {isVisibleUpdateModal} = toRefs(props);
const isModalVisible = ref(false)

const newsletterCur = ref('')

const monthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

onMounted(() => {
  EventBus.$on("show-update-monthly-news-modal", showModal);
})
onBeforeUnmount(() => {
  EventBus.$off("show-update-monthly-news-modal", showModal);
})

const isModalVisibleComputed = computed(() => isModalVisible.value = props.isVisibleUpdateModal)

const updateDisabled = computed(() => {
  const emptyUrl = newsletterCur.value.urls.find((x) => x.url === "");
  return !!(emptyUrl || !newsletterCur.value.newsDate);
})

function showModal(newsletter) {
  isModalVisible.value = props.isVisibleUpdateModal
  newsletterCur.value = newsletter
}

function cancel() {
  isModalVisible.value = false
  emits('update-value-update', false)
}

async function handleOk(bvModalEvt) {
  bvModalEvt.preventDefault();
  if (updatedNewsDate.value !== newsletterCur.value.newsDate) {
    const date = new Date(updatedNewsDate.value);
    date.setMonth(date.getMonth() + 1);
    newsletterCur.value.newsDate = date;
  }
  try {
    const resp = await axios.put(
        `/news/${newsletterCur.value.id}`,
        newsletterCur.value,
    )
    if (resp && resp.data) {
      EventBus.$emit("FETCH_MONTHLY_NEWS");
      cancel();
      makeToast(
          t("MONTHLY_NEWS_UPDATED"),
          "success",
          t("OPERATION_SUCCESSFUL"),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};
</script>

<style lang="less">
.date-position {
  margin-bottom: 30px;
}

.label-style {
  font-weight: bold;
}
</style>
