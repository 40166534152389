<template>
  <b-modal
      size="xl"
      id="blog-creation-modal"
      ref="blogCreationModalRef"
      @ok="handleOk"
      header-class="text18-black bold"
      role="dialog"
      :title="t('CREATE_BLOG')"
  >
    <template v-slot:modal-header-close>
      <b-img :src="(removeIcon)"></b-img>
    </template>
    <b-row>
      <div class="col">
        <add-blog-form
            ref="blogFormRef"
            :disabled-langs="getAvailableLanguages"
            @submit-blog="createBlog"
        ></add-blog-form>
      </div>
    </b-row>
    <template v-slot:modal-footer>
      <b-button variant="blue" @click="handleOk">
        {{ t("CONFIRM") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>

import {computed, inject, nextTick, onBeforeUnmount, onMounted, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import eventBus from "@/util/eventBus";
import {toast} from "vue3-toastify";
import addBlogForm from "@/views/components/forms/AddBlogForm.vue";
import removeIcon from "@/assets/icon/icon_x.svg";

const axios = inject("axios");
const {t, locale} = useI18n();
const blogCreationModalRef = ref(null)
const blogFormRef = ref(null)
const props = defineProps(["existingLanguages", "groupId"])
const {existingLanguages, groupId} = toRefs(props);
const emits = defineEmits(['added-blog'])

onMounted(() => {
  eventBus.$on("show-blog-creation-modal", showModal)
})

onBeforeUnmount(() => {
  eventBus.$off("show-blog-creation-modal", showModal);
})

async function showModal() {
  await nextTick()
  if (blogCreationModalRef.value) {
    blogCreationModalRef.value.show("blog-creation-modal");
  } else {
    console.error('blogCreationModalRef is null')
  }
}

function hideModal() {
  blogCreationModalRef.value.hide("blog-creation-modal")
}

function handleOk() {
  blogFormRef.value.submitForm()
}

async function createBlog(blog) {
  try {
    if (groupId.value) {
      blog.groupId = groupId.value;
    }
    const resp = await axios.post("/client/blog", blog);
    if (resp && String(resp.status).charAt(0) === "2") {
      makeToast(
          t("ADDED_BLOG"),
          "success",
          t("OPERATION_SUCCESSFUL"),
      );
      emits("added-blog", resp.data);
      hideModal()
    }
  } catch (e) {
    console.log(e);
  }
}

const getAvailableLanguages = computed(() => {
  return existingLanguages.value ? existingLanguages.value : [];
})

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};
</script>
