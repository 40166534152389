<template>
  <b-container class="account" fluid="sm">
    <div class="row">
      <div class="account-form" style="float: none; margin: 0 auto">
        <div class="account-card">
          <h3 class="title-blue" style="text-align: center">
            {{ t("CREATE_ACCOUNT") }}
          </h3>
          <div class="text-center text18-blue">
            {{ t("CREATION_TIP") }}
          </div>
          <br/>
          <div class="text-center text18-blue">
            {{ t("CREATION_INFO") }}
          </div>
          <b-alert variant="success" :show="showSuccessAlert" class="mt-4">
            {{ t("REGISTRATION_SUCCESS") }}
          </b-alert>
          <div class="header-separator">
            <div class="separator-line"></div>
          </div>
          <fieldset class="mt-4">

            <b-form ref="form"
                    @submit.prevent="onSubmit"

                    id="accountForm"
                    name="accountForm"
                    v-if="!showSuccessAlert"
            >
              <b-alert variant="warning" :show="showNameAlert" class="mt-2">
                {{ t("NAME_TIP") }}
              </b-alert>
              <b-row>
                <b-col>
                  <b-form-group
                      :invalid-feedback="fnFeedback()"
                      :state="firstNameState()"
                  >
                    <b-input
                        :placeholder="t('FIRST_NAME')"
                        type="text"
                        v-model.trim="account.firstName"
                        minlength="2"

                        title="Please use only Latin letters"
                        :invalid-feedback="fnFeedback()"
                        :state="firstNameState()"
                        v-bind:class="missingValueBorderHighlight(account.firstName)"
                        required
                    >
                    </b-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                      :invalid-feedback="lnFeedback()"
                      :state="lastNameState()"
                  >
                    <b-input
                        :placeholder="t('SURNAME')"
                        type="text"
                        v-model.trim="account.lastName"
                        minlength="2"

                        title="Please use only Latin letters"
                        :invalid-feedback="lnFeedback()"
                        :state="lastNameState()"
                        v-bind:class="missingValueBorderHighlight(account.lastName) "
                    >
                    </b-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="errorText" v-if="existingSsn"
                >{{ t("ERRORS.SSN_ALREADY_EXISTS") }}
                  {{ t("PLEASE_LOGIN") }}
                  <b-link v-bind:to="{ name: 'Login' }"
                  >{{ t("HERE") }}
                  </b-link>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                      id="ssn-group"
                      :invalid-feedback="invalidFeedback()"
                      :state="state()"
                  >
                    <b-form-input
                        type="text"
                        :title="t('ONLY_DIGITAL')"
                        oninput="value = value.replace(/\D/g, '')"
                        maxlength="11"
                        minlength="11"
                        required
                        :placeholder="t('SSN')"
                        v-model="account.username"
                        :state="state()"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group :invalid-feedback="pwFeedback()" :state="passwordState()">

                      <b-form-input class="mb-2"
                                    :placeholder="t('PASSWORD')" type="password" v-model="account.password" required
                                    minlength="15"
                                    :type="!passwordHidden1 ? 'password' : 'text'"
                                    :state="passwordState()"
                                    pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{15,}$"
                                    title="Use at least 15 uppercase, lowercase, numeric and @$!%*?& characters"
                      ></b-form-input>
<!--                      <b-input-group-append>-->
<!--                      <span v-if="form.passwordFirst !== ''" class="input-group-text">-->
<!--                <b-icon-eye-slash v-if="!passwordHidden1" @click="toggleShowPassword1"></b-icon-eye-slash>-->
<!--                <b-icon-eye v-if="passwordHidden1" @click="toggleShowPassword1"></b-icon-eye></span>-->
<!--                      </b-input-group-append>-->

                    <b-form-input :placeholder="t('PASSWORD')" type="password" v-model="account.verifiedPassword"
                                  required
                                  minlength="15"
                                  :state="passwordState()"
                                  pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{15,}$"
                                  title="Use at least 15 uppercase, lowercase, numeric and @$!%*?& characters"
                    ></b-form-input>

                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="email-group" :state="emailState()" :invalid-feedback="emailFeedback()">
                    <b-form-input class="mb-2"
                                  :state="emailState()" type="email" :placeholder="t('EMAIL')"
                                  pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}" v-model="account.email"
                                  required></b-form-input>
                    <b-form-input
                        :state="emailState()" type="email" :placeholder="t('EMAIL_2')"
                        pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}" v-model="account.email2"
                        required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="phone-grp" :state="phoneState()" :invalid-feedback="phoneFeedback()">
                <b-form-row>
                  <div class="col">
                    <b-form-select v-model="account.dialCode" required>
                      <template v-slot:first>
                        <b-form-select-option
                            :value="null"
                            disabled
                            selected="selected"

                        >{{ t("SELECT_DIAL") }}
                        </b-form-select-option>
                      </template>
                      <b-form-select-option
                          v-for="opt in dialCodeOptions()"
                          :value="opt.value"
                          v-bind:key="opt.text"
                      >{{ opt.text }}
                      </b-form-select-option>
                    </b-form-select>
                  </div>
                  <div class="col">
                    <b-form-input
                        :state="phoneState()"
                        id="phone"
                        name="phone"
                        type="tel"
                        minlength="7"
                        :maxlength="getMaxPhoneLength(account.dialCode)"
                        :placeholder="t('PHONE')"
                        v-model="account.phone"
                        oninput="value = value.replace(/\D/g, '')"
                        required
                    ></b-form-input>
                  </div>
                </b-form-row>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group id="education-group">
                    <b-form-select v-model="account.education" required>
                      <template v-slot:first>
                        <b-form-select-option
                            :value="null"
                            disabled
                            selected="selected"
                        >{{ t("SELECT_EDU") }}
                        </b-form-select-option>
                      </template>
                      <b-form-select-option
                          v-for="opt in educationOptions()"
                        :value="opt.value"
                        v-bind:key="opt.value"
                        >{{ opt.text }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="origin-grp">
                    <b-form-select v-model="account.origin" required>
                      <template v-slot:first>
                        <b-form-select-option
                            :value="null"
                            disabled
                            selected="selected"
                        >{{ t("LETUSKNOW") }}
                        </b-form-select-option>
                      </template>
                      <b-form-select-option
                          v-for="opt in originOptions()"
                          :value="opt.value"
                        v-bind:key="opt.text"
                        >{{ opt.text }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <b-form-group id="gdpr-grp">
                    <b-checkbox
                      id="gdpr"
                      name="gdpr"
                      value="true"
                      unchecked-value="false"
                      v-model="account.gdpr"
                      required
                    >
                      <div class="text14-gray">
                        {{ t("GDPR_AGREE") }}
                        <b-link
                          :to="{ name: 'GDPR' }"
                          target="_blank"
                          rel="noopener noreferrer"
                          >{{ t("GDPR") }}
                        </b-link>
                      </div>
                    </b-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col class="text-center text14-blue"
                  >{{ t("PD_TIP") }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <b-button
                      :disabled=!isFormValid()
                      variant="blue"
                      type="submit"
                      class="enter-btn"
                  >{{ t("REGISTER") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </fieldset>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script setup>

import {inject, ref} from "vue";
import {useI18n} from "vue-i18n";
import codes from "@/area_codes";
import {getMaxPhoneLength, validatePhone} from "@/util/phoneUtil";

const {t} = useI18n();
const axios = inject('axios');
const form = ref(null);
const account = ref({
  username: "",
  email: "",
  email2: "",
  password: "",
  verifiedPassword: "",
  firstName: "",
  lastName: "",
  phone: "",
  dialCode: null,
  education: null,
  origin: null,
  gdpr: false,
});

const existingSsn = ref(false);
const showNameAlert = ref(false);
const showSuccessAlert = ref(false);
const isLoading = ref(false);
const passwordHidden1 = ref(false);
const passwordHidden2 = ref(false);
const emailReg = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}/;
const pwReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[^\w\s]).{15,}$/;
const nameReg = /^[A-Za-zÄäÖöÜüÕõŽžŠš\s' -]+$/;

const emailState = () => {
  if (!account.value.email?.length && !account.value.email2?.length) {
    return null;
  }

  return account.value.email === account.value.email2 && validateEmail(account.value.email);
}

const passwordState = () => {
  if (!account.value.password?.length && !account.value.verifiedPassword?.length) {
    return null;
  }

  return account.value.password === account.value.verifiedPassword && isStrongPassword(account.value.password);
}

function validateEmail(email) {
  return email !== "" && emailReg.test(email);
}

function phoneFeedback() {
  if (!validatePhone(account.value?.dialCode, account.value?.phone)) {
    return t("FAULTY_PHONE", {maxLength: getMaxPhoneLength(account.value?.dialCode)})
  }

  return null;
}

const phoneState = () => {
  if (!account.value.phone) {
    return null;
  }

  return validatePhone(account.value?.dialCode, account.value?.phone);
};

function isStrongPassword(pw) {
  return pw !== "" && pwReg.test(pw);
}

function emailFeedback() {
  if (account.value.email !== account.value.email2) {
    return t("EMAIL_MISMATCH");
  }
  if ((account.value.email && !validateEmail(account.value.email)) && (account.value.email2 && !validateEmail(account.value.email2))) {
    return t("FAULTY_EMAIL")
  }
  return null;
}

function pwFeedback() {
  if ((account.value.password && !isStrongPassword(account.value.password)) && (account.value.verifiedPassword && !isStrongPassword(account.value.verifiedPassword))) {
    return t("PASSWORD_AGAINST_POLICY");
  }
  if (account.value.password !== account.value.verifiedPassword) {
    return t("PASSWORD_MISMATCH");
  }
  return null;
}


const validateName = (name) => {
  return nameReg.test(name);
};


function missingValueBorderHighlight(value) {
  if (!value && !account.value) return 'is-invalid';
}


const firstNameState = () => {
  if (!account.value.firstName) {
    return null;
  }
  return validateName(account.value.firstName);
};
const lastNameState = () => {
  if (!account.value.lastName) {
    return null;
  }
  return validateName(account.value.lastName);
};

const fnFeedback = () => {
  if (!firstNameState()) {
    return t('NAME_AGAINST_POLICY');
  }
  return '';
};
const lnFeedback = () => {
  if (!lastNameState()) {
    return t('NAME_AGAINST_POLICY');
  }
  return '';
};

const originOptions = () => {
  return [
    {value: "PPA", text: t("PPA")},
    {value: "SOCIAL_MEDIA", text: t("SOCIAL_MEDIA")},
    {value: "FRIENDS", text: t("FRIENDS")},
    {value: "WORK", text: t("WORK")},
    {value: "SCHOOL", text: t("SCHOOL")},
    {value: "LOCAL_MUNICIPALITY", text: t("LOCAL_MUNICIPALITY")},
    {value: "OTHER", text: t("OTHER")},
  ];
};
const educationOptions = () => {
  return [
    {value: "UNSPECIFIED", text: t("NOT_SPECIFIED")},
    {value: "PRIMARY", text: t("EDU_PRIMARY")},
    {value: "BASIC", text: t("EDU_BASIC")},
    {value: "SECONDARY", text: t("EDU_SECONDARY")},
    {value: "VOCATIONAL", text: t("EDU_VOCATIONAL")},
    {value: "HIGHER", text: t("EDU_HIGHER")},
  ];
};
const dialCodeOptions = () => {

  const areaCodes = [];
  for (const element of codes) {
    const dialCode = element.dial_code;
    const name = `${element.name} ${dialCode}`;
    areaCodes.push({value: dialCode, text: name});
  }
  return areaCodes;
};


function getControlNumber() {
  let i;
  const multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1],
      multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];
  let mod,
      total = 0;

  for (i = 0; i < 10; i++) {
    total += account.value.username.charAt(i) * multiplier1[i];
  }
  mod = total % 11;
  total = 0;
  if (10 === mod) {
    for (i = 0; i < 10; i++) {
      total += account.value.username.charAt(i) * multiplier2[i];
    }
    mod = total % 11;
    if (10 === mod) {
      mod = 0;
    }
  }
  return mod;
}

function validateSsn() {
  if (account.value.username.length !== 11) {
    return false;
  }
  const control = getControlNumber();
  if (control !== parseInt(account.value.username.charAt(10))) {
    return false;
  }
  const year = Number(account.value.username.substring(1, 3));
  const month = Number(account.value.username.substring(3, 5));
  const day = Number(account.value.username.substring(5, 7));
  const birthDate = getBirthday();
  return year === birthDate.getFullYear() % 100 && birthDate.getMonth() + 1 === month && day === birthDate.getDate();
}

function getBirthday() {
  let year = parseInt(account.value.username.substring(1, 3)),
      month = parseInt(account.value.username.substring(3, 5).replace(/^0/, '')) - 1,
      day = account.value.username.substring(5, 7).replace(/^0/, ''),
      firstNumber = account.value.username.charAt(0);

  if (firstNumber === '1' || firstNumber === '2') {
    year += 1800;
  } else if (firstNumber === '3' || firstNumber === '4') {
    year += 1900;
  } else if (firstNumber === '5' || firstNumber === '6') {
    year += 2000;
  } else {
    year += 2100;
  }

  return new Date(year, month, day);
}

const state = () => {
  if (!account.value.username) {
    return null;
  }
  return !(account.value.username && !validateSsn());
};
const invalidFeedback = () => {
  if (account.value.username && !validateSsn()) {
    return t("FAULTY_SSN");
  }
  return null;
};


function isFormValid() {
  return (validateName(account.value.firstName) && validateName(account.value.lastName) &&
      validateEmail(account.value.email) && validateSsn() && account.value.password === account.value.verifiedPassword &&
      isStrongPassword(account.value.password) && account.value.dialCode !== null && account.value.origin !== null && account.value.education !== null) &&
      phoneState();
}


async function onSubmit(e) {
  if (!isFormValid()) {
    return false;
  }
  if (!account.value.firstName && !account.value.lastName) {
    showNameAlert.value = true;
    return showNameAlert.value;
  }
  if (validateSsn()) {
    try {
      e.preventDefault();
      isLoading.value = true;
      setTimeout(() => {
        isLoading.value = false;
      }, 1000);
      const response = await axios.post("/account", account.value, {
        errorHandle: false,
      });
      if (response && response.status === 201) {
        showSuccessAlert.value = true;
        makeToast(t("OPERATION_SUCCESSFUL"), "success", t("SAVED"));
      }
      return showSuccessAlert.value;
    } catch (e) {
      if (e.response.data === "SSN_ALREADY_EXISTS") {
        existingSsn.value = true;
      }

      console.error(e);
      return existingSsn.value;
    }
  } else {
    return false;
  }
}

</script>

<style scoped>
.form-control,
.custom-select {
  background-color: white;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: lightgray;
}
.account-card {
  background-color: white;
  border: 0;
  width: 400px;
}
.account {
  margin-top: 64px;
  margin-bottom: 64px;
}
.account-form {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: static;
  width: 400px;
  left: 418px;
  top: 70px;

  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 40px;
}
.errorText {
  color: red;
  font-style: italic;
}
</style>
