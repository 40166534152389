<template>
  <b-form id="schoolForm">

    <b-form-group
        :invalid-feedback="codeFeedback()"
        :state="codeState()">
      <label class="form-label">{{ t("SCHOOL_CODE") }}</label>
      <b-form-input
          id="registryCode"
          class="form-control"
          v-model="schoolForm.registryCode"
          type="text"
          :title="t('ONLY_DIGITAL')"
          oninput="this.value = this.value.replaceAll(/\D/g, '')"
          maxlength="8"
          minlength="8"
          required
          :invalid-feedback="codeFeedback()"
          :state="codeState()"
          v-bind:class="missingValueBorderHighlight(schoolForm.registryCode)"
      >
      </b-form-input>
    </b-form-group>
    <b-form-group
        :invalid-feedback="businessNameFeedback()"
        :state="businessNameState()">
      <label class="form-label">{{ t("SCHOOL_NAME") }}</label>
      <b-form-input
          id="businessName"
          type="text"
          v-model="schoolForm.businessName"
          minlength="5"
          :title="t('MIN_5_CHARS')"
          required
          :invalid-feedback="businessNameFeedback()"
          :state="businessNameState()"
          v-bind:class="missingValueBorderHighlight(schoolForm.businessName)"
      >
      </b-form-input>
    </b-form-group>
    <b-form-group>
      <label class="form-label">{{ t("SCHOOL_MANAGER") }}</label>
      <b-form-select v-model="schoolForm.selectedManagers" multiple >
        <b-form-select-option
            v-for="op in managerOptions"
            :key="op.id"
            :value="op.id"
        >
          {{ op.fullName }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group>
      <label class="form-label">{{ t("MODULES") }}</label>
      <b-form-select v-model="schoolForm.selectedModules" multiple >
        <b-form-select-option
            v-for="op in moduleOptions"
            :key="op.id"
            :value="op.id"
        >
          {{ op.code }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
  </b-form>
</template>
<script setup>
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import {toast} from "vue3-toastify";

defineExpose({ submitForm })
const {t} = useI18n();
const props = defineProps({managerOptions: Array, moduleOptions: Array})
const schoolForm = ref({
  registryCode: '',
  businessName: '',
  selectedManagers: [],
  selectedModules: []
})
const registryCodeRegex = /^\d{8}$/;
const emit = defineEmits(['submit-school'])

const validateCode = (code) => {
  return registryCodeRegex.test(code);
};
const validateBusinessName = (name) => {
  return name.trim().length > 5;
};

function isFormValid() {
  return (validateCode(schoolForm.value.registryCode) && validateBusinessName(schoolForm.value.businessName));
}

function submitForm() {
  if (!isFormValid()) {
    makeToast(t("CHECK_INPUT"), "error", t("ATTENTION"));
    return false;
  }

  emit('submit-school', schoolForm.value)
  return true;

}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    title: title,
    variant: variant,
    autoHideDelay: 5000,
  });
}
const codeFeedback = () => {
  if (!codeState()) {
    return t('ONLY_DIGITAL') + " " + t('MIN_8_CHARS');
  }
  return '';
};
const businessNameFeedback = () => {
  if (!businessNameState()) {
    return t('MIN_5_CHARS');
  }
  return '';
};
const codeState = () => {
  if (!schoolForm.value.registryCode) {
    return null;
  }
  return validateCode(schoolForm.value.registryCode);
};
const businessNameState = () => {
  if (!schoolForm.value.businessName) {
    return null;
  }
  return validateBusinessName(schoolForm.value.businessName);
};

function missingValueBorderHighlight(value) {
  if (!value && !schoolForm.value) return 'is-invalid';
}


</script>


<style scoped>
.errorText {
  color: red;
  font-style: italic;
}
</style>