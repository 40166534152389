<template>
  <b-container>
    <div class="text24-blue mt-3">
      {{ t("MIGRATION_STATISTICS") }}
      <b-button
        variant="blue"
        @click="openNewFileWindow()"
        :hidden="!isContentManager()"
      >
        <b-icon-plus></b-icon-plus>{{ t("ADD_NEW") }}
      </b-button>
    </div>
    <br />
    <add-new-file
      v-if="newStatisticsWindow"
      :fileIdentifier="fileIdentifier"
    ></add-new-file>
    <b-col cols="8">
      <b-dropdown class="m-2 dropdown-statistics" no-caret variant="link">
        <template #button-content>
          {{ t("DOWNLOAD_EST") }}
          <b-icon-cloud-download></b-icon-cloud-download>
        </template>
        <b-dropdown-item
          href="#"
          v-bind:key="item.id"
          v-for="item in getEstFiles"
        >
          <a @click="downloadFile(item.id, item.fileName)">
            {{ item.fileName }}
          </a>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col cols="8">
      <b-dropdown class="m-2" no-caret variant="link">
        <template #button-content>
          {{ t("DOWNLOAD_ENG") }}
          <b-icon-cloud-download></b-icon-cloud-download>
        </template>
        <b-dropdown-item
          href="#"
          v-bind:key="item.id"
          v-for="item in getEngFiles"
        >
          <a @click="downloadFile(item.id, item.fileName)">
            {{ item.fileName }}
          </a>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col cols="8">
      <b-dropdown class="m-2" no-caret variant="link">
        <template #button-content>
          {{ t("DOWNLOAD_RUS") }}
          <b-icon-cloud-download></b-icon-cloud-download>
        </template>
        <b-dropdown-item
          href="#"
          v-bind:key="item.id"
          v-for="item in getRusFiles"
        >
          <a
            v-if="getRusFiles.length > 0"
            @click="downloadFile(item.id, item.fileName)"
          >
            {{ item.fileName }}
          </a>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-container>
</template>

<script setup>

import { computed, inject, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useI18n } from "vue-i18n";
import EventBus from "@/util/eventBus";
import {useRoute, useRouter} from "vue-router";
import AddNewFile from "@/views/components/AddNewFile.vue";


const axios = inject("axios");
const { t } = useI18n();
const auth = inject("auth");
const router = useRouter();
const route = useRoute();
const newStatisticsWindow = ref(false);
const fileIdentifier = 'MIGRATION_STATISTICS';
const files = ref([]);

onBeforeMount(() => {
  EventBus.$on("FETCH_NEW_FILES", fetchData);
  fetchData();
});

onBeforeUnmount(() => {
  EventBus.$off("FETCH_NEW_FILES", fetchData);
});

const isContentManager = () => {
  return auth.isAuthenticated && auth.hasRole("CONTENT_MANAGER");
}

const getEstFiles = computed(() => {
  return filterEstFiles();
});

const getEngFiles = computed(() => {
  return filterEngFiles();
});

const getRusFiles = computed(() => {
  return filterRusFiles();
});

function openNewFileWindow() {
  newStatisticsWindow.value = true;
  }

async function fetchData() {
  try {
    const resp = await axios.get(
      `/file?fileIdentifier=${fileIdentifier}`,
    );
    if (resp.data && resp) {
      files.value = resp.data;
      return files.value;
    }
  } catch (e) {
    console.log(e);
  }
}

async function downloadFile(id, name) {
  try {
    const resp = await axios.get(`/file/${id}`, {
      responseType: "blob",
    });
    if (resp) {
      const fileURL = window.URL.createObjectURL(
        new Blob([resp.data], { type: `${resp.data.type}` }),
      );
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", name);
      document.body.appendChild(fileLink);

      fileLink.click();
    }
  } catch (e) {
    console.log(e);
  }
}
function filterEstFiles() {
  return files.value.filter((f) => f.lang === "EST");
}
function filterEngFiles() {
  return files.value.filter((f) => f.lang === "ENG");
}
function filterRusFiles() {
  return files.value.filter((f) => f.lang === "RUS");
}
</script>

<style lang="less">
.dropdown-statistics {
  padding-top: 10px;
}

.dropdown-toggle {
  border-color: #003cff;
  border-radius: 20px;
  color: #003cff;
  background-color: #ffffff;
  border-width: 2px;
}

.dropdown-toggle:hover {
  background-color: #ffffff;
  color: #003cff;
  border-color: #003cff;
}
</style>
