<template>
  <b-modal
    id="transfer-modal"
    ref="transferModalRef"
    @ok="handleTransferModalOk"
    @hidden="resetTransferModal"
    @show="getLanguageGroups"
    :ok-disabled="
      !transferTraineeModalData.destination || !transferTraineeModalData.reason
    "
    body-class="position-static"
  >
    <template v-slot:modal-title>
      {{
        t("TRANSFER_PERSON_FROM_GROUP", {
          name:
              transferTraineeModalData.trainee.firstName +
              " " +
              transferTraineeModalData.trainee.lastName,
        })
      }}
    </template>
    <template v-slot:modal-header-close>
      <b-img :src="(removeIcon)"></b-img>
    </template>
    <form ref="transferFormRef" @submit.stop.prevent="handleTransferModalSubmit">
      <b-form-group label-for="select-destination">
        <label class="form-label text18-black">{{ t("GROUP") }}</label>
        <b-form-select
          v-model="transferTraineeModalData.destination"
          :options="groupOptions"
          id="select-destination"
          name="select-destination"
        ></b-form-select>
      </b-form-group>
      <b-form-group>
        <label class="form-label text18-black">{{ t("NOTE") }}</label>
        <b-textarea
          v-model="transferTraineeModalData.reason"
          required
          autofocus
        ></b-textarea>
      </b-form-group>
    </form>
    <confirm-overlay
      :show="showConfirm"
      :text="'TRANSFER_PERSON_FROM_GROUP_CONFIRM'"
      v-on:yes="handleConfirmOk"
      v-on:no="handleConfirmCancel"
    />
    <template v-slot:modal-ok>
      {{ t("CONFIRM") }}
    </template>
    <template v-slot:modal-cancel>
      {{ t("CANCEL") }}
    </template>
  </b-modal>
</template>

<style scoped>
.form-control {
  background-color: white;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}
.custom-select {
  background-color: white;
}
</style>

<script setup>

import removeIcon from "@/assets/icon/icon_x.svg";
import EventBus from "@/util/eventBus";
import ConfirmOverlay from "@/views/components/modals/confirmOverlay.vue";
import {inject, nextTick, onMounted, onUnmounted, ref} from "vue";

import {useI18n} from "vue-i18n";

const emit = defineEmits(['transfer-trainee', 'no','yes'])
const {t, locale} = useI18n();
const auth = inject("auth");
const axios = inject("axios");
const transferModalRef = ref(null)


const props = defineProps(["current"]);

const transferTraineeModalData = ref({
  trainee: null,
  numberOfTrainees: null,
  reason: null,
  destination: null,

})
const groupOptions = ref(null)
const transferFormRef = ref(null)

const showConfirm = ref(false)

onMounted(() => {
  EventBus.$on("show-transfer-trainee-modal", showTransferTraineeModal);
   getLanguageGroups()

})

onUnmounted(() => {
  EventBus.$off("show-transfer-trainee-modal", showTransferTraineeModal);
})




function resetTransferModal() {
  transferTraineeModalData.value = {
    trainee: null,
    numberOfTrainees: null,
    reason: null,
    destination: null,
  }
  showConfirm.value = false;
}


function showTransferTraineeModal(trainee, code) {

  transferTraineeModalData.value.trainee = trainee;
  transferTraineeModalData.value.code = code;
  transferModalRef.value.show("transfer-modal")

}

function handleTransferModalOk(bvModalEvt) {
  bvModalEvt.preventDefault();
   handleTransferModalSubmit();
}

function handleTransferModalSubmit()
{
  if (!transferFormRef.value.checkValidity()) {
    return;
  }
  showConfirm.value = true
}
function handleConfirmCancel()
{
 showConfirm.value= false;
}

function handleConfirmOk() {
      emit("transfer-trainee", transferTraineeModalData.value);

      nextTick(() => {
        transferModalRef.value.hide("transfer-modal");
      });
    }


async function getLanguageGroups() {
    try {
    const resp = await axios.get(
        `/training/group/language/` + transferTraineeModalData.value.code,
    );
    if (resp && resp.data) {
      const enabledOptions = [];
          const disabledOptions = [];
          resp.data.forEach((row) => {
            if (row.id === props.current) {
              return;
            }
            const traineeCount = row.numberOfTrainees
              ? row.numberOfTrainees
              : 0;
            const text = `${row.title} (${traineeCount}/${row.groupSize})`;
            const disabled = traineeCount >= row.groupSize;
            const entry = {value: row.id, text: text, disabled: disabled};
            if (disabled) {
              [];
            } else {
              enabledOptions.push(entry);
            }
          });
      groupOptions.value = enabledOptions.concat(disabledOptions);
        }
      } catch (e) {
        console.log(e);
      }
    }
</script>
