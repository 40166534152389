<template>
  <div v-if="schoolData" class="container-fluid section-p70">
    <b-row>
      <b-col class="title-blue" cols="auto">
        {{ t("MANAGE_SCHOOL") }} - {{ schoolData.code }}
      </b-col>
      <b-col align-self="center">
        <b-button variant="blue" @click="toggleSchoolArchive()">
          {{ isArchived() ? t("ARCHIVED") : t("ARCHIVE") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col xl="3">
        <b-card class="text-left">
          <b-card-title>{{ t("BASE_DATA") }}</b-card-title>
          <b-form id="schoolForm" ref="form">
            <b-form-group label-for="code">
              <label class="form-label">{{ t("SCHOOL_CODE") }}</label>
              <b-form-input id="code" :value="schoolData.registryCode" disabled>
              </b-form-input>
            </b-form-group>
            <b-form-group label-for="type">
              <label class="form-label">{{ t("SCHOOL_NAME") }}</label>
              <b-form-input id="type" :value="schoolData.businessName" disabled>
              </b-form-input>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
      <b-col xl="9">
        <b-tabs active-nav-item-class="bold" card>
          <b-tab
              :title="t('TRAINING_GROUPS')"
              active
              title-link-class="text16-black"
          >
            <b-row>
              <b-col>
                <loader :active="loaderActive" :message="t('PLEASE_WAIT')"/>
                <b-table
                    :borderless="true"
                    :current-page="currentGroupsPage"
                    :empty-text="t('NO_RESULTS')"
                    :fields="groupFields"
                    :items="groups"
                    :per-page="perPage"
                    show-empty
                    striped
                >
                  <template v-slot:cell(action)="{ item }">
                    <div class="align-items-center">
                      <b-button
                          :to="{
                          name: 'ManageGroup',
                          params: { id: `${item.id}` },
                        }">
                        {{ t("VIEW") }}
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                    v-if="groups && groups.length > perPage"
                    v-model="currentGroupsPage"
                    :per-page="perPage"
                    :total-rows="groups ? groups.length : 0"
                    align="center">
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="t('SCHOOL_MANAGER')" title-link-class="text16-black">
            <b-row>
              <b-col class="text-right">
                <b-button
                    v-model="isSearch"
                    :disabled="isArchived()"
                    variant="blue"
                    @click="toggleSearchForm"
                >{{ t("ADD") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form v-if="isSearch" class="searchForm">
                  <b-form-group label-for="search-managers">
                    <label class="form-label">{{ t("SEARCH_TIP2") }}</label>
                    <b-form-input
                        id="search-managers"
                        v-model="searchValue"
                        minlength="3"
                        type="text"
                        v-on:input="searchManager"
                    ></b-form-input>
                  </b-form-group>
                  <b-form v-if="searchValue && searchValue.length >= 3">
                    <div v-for="manager in searchResult" :key="manager.id">
                      <b-row>
                        <b-link
                            v-on:mouseenter="hover = true"
                            v-on:mouseleave="hover = false"
                            @click="submitManager(manager.id)"
                        >
                          {{ manager.firstName + " " + manager.lastName }}
                        </b-link>
                      </b-row>
                    </div>
                    <b-form-group class="space"></b-form-group>
                    <p v-show="hover" class="italic">
                      {{ t("REGISTER") }}
                    </p>
                  </b-form>
                </b-form>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                    :borderless="true"
                    :current-page="currentManagerPage"
                    :empty-text="t('NO_RESULTS')"
                    :fields="managerFields"
                    :items="managers"
                    :per-page="perPage"
                    show-empty
                    striped
                >
                  <template v-slot:cell(action)="{ item }">
                    <div class="text-center">
                      <img
                          :disabled="isArchived()"
                          :src="iconRemove"
                          alt="icon Remove with X"
                          class="clickable"
                          @click="removeManager(item.id)"
                      />
                    </div>
                  </template>
                </b-table>
                <b-pagination
                    v-if="managers && managers.length > perPage"
                    v-model="currentManagerPage"
                    :per-page="perPage"
                    :total-rows="managers ? managers.length : 0"
                    align="center"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab :title="t('SCHOOL_MODULES')" title-link-class="text16-black">
            <b-row>
              <b-col class="text-right">
                <b-button variant="blue" @click="showSchoolModuleModal()"
                >{{ t("ADD") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                    :borderless="true"
                    :current-page="currentModulePage"
                    :empty-text="t('NO_RESULTS')"
                    :fields="moduleFields"
                    :items="modules"
                    :per-page="perPage"
                    show-empty
                    striped
                >
                  <template v-slot:cell(action)="{ item }">
                    <div class="text-center">
                      <img
                          :disabled="isArchived()"
                          :src="iconRemove"
                          alt="icon Remove with X"
                          class="clickable"
                          @click="removeModule(item.id)"
                      />
                    </div>
                  </template>
                </b-table>
                <b-pagination
                    v-if="modules && modules.length > perPage"
                    v-model="currentModulePage"
                    :per-page="perPage"
                    :total-rows="modules ? modules.length : 0"
                    align="center"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab :title="t('SCHOOL_TRAINERS')" title-link-class="text16-black"
                 active>
            <b-row>
              <b-col class="text-right">
                <b-button
                    v-model="addTrainer"
                    :disabled="isArchived()"
                    variant="blue"
                    @click="toggleSearchForm"
                >{{ t("ADD") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form v-if="isSearch" class="searchForm">
                  <b-form-group label-for="search-trainers">
                    <label class="form-label">{{ t("SEARCH_TIP2") }}</label>
                    <b-form-input
                        id="search-trainers"
                        v-model="searchValue"
                        minlength="3"
                        type="text"
                        v-on:input="searchTrainer"
                    ></b-form-input>
                  </b-form-group>
                  <b-form v-if="searchValue && searchValue.length >= 3">
                    <div v-for="trainer in searchResult" :key="trainer.id">
                      <b-row>
                        <b-link
                            v-on:mouseenter="hover = true"
                            v-on:mouseleave="hover = false"
                            @click="submitTrainer(trainer.id)"
                        >
                          {{ trainer.firstName + " " + trainer.lastName }}
                        </b-link>
                      </b-row>
                    </div>
                    <b-form-group class="space"></b-form-group>
                    <p v-show="hover" class="italic">
                      {{ t("REGISTER") }}
                    </p>
                  </b-form>
                </b-form>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                    :borderless="true"
                    :current-page="currentTrainerPage"
                    :empty-text="t('NO_RESULTS')"
                    :fields="trainerFields"
                    :items="trainers"
                    :per-page="perPage"
                    show-empty
                    striped
                >
                  <template v-slot:cell(action)="{ item }">
                    <div class="text-center">
                      <img
                          :disabled="isArchived()"
                          :src="iconRemove"
                          alt="icon Remove with X"
                          class="clickable"
                          @click="removeTrainer(item.id)"
                      />
                    </div>
                  </template>
                </b-table>
                <b-pagination
                    v-if="trainers && trainers.length > perPage"
                    v-model="currentTrainerPage"
                    :per-page="perPage"
                    :total-rows="trainers ? trainers.length : 0"
                    align="center"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <school-module-modal
        :fields="moduleFields"
        :trainings="trainings"
        @update-complete="handleUpdateComplete"
        ref="schoolModuleModalRef"
    ></school-module-modal>
  </div>
</template>

<script setup>
import schoolModuleModal from "@/views/components/modals/schoolModuleModal.vue";
import {computed, inject, onMounted, ref} from "vue";
import removeIcon from "@/assets/icon/icon_x.svg";
import {useDateFormat} from "@/mixins/dateFormatMixin2.ts";
import {toast} from "vue3-toastify";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";

const axios = inject("axios");
const route = useRoute();
const {t, locale} = useI18n();
const auth = inject("auth");
const schoolData = ref(null);
const groups = ref([]);
const managers = ref([]);
const trainers = ref([]);
const modules = ref([]);
const trainings = ref([]);
const isSearch = ref(false);
const addTrainer = ref(false);
const iconRemove = removeIcon;
const searchValue = ref("");
const currentManagerPage = ref(1);
const currentGroupsPage = ref(1);
const currentModulePage = ref(1);
const currentTrainerPage = ref(1);
const searchResult = ref([]);
const hover = ref(false);
const perPage = 15;
const {dateFormat} = useDateFormat();
const loaderActive = ref(false);

onMounted(() => {
  if (!props.school) {
  } else {
    schoolData.value = props.school;
  }
  getSchool();
  getSchoolGroups();
  getSchoolManagers();
  getSchoolTrainers();
  getSchoolModules();
  getMissingModules();
});

const props = defineProps(["school"]);

const groupFields = computed(() => {
  return [
    {key: "title", label: t("GROUP_TITLE"), sortable: true},
    {key: "startEvent", label: t("START"), sortable: true, formatter: getGroupStart},
    {key: "endEvent", label: t("END"), sortable: true, formatter: getGroupStart},
    {key: "translations", label: t("name"), sortable: true, formatter: getModuleName },
    {key: "language", label: t("LANGUAGE"), sortable: true},
    {key: "location", label: t("LOCATION"), sortable: true},
    {
      key: "groupSize",
      label: t("OCCUPANCY"),
      sortable: true,
      formatter: getGroupSize,
    },
    {
      key: "status",
      label: t("STATUS"),
      sortable: true,
      formatter: (value) => t(value),
    },
    {key: "action", label: "", sortable: false},
  ];
});


const handleUpdateComplete = (success) => {
  if (success) {
    getSchoolModules();
  } else {
    console.error('Update failed');
  }
};




const moduleFields = computed(() => {
  return [
    {key: "code", label: t("CODE_NAME"), sortable: true},
    {
      key: "translations",
      label: t("NAME"),
      sortable: true,
      formatter: getModuleName,
    },
    {
      key: "type",
      label: t("MODULE_TYPE"),
      sortable: true,
      formatter: getTypeName,
    },
    {key: "action", label: "", sortable: false},
  ];
});

const isArchived = () => {
  return schoolData.value.archived;
};

const getTypeName = (value /* key, item */) => {
  return t(value);
};
const getSchool = async () => {
  try {
    const resp = await axios.get(`/school/schools/manager/${route.params.id}`);
    if (resp.data && resp) {
      schoolData.value = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
};

async function getMissingModules() {
  try {
    const resp = await axios.get(
        `/school/${route.params.id}/missing`,
    );
    trainings.value = resp.data;
  } catch (error) {
    console.error(error);
  }
}

const showSchoolModuleModal = () => {
  getMissingModules()
  EventBus.$emit("show-school-module-modal");
}

const getSchoolManagers = async () => {

  try {
    const resp = await axios.get(`/school/${route.params.id}/managers`);
    if (resp.data && resp) {
      managers.value = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const getSchoolTrainers = async () => {

  try {
    const resp = await axios.get(`/school/${route.params.id}/trainers`);
    if (resp.data && resp) {
      trainers.value = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
};
const getSchoolGroups = async () => {
  try {
    const resp = await axios.get(`/school/${route.params.id}/group-list`);
    if (resp.data && resp) {
      groups.value = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
};

async function getSchoolModules() {
  try {
    const resp = await axios.get(`/school/${route.params.id}/training`);
    if (resp.data && resp) {
      modules.value = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
}

const toggleSchoolArchive = async () => {
  try {
    const response = await axios.post(`/school/${route.params.id}/archive`);
    if (response.data && response) {
      schoolData.value = response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const getGroupStart = (startDay) => {
  return dateFormat(startDay);
};

const getGroupSize = (value, key, item) => {
  return (
      (item.numberOfTrainees ? item.numberOfTrainees : 0) + "/" + item.groupSize
  );
};

const getFullName =  ( value, key, item) => {

  return item.fullName;
};

const getModuleName = (value /* key, item */) => {
  return value[locale.value];
};

function toggleSearchForm() {
  isSearch.value = !isSearch.value;
}

const searchManager = async () => {
  try {
    if (searchValue.value.length >= 3) {
      const response = await axios.post(
          `/account/search/allFreeManagers?roles=SCHOOL_MANAGER&searchValue=${searchValue.value}&fields=SSN,NAME`,
      );
      const data = response.data;
      searchResult.value = data.filter((t) => !t.isArchived);
      return searchResult.value;
    }
  } catch (error) {
    console.error(error);
  }
};
const searchTrainer = async () => {
  try {
    if (searchValue.value.length >= 3) {
      const response = await axios.post(
          `/account/search/trainers?roles=TRAINER&searchValue=${searchValue.value}&fields=SSN,NAME`,
      );
      const data = response.data;
      searchResult.value = data.filter((t) => !t.isArchived);
      return searchResult.value;
    }
  } catch (error) {
    console.error(error);
  }
};
async function submitManager(id) {
  try {
    const response = await axios.post(
        `/school/${route.params.id}/manager/${id}`,
    );
    if (response.data && response) {
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));
      managers.value.push(response.data);
      searchValue.value = "";
      isSearch.value = false;
      await getSchoolManagers();
    }
  } catch (error) {
    console.error(error);
  }
}
async function submitTrainer(id) {
  try {
    const response = await axios.post(
        `/school/${route.params.id}/trainer/${id}`,
    );
    if (response.data && response) {
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));
      trainers.value.push(response.data);
      searchValue.value = "";
      isSearch.value = false;
      await getSchoolTrainers();
    }
  } catch (error) {
    console.error(error);
  }
}

async function removeManager(id) {
  try {
    const response = await axios.delete(
        `/school/${route.params.id}/manager/${id}`,
    );
    if (response.data && response) {
      makeToast(t("REMOVED"), "success", t("OPERATION_SUCCESSFUL"));

      await getSchoolManagers();
    }
  } catch (e) {
    console.log(e);
  }
}
async function removeTrainer(id) {
  try {
    const response = await axios.delete(
        `/school/${route.params.id}/trainer/${id}`,
    );
    if (response.data && response) {
      makeToast(t("REMOVED"), "success", t("OPERATION_SUCCESSFUL"));

      await getSchoolTrainers();
    }
  } catch (e) {
    console.log(e);
  }
}

async function removeModule(id) {
  try {
    const response = await axios.delete(
        `/school/${route.params.id}/module/${id}`,
    );
    if (response.data && response) {
      makeToast(t("REMOVED"), "success", t("OPERATION_SUCCESSFUL"));
      await getSchoolModules();
    }
  } catch (e) {
    console.log(e);
  }
}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};

const managerFields = computed(() => {
  return [
    {key: "fullName", label: t("NAME"), sortable: true, formatter: getFullName},
    {key: "action", label: "", sortable: false},
  ];
});
const trainerFields = computed(() => {
  return [
    {key: "fullName",
      label: t("NAME"),
      sortable: true,
      formatter: getFullName},
    {key: "action", label: "", sortable: false},
  ];
});
</script>

<style lang="less" scoped>
.table-header-btn {
  margin-right: 2%;
  height: 30px;
}

.form-control:disabled {
  background-color: #bae6e8;
  border: 0;
}

.red {
  color: red;
}

.add-me {
  background-color: white;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
</style>
