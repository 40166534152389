<template>
  <div>
    <div class="text24-blue mt-3">{{ t("MONTHLY_NEWSLETTER") }}</div>
    <div class="text14-gray mt-3 join-msg">{{ t("CLICK_HERE_TO_JOIN") }}</div>
    <div>
      <b-button
          variantf="link"
          class="link-button"
          href="https://ysle8gab.sendsmaily.net/landing-pages/d363dfc9-3a58-477a-af93-333d84aea843/html/"
          target="_blank"
          rel="noopener noreferrer"
      >{{ t("JOIN_HERE") }}
      </b-button>
      <b-button
          variant="blue"
          @click="addMonthlyNewsState"
          :hidden="!isContentManager()"
      >
        <b-icon-plus></b-icon-plus>{{ t("ADD_NEW") }}
      </b-button>
    </div>
    <br />
    <add-monthly-news v-if="newMonthlyNews"></add-monthly-news>
    <div v-for="(oneDate, index) in showMoreNews" :key="oneDate.id">
      <hr v-if="index !== 0" class="mt-2" />
      <div class="text16-black mt-2">
        <strong>{{ getMonthYear(oneDate.newsDate) }}</strong>
      </div>
      <div>
        <b-link
            v-for="link in oneDate.urls"
            :key="link.id"
            class="text16-blue mt-2 spaceBetween"
            :href="link.url"
            target="_blank"
            rel="noopener noreferrer"
        >
          <strong>{{ link.lang }}</strong>
        </b-link>
        <b-icon-pencil
            class="mx-2"
            v-if="isContentManager()"
            @click="showUpdateNewsModal(oneDate)"
        ></b-icon-pencil>
        <b-icon-x-circle
            variant="danger"
            v-if="isContentManager()"
            @click="showRemoveNewsModal(oneDate.id)"
        ></b-icon-x-circle>
        <update-monthly-news-modal :is-VisibleUpdateModal="isVisibleUpdateModal"
                                   @update-value-update="isVisibleUpdateModal = $event"></update-monthly-news-modal>
        <remove-monthly-newsletter-modal :is-Visible="isVisible"
                                         @update-value="isVisible = $event"></remove-monthly-newsletter-modal>
      </div>
    </div>

    <b-link class="mt-3 text16-blue" @click="showMore = !showMore">
      <span v-if="showMore">{{ t("SHOW_LESS") }}</span>
      <span v-else>{{ t("MORE") }}...</span>
    </b-link>
  </div>
</template>

<script setup>
import {computed, inject, onBeforeMount, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import UpdateMonthlyNewsModal from "@/views/components/modals/updateMonthlyNewsModal.vue";
import RemoveMonthlyNewsletterModal from "@/views/components/modals/removeMonthlyNewsletterModal.vue";
import addMonthlyNews from "@/views/components/AddMonthlyNews.vue";

const axios = inject("axios");
const { t } = useI18n();
const auth = inject("auth");
const newMonthlyNews = ref(false);
const newsLinks = ref([]);
const showMore = ref(false);
const isVisible = ref(false);
const isVisibleUpdateModal = ref(false);
onBeforeMount(() => {
  EventBus.$on("FETCH_MONTHLY_NEWS", fetchData);
});
onBeforeUnmount(() => {
  EventBus.$off("FETCH_MONTHLY_NEWS", fetchData);
});

onMounted(() => {
  fetchData();
});

const showMoreNews = computed(() => {
  return getMoreNewsletters();
});

const isContentManager = () => {
  return auth.hasRole("CONTENT_MANAGER");
};

async function fetchData() {
  try {
    const resp = await axios.get("/news");
    if (resp && resp.data) {
      newsLinks.value = resp.data;
    }
  } catch (e) {
    console.log(e);
  }
}

const addMonthlyNewsState = () => {
  newMonthlyNews.value = true;
};

const showRemoveNewsModal = (newsletterId) => {
  isVisible.value = !isVisible.value
  EventBus.$emit("show-remove-monthly-newsletter-modal", newsletterId);
};

const showUpdateNewsModal = (newsletter) => {
  isVisibleUpdateModal.value = !isVisibleUpdateModal.value
  EventBus.$emit("show-update-monthly-news-modal", newsletter);
};

const getMonthYear = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const realMonth = date.getMonth() + 1;
  let month;

  switch (realMonth) {
    case 1:
      month = t("MONTHS.JANUARY");
      break;
    case 2:
      month = t("MONTHS.FEBRUARY");
      break;
    case 3:
      month = t("MONTHS.MARCH");
      break;
    case 4:
      month = t("MONTHS.APRIL");
      break;
    case 5:
      month = t("MONTHS.MAY");
      break;
    case 6:
      month = t("MONTHS.JUNE");
      break;
    case 7:
      month = t("MONTHS.JULY");
      break;
    case 8:
      month = t("MONTHS.AUGUST");
      break;
    case 9:
      month = t("MONTHS.SEPTEMBER");
      break;
    case 10:
      month = t("MONTHS.OCTOBER");
      break;
    case 11:
      month = t("MONTHS.NOVEMBER");
      break;
    case 12:
      month = t("MONTHS.DECEMBER");
      break;
  }
  return month ? month.toUpperCase() + "  " + year : null;
};
const getMoreNewsletters = () => {
  if (showMore.value) {
    return newsLinks.value;
  }
  return newsLinks.value.slice(0, 2);
};

</script>


<style lang="less">
.join-msg {
  margin-bottom: 15px;
}

.link-button {
  margin-right: 10px;
}

.spaceBetween {
  padding-right: 10px;
}
</style>
