import {Axios} from "axios";
import {toast} from "vue3-toastify";
import {type ComposerTranslation, type I18n} from "vue-i18n";

export const interceptHttpResponses = (i18n: I18n<any>, axios: Axios) => {
    // @ts-ignore
    const {t} = i18n.global;

    axios.interceptors.response.use(
        (data) => {
            return data;
        },
        (err) => {
            makeErrorToast(getErrorMessage(t, err));
            return Promise.reject(err);
        },
    );
};

const getErrorMessage = (t: ComposerTranslation, err: any) => {
    let translationKey: string | null = null;

    if (err?.response) {
        if (err.response.data && typeof err.response.data === "string") {
            translationKey = `ERRORS.${err.response.data}`;
        } else if (err.response.data?.message && typeof err.response.data.message === "string") {
            translationKey = `ERRORS.${err.response.data.message}`;
        } else if (err.response.status === 400) {
            translationKey = "ERRORS.BAD_REQUEST";
        } else if (err.response.status === 401) {
            translationKey = "ERRORS.UNAUTHORIZED";
        } else if (err.response.status === 403) {
            translationKey = "ERRORS.FORBIDDEN";
        }
    }

    if (translationKey) {
        const translation = t(translationKey);
        if (translationKey !== translation) {
            return translation;
        }
    }

    return t("ERRORS.ERROR_GENERAL");
};

const makeErrorToast = (title: string) => {
    toast(title, {
        type: "error",
        autoClose: 5000,
    });
};
