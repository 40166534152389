<template>
  <b-container>
    <b-card style="text-align: left" class="col mb-8">
      <b-form v-if="viewData" id="profileForm" name="profileForm" ref="form">
        <b-row>
          <b-col cols="12">
            <b-form-group
                id="name-grp"
                label-for="firstName"
                label-class="font-weight-bold"
                :label='t("NAME")'
            >
              <b-form-input
                  id="firstName"
                  name="firstName"
                  type="text"
                  :state="firstNameState"
                  :invalid-feedback="firstNameFeedback"
                  required
                  autocomplete="off"
                  style="text-transform: uppercase"
                  v-model.trim="viewData.firstName"
                  :title='t("NAME_AGAINST_POLICY")'
              />
              <p></p>
              <b-form-input
                  id="lastName"
                  name="lastName"
                  type="text"
                  :state="lastNameState"
                  :invalid-feedback="lastNameFeedback"
                  required
                  autocomplete="off"
                  style="text-transform: uppercase"
                  v-model.trim="viewData.lastName"
                  :title='t("NAME_AGAINST_POLICY")'
              />
            </b-form-group>
            <b-form-group
                label-for="SSN"
                label-class="font-weight-bold"
                :label='t("SSN")'
            >
              <span v-if="viewData">{{ viewData.ssn }}</span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
            id="email-grp"
            label-for="email"
            label-class="font-weight-bold"
            :label='t("EMAIL")'
            :description="t('EMAIL_NOTE')"
        >
          <b-form-input
              id="email"
              type="email"
              :state="emailState"
              :invalid-feedback="emailFeedback"
              required
              autocomplete="off"
              name="email"
              v-model.trim="viewData.email"
              :title='t("FAULTY_EMAIL")'
          />
        </b-form-group>

        <b-form-group
            id="phone-grp"
            label-for="phone"
            label-class="font-weight-bold"
            :label='t("PHONE")'
        >
          <b-row>
            <div class="col-6">
              <b-form-select
                  :options="areaCodes"
                  v-model="viewData.dialCode"
                  :state="dialCodeState"
                  :invalid-feedback="dialCodeFeedback"
                  :title='t("SELECT_DIAL")'
                  required
              />
            </div>
            <div class="col-6">
              <b-form-input
                  id="phone"
                  name="phone"
                  autocomplete="off"
                  placeholder="1234567"
                  :state="phoneState"
                  :invalid-feedback="phoneFeedback"
                  required
                  type="text"
                  :title='t("FAULTY_PHONE", {maxLength: getMaxPhoneLength(viewData.dialCode)})'
                  v-model.trim="viewData.phone"
                  oninput="this.value = this.value.replaceAll(/\D/g, '')"
                  minlength="6"
                  :maxlength="getMaxPhoneLength(viewData.dialCode)"
              />
            </div>
          </b-row>
        </b-form-group>
        <b-form-group
            id="address-grp"
            label-for="address"
            label-class="font-weight-bold"
            :label='t("ADDRESS")'
        >
          <b-form-textarea
              class="textarea"
              id="address"
              name="address"
              autocomplete="off"
              max-rows="4"
              type="text"
              v-model.trim="viewData.address"
              :title='t("TEXT_RESTRICTION")'
              :state="addressState"

          />
        </b-form-group>
        <b-form-group
            id="education-grp"
            label-for="education"
            label-class="font-weight-bold"
            :label='t("EDUCATION")'
        >
          <b-form-select
              :options="educationOptions"
              id="education"
              name="education"
              v-model="viewData.education"
              :state="educationState"
              :invalid-feedback="educationFeedback"
              :title='t("SELECT_OPTION")'
              required
          />
        </b-form-group>
        <b-form-group
            id="details-grp"
            label-for="details"
            label-class="font-weight-bold"
            :description="t('DETAILS_NOTE')"
            :label='t("DETAILS")'
        >
          <b-form-textarea
              class="textarea"
              id="details"
              name="details"
              rows="2"
              max-rows="4"
              :placeholder="t('WRITE_STH')"
              v-model.trim="viewData.details"
              :title='t("TEXT_RESTRICTION")'

          />
        </b-form-group>
        <b-form-group
            id="origin-grp"
            label-for="origin"
            label-class="font-weight-bold"
            :label='t("LETUSKNOW")'
        >
          <b-form-select
              :options="originOptions"
              id="origin"
              name="origin"
              v-model="viewData.origin"
              :state="originState"
              :invalid-feedback="originFeedback"
              :title='t("SELECT_OPTION")'
              required
          />
        </b-form-group>
        <b-button
            style="width: 100%"
            v-show="route.path !== '/groups'"
            variant="blue"
            id="profileSubmit"
            @click="onSubmit"
        >
          {{ t("SAVE") }}
        </b-button>
      </b-form>
    </b-card>
  </b-container>
</template>


<style scoped>
.errorText {
  color: red;
  font-style: italic;
}
</style>
<script setup>
import codes from "@/area_codes";
import EventBus from "@/util/eventBus";
import router from "@/router";
import {toast} from "vue3-toastify";
import {computed, inject, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import {getMaxPhoneLength, validatePhone} from "@/util/phoneUtil";

const axios = inject("axios");
const route = useRoute();
const {t} = useI18n();
const auth = inject("auth");
const viewData = ref({
  firstName: "",
  lastName: "",
  ssn: "",
  email: "",
  phone: "",
  dialCode: "",
  address: "",
  education: "",
  details: "",
  origin: "",
  isArchived: false,
});
const emit = defineEmits(['updateField', 'update:validity', ['registrationSuccess']]);
const areaCodes = ref(
    codes.map((code) => ({
      value: code.dial_code,
      text: `${code.name} (${code.dial_code})`,
    }))
);

const educationOptions = computed(() => [
  {value: "UNSPECIFIED", text: t("NOT_SPECIFIED")},
  {value: "PRIMARY", text: t("EDU_PRIMARY")},
  {value: "BASIC", text: t("EDU_BASIC")},
  {value: "SECONDARY", text: t("EDU_SECONDARY")},
  {value: "VOCATIONAL", text: t("EDU_VOCATIONAL")},
  {value: "HIGHER", text: t("EDU_HIGHER")},
]);

const originOptions = computed(() => [
  {value: "PPA", text: t("PPA")},
  {value: "SOCIAL_MEDIA", text: t("SOCIAL_MEDIA")},
  {value: "FRIENDS", text: t("FRIENDS")},
  {value: "WORK", text: t("WORK")},
  {value: "SCHOOL", text: t("SCHOOL")},
  {value: "LOCAL_MUNICIPALITY", text: t("LOCAL_MUNICIPALITY")},
  {value: "OTHER", text: t("OTHER")},
]);

const namePattern = "^[A-Za-zÄäÖöÜüÕõŽžŠš\\s' -]+$";
const emailReg = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$/;
const nameReg = new RegExp(namePattern);
const addressPattern = /^[A-Za-z0-9ÄäÖöÜüÕõŽžŠš\s,.'-]{5,100}$/;

function validateName(name) {
  return nameReg.test(name) && name.length >= 2 && name.length <= 20;
}

function validateEmail(email) {
  return emailReg.test(email);
}

const firstNameState = computed(() => {
  if (viewData.value.firstName === "") {
    return false;
  }
  return validateName(viewData.value.firstName);
});

const firstNameFeedback = computed(() => {
  if (firstNameState.value === false) {
    return t("NAME_AGAINST_POLICY");
  }
  return "";
});

const lastNameState = computed(() => {
  if (viewData.value.lastName === "") {
    return false;
  }
  return validateName(viewData.value.lastName);
});

const lastNameFeedback = computed(() => {
  if (lastNameState.value === false) {
    return t("NAME_AGAINST_POLICY");
  }
  return "";
});

const emailState = computed(() => {
  if (viewData.value.email === "") {
    return false;
  }
  return validateEmail(viewData.value.email);
});

const emailFeedback = computed(() => {
  if (emailState.value === false) {
    return t("FAULTY_EMAIL");
  }
  return "";
});

const phoneState = computed(() => {
  return validatePhone(viewData.value?.dialCode, viewData.value?.phone);
});

const phoneFeedback = computed(() => {
  if (phoneState.value === false) {
    return t('ONLY_DIGITAL') + " " + t('MIN_MAX_PHONE');
  }
  return "";
});

const dialCodeState = computed(() => {
  return !!viewData.value?.dialCode;
});

const dialCodeFeedback = computed(() => {
  if (dialCodeState.value === false) {
    return t("FIELD_REQUIRED");
  }
  return "";
});

const educationState = computed(() => {
  if (
      viewData.value.education === "" ||
      viewData.value.education === "UNSPECIFIED"
  ) {
    return false;
  }
  return true;
});

const educationFeedback = computed(() => {
  if (educationState.value === false) {
    return t("FIELD_REQUIRED");
  }
  return "";
});

const addressState = computed(() => {
  const address = viewData.value.address;
  return !address || address.trim() === '' || addressPattern.test(address)
});

const originState = computed(() => {
  if (viewData.value.origin === "" || viewData.value.origin === null) {
    return false;
  }
  return true;
});

const originFeedback = computed(() => {
  if (originState.value === false) {
    return t("FIELD_REQUIRED");
  }
  return "";
});

const formIsValid = computed(() => {
  return (
      firstNameState.value === true &&
      lastNameState.value === true &&
      emailState.value === true &&
      phoneState.value === true &&
      dialCodeState.value === true &&
      addressState.value === true &&
      educationState.value === true &&
      originState.value === true
  );
});

function onSubmit() {
  if (formIsValid.value) {
    updateAccount();
  } else {
    makeToast(t("ERRORS.FORM_INVALID"), "danger", t("ATTENTION"));
  }
}

async function accountData() {
  try {
    let response;
    if (route.params.id) {
      response = await axios.get(`/account/${route.params.id}`);
    } else {
      response = await axios.get("/account");
    }
    if (response.data && response) {
      viewData.value = response.data;
    }
    if (viewData.value.isArchived && !auth.hasRole("ADMIN")) {
      await router.push({name: "Groups"});
    }
    route.params.isArchived = viewData.value.isArchived;
    return viewData.value;
  } catch (e) {
    console.log(e);
  }
}

async function updateAccount() {
  try {
    const response = await axios.put(
        `/account/${viewData.value.id}/`,
        viewData.value
    );
    if (response.status === 200) {
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));
    }
  } catch (error) {
    console.error("Error updating account:", error);
  }
}

onMounted(async () => {
  await accountData();
});

onBeforeUnmount(() => {
  EventBus.$off("PROFILE_UPDATE", updateAccount);
});

const makeToast = (message = null, variant = null, title = null) => {
  toast(`${message}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};
watch(formIsValid, (newVal) => {
  emit('update:validity', newVal);
});
defineExpose({
  updateAccount,
});
</script>
