<template>
  <b-modal
      size="xl"
      id="group-creation-modal"
      ref="groupCreationModalRef"
      @ok="handleOk"
      header-class="text18-black bold"
      role="dialog"
      :title="t('CREATE_GROUP')"
  >
    <template v-slot:modal-header-close>
      <b-img :src=removeIcon></b-img>
    </template>
    <b-row>
      <div class="col">
        <add-group-form
            ref="groupFormRef"
            :module="module"
            :trainers="trainers"
            @submit-group="createGroup"
            :is-admin="isAdmin"
        ></add-group-form>
      </div>
    </b-row>
    <template v-slot:modal-footer>
      <b-button variant="blue" @click="handleOk">
        {{ t("CONFIRM") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import removeIcon from "@/assets/icon/icon_x.svg";
import EventBus from "@/util/eventBus";
import addGroupForm from "@/views/components/forms/AddGroupForm.vue";
import {inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {toast} from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const emit = defineEmits(['added-group'])
const props = defineProps({module: Object, trainers: Array, isAdmin: Boolean});
const router = useRouter();
const route = useRoute();
const axios = inject("axios", () => console.log("axios is not provided"));
const {t, locale} = useI18n()
const groupFormRef = ref(null)
const groupCreationModalRef = ref(null)

onMounted(() => {
  EventBus.$on("show-group-creation-modal", showModal);
})

onBeforeUnmount(() => {
  EventBus.$off("show-group-creation-modal", showModal);
})

function handleOk() {
  groupFormRef.value.submitForm()
}

const createGroup = async (groupFormRef) => {
  try {
    groupFormRef.training = props.module;
    const resp = await axios.post("/training/group/", groupFormRef);
    if (resp.data) {
      makeToast(t("ADDED_GROUP"), "success", t("OPERATION_SUCCESSFUL"));
      emit("added-group", resp.data);
      groupCreationModalRef.value.hide();
    }
  } catch (error) {
    groupCreationModalRef.value.hide();
    console.error(error);
  }
};

function cancel() {
  groupCreationModalRef.value.hide();
}
const showModal = () => {
  groupCreationModalRef.value.show();
}
const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    title: title,
    variant: variant,
    autoHideDelay: 5000,
  });
};
</script>
