import "./scss/global.scss";

import App from "./App.vue";
import router from "./router";
import {
  BootstrapVue,
  IconsPlugin,
  TablePlugin,
  ToastPlugin,
} from "bootstrap-vue";
import { getInstance, OAuthPlugin } from "./auth/oauth";
import { createI18n } from "vue-i18n";
import * as translations from "./locales/translations.json";
import * as translationsEn from "./locales/translations_en.json";
import * as translationsRu from "./locales/translations_ru.json";
import { createApp } from "vue";
import axios from "axios";
import vSelect from "vue-select";
import {interceptHttpResponses} from "@/interceptor/http.interceptor";

const app = createApp(App);

// Vue.config.productionTip = false;
app.use(router);
app.use(BootstrapVue, {
  BButton: { variant: "white" },
});
app.use(IconsPlugin);
app.use(TablePlugin);
app.use(ToastPlugin);

const instance = axios.create({
  baseURL: import.meta.env.VITE_INTERNAL_API,
});
app.provide("axios", instance);

app.use({
  install() {
    instance.interceptors.request.use((config: any) => {
      const authService = getInstance();
      if (authService && authService.isAuthenticated()) {
        config.headers["Authorization"] = `Bearer ${authService.getToken()}`;
        config.headers["Content-type"] = "application/json; charset=utf-8";
        config.headers["Accept"] = "application/json";
      }
      return config;
    });
    app.config.globalProperties.$axios = instance;
  },
});

app.use({
  install() {
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id) || fjs.parentNode === null) {
        return;
      }
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/et_EE/sdk.js#xfbml=1&version=v8.0";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
});

// app.use(PollingPlugin, {axiosInstance: app.prototype.axios});
//
app.use(OAuthPlugin, {
  onLogin: async (roles: Array<string>) => {
    try {
      await router.push({ name: "Groups" });
    } catch (e) {
      if (e.name == "NavigationDuplicated") {
        router.go(0);
        return;
      }
      console.error(e);
    }
  },
  onLogout: async () => {
    try {
      await router.push({ path: "/" });
    } catch (e) {
      if (e.name == "NavigationDuplicated") {
        router.go(0);
        return;
      }
      console.error(e);
    }
  },
});

export const i18n = createI18n({
  locale: "RUS",
  fallbackLocale: "RUS",
  legacy: false,
  messages: {
    RUS: translationsRu.ru,
    EST: translations.ee,
    ENG: translationsEn.en,
  },
});

app.use(i18n);
app.component("v-select", vSelect);
app.mount('#app');

interceptHttpResponses(i18n, instance);
