<template>
  <b-card v-if="isComponentVisible" class="mainNews">
    <b-card-title class="text24-blue mt-3">{{
      t("NEW_MONTHLY_NEWSLETTER")
    }}</b-card-title>
    <b-card-body>
      <VueDatePicker
          id="newsDate"
          :monthLabels="monthLabels"
          v-model="newsDate"
          :place-holder="t('CHOOSE_DATE')"
      ></VueDatePicker>
      <b-form-input
          v-model="newMonthlyNewsUrlEng"
          size="sm"
          class="mt-3"
          v-bind:placeholder="t('NEWS_URL_ENG')"
          v-bind:class="missingValueBorderHighlight(newMonthlyNewsUrlEng)"
      >
      </b-form-input>
      <b-form-input
          v-model="newMonthlyNewsUrlEst"
          size="sm"
          class="mt-3"
          v-bind:placeholder="t('NEWS_URL_EST')"
          v-bind:class="missingValueBorderHighlight(newMonthlyNewsUrlEst)"
      >
      </b-form-input>
      <b-form-input
          v-model="newMonthlyNewsUrlRus"
          size="sm"
          class="mt-3"
          v-bind:placeholder="t('NEWS_URL_RUS')"
          v-bind:class="missingValueBorderHighlight(newMonthlyNewsUrlRus)"
      >
      </b-form-input>
    </b-card-body>

    <div class="new-file-buttons">
      <b-button @click="cancel">{{ t("CANCEL") }}</b-button>
      <b-button variant="blue" :disabled="saveDisabled" @click="save()">{{
          t("SAVE")
        }}
      </b-button>
    </div>
  </b-card>
</template>

<script setup>

import {highlightStyle} from "@/mixins/highlightStyleMixin2";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {computed, inject, nextTick, ref} from "vue";
import {toast} from "vue3-toastify";
import VueDatePicker from '@vuepic/vue-datepicker';



const {missingValueTextHighlight, missingValueBorderHighlight} = highlightStyle();
const axios = inject("axios");
const {t} = useI18n();
const isComponentVisible = ref(true);
const newsDate = ref(null);
const newMonthlyNewsUrlEst = ref(null);
const newMonthlyNewsUrlEng = ref(null);
const newMonthlyNewsUrlRus = ref(null);
const monthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const saveDisabled = computed(() => {
  return !(
    newMonthlyNewsUrlEst.value &&
    newMonthlyNewsUrlEng.value &&
    newMonthlyNewsUrlRus.value
  );
});
async function removeNews() {
  await nextTick()
  EventBus.$emit("remove-newsletter-modal");
}
function cancel() {
  isComponentVisible.value = !isComponentVisible.value;
  return isComponentVisible.value;
}

async function save() {
  const date = new Date(newsDate.value);
  date.setMonth(date.getMonth() + 1);
  try {
    const resp = await axios.post("/news", {
      newsDate: date,
      estUrl: newMonthlyNewsUrlEst.value,
      engUrl: newMonthlyNewsUrlEng.value,
      rusUrl: newMonthlyNewsUrlRus.value,
    });
    if (resp.data && resp) {
      EventBus.$emit("FETCH_MONTHLY_NEWS");
      cancel();
      makeToast(t("OPERATION_SUCCESSFUL"), "success", t("MONTHLY_NEWS_ADDED"));
    }
  } catch (e) {
    console.log(e);
  }
}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    title: title,
    variant: variant,
    autoHideDelay: 6000,
  });
};
</script>

<style lang="less">
.mainNews {
  border-radius: 10px;
}

.vue-date-picker {
  .picker {
    .placeholder {
      background-color: transparent;
      color: lightslategray;
    }
    .input {
      background-color: transparent;
      color: lightslategray;
    }
  }
}
</style>
