import { type AxiosStatic } from "axios";
import { useRouter } from "vue-router";

export function usePageContent() {
  async function getPageContent(axios, page: string, lang: string) {
    const resp = await axios.get(`/client?page=${page}&lang=${lang}`);
    if (resp && resp.data) {
      if (!resp.data.elements) {
        resp.data.elements = {};
      }
      return resp.data;
    }
  }

  function savePageContent(
    axios,
    page: string,
    lang: string,
    element: string,
    value: string,
  ) {
    const request = {
      page: page,
      lang: lang,
      element: element,
      value: value,
    };
    axios.post("/client", request);
  }

  function savePageContentBatch(
    axios,
    list: {
      page: string;
      lang: string;
      element: string;
      value: string;
    }[],
  ) {
    axios.post("/client/batch", list);
  }

  function deletePageContent(
    axios,
    page: string,
    lang: string,
    element: string,
  ) {
    const request = {
      page: page,
      lang: lang,
      element: element,
    };
    const resp = axios.delete("/client", { data: request });
    return resp.status.toString(10).startsWith("2");
  }

  function deletePageContentBatch(
    axios: AxiosStatic,
    list: {
      page: string;
      lang: string;
      element: string;
    }[],
  ) {
    const resp = axios.delete("/client/batch", { data: list });
    return resp.status.toString(10).startsWith("2");
  }
  return {
    getPageContent,
    savePageContent,
    savePageContentBatch,
    deletePageContent,
    deletePageContentBatch,
  };
}
