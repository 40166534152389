<template>
  <div id="footer-container">
    <div id="blue-section">
      <b-container>
        <b-row>
          <b-col class="pb-3" cols="12" md="4">
            <div class="text24-white mb-2">Settle in Estonia</div>
            <div>
              <a href="/programme" class="text14-white">{{
                t("SETTLE_PROGRAMME")
              }}</a>
            </div>
            <div>
              <a href="/moving-to-estonia" class="text14-white">{{
                t("MOVING_TO_ESTONIA")
              }}</a>
            </div>
            <div>
              <a href="/adaptation" class="text14-white">{{
                t("HELP_ADAPTING")
              }}</a>
            </div>
          </b-col>
          <b-col class="pb-3" cols="12" md="4">
            <div class="text24-white mb-2">{{ t("STAY_IN_TOUCH") }}</div>
            <div>
              <a href="mailto:info@settleinestonia.ee" class="text14-white"
                >info@settleinestonia.ee</a
              >
            </div>
            <div>
              <a
                href="https://www.facebook.com/settleinestonia"
                target="_blank"
                rel="noopener noreferrer"
                class="text14-white"
                >Facebook (Settle in Estonia)</a
              >
            </div>
            <div>
              <a href="/news" class="text14-white">{{ t("BLOG") }}</a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/playlist?list=PLnxuvGFPMWQpKweKICVJnV_-EFFo16DqH"
                target="_blank"
                rel="noopener noreferrer"
                class="text14-white"
                >Youtube</a
              >
            </div>
            <div class="mt-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                class="mr-4"
                ><img src="@/assets/icon/icon_facebook.svg"
                      alt="facebook icon"
              /></a>
              <a
                href="https://youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                ><img src="@/assets/icon/icon_youtube.svg"
                      alt="youtube icon"
              /></a>
            </div>
          </b-col>
          <b-col class="pb-3" cols="12" md="4">
            <div class="text24-white mb-2">{{ t("IMPORTANT_CONTACTS") }}</div>
            <b-row>
              <b-col cols="12">
                <div>
                  <a
                    href="https://www.politsei.ee/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >{{ t("POLICE_AND_BORDER") }}</a
                  >
                </div>
                <div>
                  <a
                    href="https://www.politsei.ee/en/migration-consultants"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >{{ t("MIGRATION_ADVISORS") }}</a
                  >
                </div>
                <div>
                  <a
                    href="https://www.eesti.ee/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >{{ t("STATE_PORTAL") }}</a
                  >
                </div>
                <div>
                  <a
                    href="https://www.workinestonia.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >workinestonia.com</a
                  >
                </div>
                <div>
                  <a
                    href="https://www.visitestonia.com/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >visitestonia.com</a
                  >
                </div>
                <div>
                  <a
                    href="https://studyinestonia.ee/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >studyinestonia.ee</a
                  >
                </div>
                <div>
                  <a
                    href="https://researchinestonia.eu/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >researchinestonia.eu</a
                  >
                </div>
                <div>
                  <a
                    href="https://investinestonia.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >investinestonia.com</a
                  >
                </div>
                <div>
                  <a
                    href="https://tradewithestonia.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text14-white"
                    >tradewithestonia.com</a
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="text-center">
      <img
          class="settle-image"
          src="@/assets/settle_estonia_horizontal_negative.svg"
          alt="settle estonia"
      />
      <img src="@/assets/kultuurimin_3lovi_eng_pms.svg"
      alt="logo Ministry of culture"/>
      <img id="logo-sticky" src="@/assets/el_sotsiaalfond_vertikaalne.jpg" alt="logo"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#blue-section {
  background-color: $color-blue;
  padding-bottom: 50px;
  padding-top: 50px;
  padding-left: 20px;
}

.settle-image {
  max-width: 305px;
}

#logo-sticky {
  position: relative;
  bottom: 0;
  end: 0;
}
</style>
<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();
</script>
