<template>
  <b-container class="section-p70">
    <b-row>
      <b-col>
        <h2 class="title-blue">{{ t("SCHOOLS") }}</h2>
      </b-col>
    </b-row>

    <b-row align-h="between">
      <b-col cols="auto">
        <b-form-checkbox switch v-model="showArchived">{{
          t("ARCHIVED")
        }}</b-form-checkbox>
      </b-col>
      <b-col cols="auto">
        <b-button variant="blue" @click="showSchoolCreationModal()">{{
          t("ADD")
        }}</b-button>
      </b-col>
    </b-row>

    <div class="separator-line mt-3"></div>

    <div class="row">
      <div class="col">
        <b-table
          :fields="fields"
          :items="items"
          striped
          :borderless="true"
          responsive
          show-empty
          :empty-text="t('NO_RESULTS')"
        >
          <template v-slot:cell(view)="{ item }">
            <div class="text-center">
              <b-button
                :to="{
                  name: 'ManageSchool',
                  params: { id: item.id, school: item },
                }"
              >
                {{ t("VIEW") }}
              </b-button>
            </div>
          </template>

          <template v-slot:cell(archived)="val">
            <b-form-checkbox
              v-model="val.item.archived"
              disabled
            ></b-form-checkbox>
          </template>
        </b-table>
      </div>
    </div>
    <school-creation-modal ref="schoolCreationModal" @added-school="addSchool"></school-creation-modal>
  </b-container>
</template>

<script setup>

import EventBus from "@/util/eventBus";
import {computed, inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import SchoolCreationModal from "@/views/components/modals/schoolCreationModal.vue";

const route = useRoute();
const {t, locale} = useI18n();
const auth = inject("auth");
const axios = inject("axios");
const schools = ref([]);
const showArchived = ref(false);


onMounted(async () => {
  await getData();
});

const showSchoolCreationModal = () => {
  EventBus.$emit("show-school-creation-modal");
}

const getData = async function () {
  try {
    const response = await axios.get("/school/schools");
    if (response.data && response) {
      schools.value = response.data;
    }
  }catch (error) {
    console.error(error);
  }
};

function addSchool() {
  getData();
}

const fields = computed(() => {
  const fields = [
    { key: "registryCode", label: t("SCHOOL_CODE"), sortable: true },
    { key: "businessName", label: t("SCHOOL_NAME"), sortable: true },
    { key: "view", label: "", sortable: false },
  ];
  if (showArchived.value) {
    const field = { key: "archived", label: t("ARCHIVED"), sortable: true };
    fields.splice(-1, 0, field);
  }
  return fields;
});

const items = computed(() => {
  return showArchived
    ? schools.value
    : schools.value.filter((m) => !m.isArchived);
});
</script>
