import {ref} from 'vue'

export const useLoader = ()=> {


    const loaderActive = ref(false);

    const showLoader = () => {
      loaderActive.value = true;
    }

    const hideLoader = () => {
      loaderActive.value = false;
    }
    return {
      loaderActive,
      showLoader,
      hideLoader
    }
}
