<template>
  <div
    id="header-container-pub"
    :class="{
      light: !['Home', 'Blog'].includes($route.name),
      dark: ['Home', 'Blog'].includes($route.name),
    }"
  >
    <b-container fluid>
      <b-navbar toggleable="lg">
        <img
          id="brand-logo"
          src="@/assets/settle_estonia.svg"
          alt="Brand logo"
        />

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!--          navbar has to depend on role change this later -->
          <b-navbar-nav>
            <template v-if="isAuthenticated()">
              <b-nav-item-dropdown :text="t('SETTLE_ESTONIA_INFO')">
                <b-dropdown-item to="/">{{ t("HOME") }}</b-dropdown-item>
                <b-dropdown-item to="/moving-to-estonia">{{
                  t("MOVING_TO_ESTONIA")
                }}</b-dropdown-item>
                <b-dropdown-item to="/programme">{{
                  t("PROGRAMME")
                }}</b-dropdown-item>
                <b-dropdown-item to="/adaptation">{{
                  t("ADAPTATION")
                }}</b-dropdown-item>
                <b-dropdown-item to="/news">{{ t("NEWS") }}</b-dropdown-item>
                <b-dropdown-item to="/contacts">{{
                  t("CONTACTS")
                }}</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item v-if="canAccessGroups" to="/groups">{{
                t("TRAINING_GROUPS")
              }}</b-nav-item>
              <b-nav-item v-if="canAccessSearch" to="/search">{{
                t("SEARCH_QUERY")
              }}</b-nav-item>
              <b-nav-item v-if="canAccessModules" to="/modules">{{
                t("MODULES")
              }}</b-nav-item>
              <b-nav-item v-if="canAccessSchools" to="/schools">{{
                t("SCHOOLS")
              }}</b-nav-item>
            </template>
            <template v-else>
              <b-nav-item to="/">{{ t("HOME") }}</b-nav-item>
              <b-nav-item to="/moving-to-estonia">{{
                t("MOVING_TO_ESTONIA")
              }}</b-nav-item>
              <b-nav-item to="/programme">{{ t("PROGRAMME") }}</b-nav-item>
              <b-nav-item to="/adaptation">{{ t("ADAPTATION") }}</b-nav-item>
              <b-nav-item to="/news">{{ t("NEWS") }}</b-nav-item>
              <b-nav-item to="/contacts">{{ t("CONTACTS") }}</b-nav-item>
            </template>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="!isAuthenticated()" to="/login">{{
              t("LOG_IN")
            }}</b-nav-item>

            <LocaleSwitcher></LocaleSwitcher>

            <b-nav-item-dropdown
              v-if="isAuthenticated()"
              id="user-dropdown"
              :text="getDisplayName()"
              right
            >
              <b-dropdown-item to="/account/password">{{
                t("CHOOSE_PASSWORD")
              }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'UserProfile' }">{{
                t("MANAGE_PROFILE")
              }}</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item id="logout" href="#" @click="logout()">{{
                t("LOG_OUT")
              }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>
<script setup>
import { computed, inject } from "vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const auth = inject("auth");
const router = useRouter();
const { t } = useI18n();

const logout = () => {
  auth.logout();
};
const getDisplayName = () => {
  const data = auth.getPayload();
  if (data.fn && data.ln) {
    return `${data.fn} ${data.ln}`;
  }
  if (data.fn && !data.ln) {
    return `${data.fn}`;
  }
  if (!data.fn && data.ln) {
    return `${data.ln}`;
  }
  return data.sub;
};

const canAccessSearch = computed(() => {
  const requiredRoles = ["ADMIN",  "VIEWER"];

  return requiredRoles.some((role) => auth.getPayload().roles.includes(role));
});
const canAccessModules = computed(() => {
  const requiredRoles = ["ADMIN", "VIEWER", "SCHOOL_MANAGER"];

  return requiredRoles.some((role) => auth.getPayload().roles.includes(role));
});
const canAccessSchools = computed(() => {
  const requiredRoles = ["ADMIN"];

  return requiredRoles.some((role) => auth.getPayload().roles.includes(role));
});
const canAccessGroups = computed(() => {
  const requiredRoles = ["ADMIN", "TRAINEE","TRAINER", "SCHOOL_MANAGER"];
  return requiredRoles.some((role) => auth.getPayload().roles.includes(role));
});

const isAuthenticated = () => {
  return auth.isAuthenticated();
};
</script>
<style lang="scss" scoped>
$container: #header-container-pub;
$nav: #nav-collapse;

#{$container} {
  #brand-logo {
    margin-top: -0.5rem;
    margin-right: 15px;
  }

  .navbar {
    padding-left: 0;
    padding-right: 0;

    .nav-collapse.show {
      padding-bottom: 120px;
    }
  }

  .navbar-light {
    .navbar-toggler {
      background-color: white;
    }
  }

  #{$nav} {
    align-self: start;
    margin-top: -0.5rem;
  }

  .container-fluid {
    padding-left: 32px;
    padding-right: 32px;
  }
}

:deep(.nav-link) {
  font-size: 18px;
  line-height: 24px;
  border-top: 2px solid transparent;
  padding-top: 18px;
  text-transform: capitalize;

  #{$container}.light & {
    color: $color-blue;
  }

  #{$container}.dark & {
    color: $color-white;
    background-color: transparent;
  }
}

:deep(.dropdown-item) {
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: $color-blue;
}

:deep(.nav-item) {
  margin: 0 1rem;
}

:deep(a.router-link-exact-active) {
  #{$container}.dark & {
    border-top: solid 2px $color-white;
  }

  #{$container}.light & {
    border-bottom: solid 2px $color-blue;
  }
}

:deep(.dropdown-menu a.router-link-exact-active) {
  border-top: solid 2px $color-blue !important;
  border-bottom: solid 2px $color-blue !important;
}

:deep(#user-dropdown .nav-link) {
  text-transform: none;
}

@media screen and (max-width: 992px) {
  #{$container}.dark .nav-link {
    color: $color-blue;
  }
  :deep(a.router-link-exact-active) {
    #{$container}.dark & {
      margin-top: 18px;
      width: 160px;
    }

    #{$container}.light & {
      width: 160px;
    }
  }
  :deep(.navbar-light .navbar-nav .nav-link) {
    color: $color-blue !important;
  }
  #{$container} #nav-collapse.show {
    padding-bottom: 130px;
  }
}
</style>
