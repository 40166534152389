<template>
  <b-modal
      id="remove-modal"
      ref="removeModalRef"
      @ok="handleRemoveModalOk"
      @hidden="resetRemoveModal"
      :ok-disabled="
      removeTraineeModalData.type !== 'GROUP_REMOVE_ELIGIBILITY' &&
      !removeTraineeModalData.reason
    "
      body-class="position-static"
  >
    <template v-slot:modal-title>
      {{
        t("REMOVE_PERSON_FROM_GROUP", {
          name:
              removeTraineeModalData.trainee.firstName +
              " " +
              removeTraineeModalData.trainee.lastName,
        })
      }}
    </template>
    <form
        ref="removeReasonFormRef"
        @submit.stop.prevent="handleRemoveModalSubmit"
    >
      <b-form-group :label="t('REASON')">
        <b-form-radio-group
            v-model="removeTraineeModalData.type"
            :options="reasonTypes"
        ></b-form-radio-group>
      </b-form-group>
      <b-input-group
          :prepend="t('NOTE')"
          v-if="removeTraineeModalData.type === 'GROUP_REMOVE_OTHER'"
      >
        <b-textarea
          v-model="removeTraineeModalData.reason"
          required
          autofocus
        ></b-textarea>
      </b-input-group>
    </form>
    <confirm-overlay
      :show="showConfirm"
      :text="'REMOVE_PERSON_FROM_GROUP_CONFIRM'"
      v-on:yes="handleConfirmOk"
      v-on:no="handleConfirmCancel"
    />
    <template v-slot:modal-ok>
      {{ t("REMOVE") }}
    </template>
    <template v-slot:modal-cancel>
      {{ t("CANCEL") }}
    </template>
  </b-modal>
</template>

<script setup>
import EventBus from "@/util/eventBus";
import ConfirmOverlay from "@/views/components/modals/confirmOverlay.vue";
import {useI18n} from "vue-i18n";
import {computed,  nextTick, onBeforeUnmount, onMounted, ref} from "vue";

const {t} = useI18n();
const removeModalRef = ref(null)
const removeReasonFormRef = ref(null)
const removeTraineeModalData = ref({
  trainee: null,
  reason: null,
  type: null,
})
const showConfirm = ref(false)


const emit = defineEmits(['remove-trainee', 'no','yes'])
onMounted(() => {
  EventBus.$on("show-remove-trainee-modal", showRemoveTraineeModal);
})
onBeforeUnmount(() => {
  EventBus.$off("show-remove-trainee-modal", showRemoveTraineeModal);
})


const reasonTypes = computed(() => {
  return [
    {value: "GROUP_REMOVE_ELIGIBILITY", text: t("NOT_ELIGIBLE")},
    {value: "GROUP_REMOVE_OTHER", text: t("OTHER")},
  ]
})


function resetRemoveModal() {
  removeTraineeModalData.value = {
    trainee: null,
    reason: "",
    type: "",
  };
  showConfirm.value = false;
}

function showRemoveTraineeModal(trainee) {
  removeModalRef.value.show("remove-modal");
  removeTraineeModalData.value.trainee = trainee;
}

function handleRemoveModalOk(bvModalEvt) {
  bvModalEvt.preventDefault();
  handleRemoveModalSubmit();
}

function handleRemoveModalSubmit() {
  if (!removeReasonFormRef.value.checkValidity()) {
    return;
  }
  showConfirm.value = true;
}

function handleConfirmCancel() {
  showConfirm.value = false;
}

function handleConfirmOk() {
  emit("remove-trainee", removeTraineeModalData.value);
  nextTick(() => {
    removeModalRef.value.hide("remove-modal");
  })
}
</script>
