import {AuthGuard} from "@/auth/guard";
import {Role} from "@/auth/oauth";
import Home from "@/views/public/Home.vue";
import Login from "../views/Login.vue";
import LoginPassword from "../views/LoginPassword.vue";
import RegisterAccount from "../views/RegisterAccount.vue";
import ActivateAccount from "../views/ActivateAccount.vue";
import OAuth from "../views/OAuth.vue";
import AccountPassword from "../views/AccountPassword.vue";
import Search from "../views/Search.vue";
import Groups from "../views/Groups.vue";
import ManageGroup from "../views/ManageGroup.vue";
import UserProfile from "../views/UserProfile.vue";
import MovingToEstonia from "../views/public/MovingToEstonia.vue";
import Programme from "../views/public/Programme.vue";
import Adaptation from "../views/public/Adaptation.vue";
import News from "../views/public/News.vue";
import Contacts from "../views/public/Contacts.vue";
import BasicModule from "../views/public/BasicModule.vue";
import LanguageModule from "../views/public/LanguageModule.vue";
import PasswordReset from "../views/PasswordReset.vue";
import GDPR from "../views/public/GDPR.vue";
import Modules from "../views/Modules.vue";
import Schools from "../views/Schools.vue";
import ManageModule from "../views/ManageModule.vue";
import ManageSchool from "../views/ManageSchool.vue";
import StudyModule from "../views/public/StudyModule.vue";
import FamilyModule from "../views/public/FamilyModule.vue";
import ResearchModule from "../views/public/ResearchModule.vue";
import ProtectionModule from "../views/public/ProtectionModule.vue";
import WorkModule from "../views/public/WorkModule.vue";
import EntrepreneurshipModule from "../views/public/EntrepreneurshipModule.vue";
import Blog from "../views/public/Blog.vue";
import TemporaryProtectionModule from "../views/public/TemporaryProtectionModule.vue";
import TemporaryProtectionLangModule from "../views/public/TemporaryProtectionLangModule.vue";
import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import * as process from "process";

const routes: any = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/moving-to-estonia",
    name: "MovingToEstonia",
    component: MovingToEstonia,
  },

  {
    path: "/programme",
    name: "Programme",
    component: Programme,
  },
  {
    path: "/adaptation",
    name: "Adaptation",
    component: Adaptation,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      anon: true,
    },
  },
  {
    path: "/login/password",
    name: "LoginPassword",
    component: LoginPassword,
    meta: {
      anon: true,
    },
  },
  {
    path: "/login/register",
    name: "RegisterAccount",
    component: RegisterAccount,
    meta: {
      anon: true,
    },
  },
  {
    path: "/login/activate",
    name: "ActivateAccount",
    component: ActivateAccount,
  },
  {
    path: "/oauth",
    name: "OAuth",
    component: OAuth,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      roles: [ Role.ADMIN, Role.VIEWER],
    },
  },
  {
    path: "/modules",
    name: "Modules",
    component: Modules,
    meta: {
      roles: [Role.ADMIN, Role.VIEWER, Role.SCHOOL_MANAGER],
    },
  },
  {
    path: "/modules/manage/:id",
    name: "ManageModule",
    component: ManageModule,
    props: true,
    meta: {
      roles: [Role.ADMIN, Role.VIEWER, Role.SCHOOL_MANAGER],
    },
  },

  {
    path: "/schools",
    name: "Schools",
    component: Schools,
    meta: {
      roles: [Role.ADMIN],
    },
  },
  {
    path: "/schools/manage/:id",
    name: "ManageSchool",
    component: ManageSchool,
    meta: {
      roles: [Role.ADMIN],
    },
  },

  {
    path: '/groups/:studentId?',
    name: 'Groups',
    component: Groups,
    meta: {
      roles: [
        Role.TRAINEE,
        Role.TRAINER,
        Role.ADMIN,
        Role.VIEWER,
        Role.SCHOOL_MANAGER,
      ],
    },
  },
  {
    path: "/account/password",
    name: "AccountPassword",
    component: AccountPassword,
    meta: {
      roles: [Role.ALL],
    },
  },
  {
    path: "/groups/manage/:id",
    name: "ManageGroup",
    component: ManageGroup,
    meta: {
      roles: [Role.TRAINER, Role.ADMIN, Role.VIEWER, Role.SCHOOL_MANAGER],
    },
  },
  {
    path: "/account/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/account/profile/",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      roles: [Role.ALL],
    },
  },
  {
    path: "/trainee/:id",
    name: "Trainee",
    component: UserProfile,
    meta: {
      roles: [Role.TRAINER, Role.ADMIN, Role.VIEWER, Role.SCHOOL_MANAGER],
    },
  },
  {
    path: "/programme/basic-module",
    name: "BasicModule",
    component: BasicModule,
  },
  {
    path: "/gdpr",
    name: "GDPR",
    component: GDPR,
  },
  {
    path: "/programme/language-module",
    name: "LanguageModule",
    component: LanguageModule,
  },
  {
    path: "/programme/study-module",
    name: "StudyModule",
    component: StudyModule,
  },
  {
    path: "/programme/family-module",
    name: "FamilyModule",
    component: FamilyModule,
  },
  {
    path: "/programme/research-module",
    name: "ResearchModule",
    component: ResearchModule,
  },
  {
    path: "/programme/protection-module",
    name: "ProtectionModule",
    component: ProtectionModule,
  },
  {
    path: "/programme/work-module",
    name: "WorkModule",
    component: WorkModule,
  },
  {
    path: "/programme/entrepreneurship-module",
    name: "EntrepreneurshipModule",
    component: EntrepreneurshipModule,
  },
  {
    path: "/blog/:slug",
    name: "Blog",
    component: Blog,
    props: true,
  },
  {
    path: "/programme/temporary-protection-module",
    name: "TemporaryProtectionModule",
    component: TemporaryProtectionModule,
  },
  {
    path: "/programme/temporary-protection-lang-module",
    name: "TemporaryProtectionLangModule",
    component: TemporaryProtectionLangModule,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  return AuthGuard(to, from);
});

export default router;
