<template>
  <b-form id="blogForm"  >
    <label class="form-label">{{ t("TITLE") }}</label>
    <b-input type="text" v-model="blogForm.title" required></b-input>
    <label class="form-label">{{ t("LANGUAGE") }}</label>
    <b-form-select
        :options="getAvailableLangs"
        v-model="blogForm.lang"
        required
    ></b-form-select>
      </b-form>
</template>

<script setup>
import {computed, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

defineExpose({ submitForm })

const {t, locale, availableLocales} = useI18n();
const props = defineProps(["disabledLangs"])
const {disabledLangs} = toRefs(props);
const emits = defineEmits(['submit-blog'])
const blogForm = ref({
  title: null,
  slug: null,
  titleHtml: null,
  lang: null,
})

function submitForm() {
  if (isValid()) {
    blogForm.value.titleHtml = '<div class="title-white">' + blogForm.value.title + "</div>";
    blogForm.value.slug = getSlug(blogForm.value.title);
    emits('submit-blog', blogForm.value);
  }
}
function isValid() {
  const blogForm = document.getElementById('blogForm');
  if (!blogForm.checkValidity()) {
    blogForm.reportValidity();
    return false
  }
  return true
}

function getSlug(raw) {
  return raw
      .toLowerCase()
      .normalize("NFD")
      .replace(/[–!*'();:@&=+$,/?#[\]]/g, "")
      .trim()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/--+/g, "-"); // Replace multiple - with single -
}
const getAvailableLangs = computed(() => {
  return availableLocales.filter(locale => !disabledLangs.value.includes(locale));
})
</script>
