<template>
  <b-container>
    <b-row class="section-header">
      <b-col>
        <div class="title-blue">{{ t("NEWS") }}</div>
      </b-col>
    </b-row>
    <b-row class="section">
      <b-col cols="6">
        <img class="mw-100" :src="images.news" alt="image" />
      </b-col>
      <b-col cols="6">
        <text-block
          content-id="introductionBlock"
          v-on:save="save"
          :content="content.elements.introductionBlock"
        ></text-block>
      </b-col>
    </b-row>
    <separator />
    <b-row class="section">
      <b-col cols="8" id="left-col">
        <b-row>
          <b-col cols="auto">
            <div class="text24-blue">{{ t("BLOG") }}</div>
          </b-col>
          <b-col v-if="isContentManager()">
            <b-button variant="blue" @click="createBlog">{{
              t("ADD")
            }}</b-button>
          </b-col>
        </b-row>
        <b-row>
          <template v-for="(post, index) in blogsToShow">
            <b-col v-if="index === 0" cols="12" :key="post.slug">
              <div class="showblogs mt-3"></div>
              <div class="mt-2">
                <b-link
                  class="text18-blue"
                  :to="{ name: 'Blog', params: { slug: post.slug } }"
                  >{{ post.title }}
                </b-link>
              </div>
            </b-col>
            <b-col v-else cols="6" >
              <div class="showblogs mt-3"></div>
              <div class="mt-2">
                <b-link
                  class="text18-blue"
                  :to="{ name: 'Blog', params: { slug: post.slug } }"
                  >{{ post.title }}
                </b-link>
              </div>
            </b-col>
          </template>
        </b-row>
        <div class="mt-3">
          <a role="button" class="text16-blue" @click="showMoreBlogs()"
            >{{ isReadMoreOpen ? t("LESS") : t("MORE") }}...</a>
        </div>
        <hr class="mt-3" />
        <div class="text24-blue mt-3">{{ t("MEDIA") }}</div>
        <div class="mt-3">
          <div v-for="link in mediaLinks()" :key="link.title">
            <div class="text14-gray">{{ link.date + " " + link.source }}</div>
            <div>
              <a class="text16-blue"
                :href="link.href"
                target="_blank"
                rel="noopener noreferrer"
                >{{ link.title }}</a>
            </div>
            <hr class="mb-3" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="4" id="right-col">
        <div class="text24-blue mt-3">Facebook</div>
        <div
          class="fb-page mt-3"
          data-href="https://www.facebook.com/settleinestonia"
          data-tabs="timeline"
          data-width=""
          data-height=""
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="true"
          data-show-facepile="true">
          <blockquote
            cite="https://www.facebook.com/settleinestonia"
            class="fb-xfbml-parse-ignore">
            <a href="https://www.facebook.com/settleinestonia"
              >Settle in Estonia</a>
          </blockquote>
        </div>
        <monthly-newsletter></monthly-newsletter>
        <hr class="mt-3" />
        <migration-statistics></migration-statistics>
        <hr class="mt-3" />
        <field-research></field-research>
      </b-col>
    </b-row>
    <blog-creation-modal  v-on:added-blog="showBlog"></blog-creation-modal>
  </b-container>
</template>

<script setup>

import {inject, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref} from "vue";
import { useI18n } from "vue-i18n";
import eventBus from "@/util/eventBus";
import { useRouter } from "vue-router";
import { usePageContent } from "@/mixins/pageContentMixin2";
import MonthlyNewsletter from "@/views/components/MonthlyNewsletter.vue";
import MigrationStatistics from "@/views/components/MigrationStatistics.vue";
import FieldResearch from "@/views/components/FieldResearch.vue";
import BlogCreationModal from "@/views/components/modals/blogCreationModal.vue";
import Separator from "@/views/components/Separator.vue";
import TextBlock from "@/views/components/TextBlock.vue";
import newsImage from "@/assets/news.jpg";

const { getPageContent, savePageContent } = usePageContent();
const axios = inject("axios");
const { t, locale } = useI18n();
const auth = inject("auth");
const router = useRouter();
const content = ref({ elements: {} });
const pageName = "news";
const blogPosts = ref([]);
const blogsToShow = ref([]);
const isReadMoreOpen = ref(false);
const images = {
  news: newsImage,
};

onBeforeMount(() => {
  eventBus.$on("locale", loadPageContent);
  eventBus.$on("locale", loadBlogs);
  loadPageContent();
  loadBlogs();
});

onBeforeUnmount(() => {
  eventBus.$off("locale", loadPageContent);
  eventBus.$off("locale", loadBlogs);
});
onMounted(() => {
  if (window.FB) {
    window.FB.XFBML.parse();
  }
});
async function loadPageContent() {
  content.value = await getPageContent(axios, pageName, locale.value);
}

const save = (elem) => {
  savePageContent(axios, pageName, locale.value, elem.element, elem.value);
}

const isContentManager = () => {
  return auth.hasRole("CONTENT_MANAGER");
}

const mediaLinks =  () => {
  return [
    {
      title:
        "Martin Tulit: ränne, kohanemine ja lõimumine peavad moodustama koostoimiva terviku",
      date: "02.02.2021",
      source: "Siseministeerium",
      href: "https://siseturvalisus.wordpress.com/2021/02/02/martin-tulit-ranne-kohanemine-ja-loimumine-peavad-moodustama-koostoimiva-terviku/",
    },
    {
      title:
        "Эстонское государство и социальный фонд ЕС выделяют миллионы на курсы для приезжих",
      date: "30.01.2021",
      source: "МК-Эстония",
      href: "https://www.mke.ee/sobytija/estonskoe-gosudarstvo-i-sotsialnyj-fond-es-vydelyayut-milliony-na-kursy-dlya-priezzhikh",
    },
    {
      title:
        "Välismaalaste kohanemisprogramm: suhted kinnitavad sisseelamise edukust",
      date: "04.03.2020",
      source: "Postimees",
      href: "https://leht.postimees.ee/6913928/valismaalaste-kohanemisprogramm-suhted-kinnitavad-sisseelamise-edukust",
    },
    {
      title:
        "EMN: 2019. aasta rände olukord Eestis oli stabiilne, kasvutrend statistikas jätkus",
      date: "24.08.2020",
      source: "Siseministeerium",
      href: "https://siseturvalisus.wordpress.com/2020/08/24/emn-2019-aasta-rande-olukord-eestis-oli-stabiilne-kasvutrend-statistikas-jatkus/2/",
    },
    {
      title: "Aimar Altosaar: Eesti kutsub kohanejad pardale",
      date: "04.03.2020",
      source: "Postimees",
      href: "https://leht.postimees.ee/6913942/aimar-altosaar-eesti-kutsub-kohanejad-pardale",
    },
  ];
};

async function loadBlogs() {
  try {
    const resp = await axios.get("/client/blogs?lang=" + locale.value);
    if (resp && resp.data) {
      blogPosts.value = resp.data.blogs;
      blogsToShow.value = blogPosts.value.slice(0, 5);
    }
  } catch (e) {
    console.log(e);
  }
}

function showMoreBlogs() {
  if (!isReadMoreOpen.value) {
    blogsToShow.value = blogPosts.value;
    isReadMoreOpen.value = true;
  } else {
    blogsToShow.value = blogPosts.value.slice(0, 5);
    isReadMoreOpen.value = false;
  }
}

function showBlog(blog) {
  router.push({ name: "Blog", params: { slug: blog.slug, data: blog } }).then();
}

async function createBlog() {
  await nextTick()
  eventBus.$emit("show-blog-creation-modal");
}


</script>
<style lang="scss" scoped>
#left-col {
  border-right: $separator-line;
  padding-right: 30px;
  word-break: break-word;
}

#right-col {
  padding-left: 30px;
}

#input-email {
  border: 0;
  border-bottom: $separator-line;
  font-size: 14px;
  line-height: 16px;
  color: #575a5d;
  padding-left: 0;
}

#link-container {
  background: $color-gray-light;
}

.showblogs {
  width: 100%;
  height: 200px;
  border: solid 1px black;
  background: url("@/assets/news.jpg") no-repeat center;
  background-size: cover;
}
</style>
