<template>
  <div></div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { inject, onMounted } from "vue";
const auth = inject("auth");
const route = useRoute();
onMounted(() => {
  authorize();
});

function authorize() {
  if ("token" in route.query && route.query.token) {
    auth.login(route.query.token);
  }
}
</script>
