<template>
  <div>
    <div
      v-for="(entry, listIndex) in localData"
      :key="entry.index"
      class="border-bottom no-gutters">
      <b-row v-b-toggle="getIdPrefix(entry.index)" class="py-3">
        <b-col cols="auto">
          <text-block
            :content-id="getIdPrefix(entry.index) + 'Title'"
            :index="listIndex"
            v-on:save="save"
            :content="entry.title">
          </text-block>
        </b-col>
        <b-col>
          <b-button
            v-if="isContentManager()"
            @click="deleteRow(entry, listIndex)"
          >
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-col>
        <b-col cols="1" class="ml-auto text-right">
          <b-icon
            icon="chevron-down"
            style="color: blue"
            class="closed"
          ></b-icon>
          <b-icon icon="chevron-up" style="color: blue" class="opened"></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-collapse
            :id="getIdPrefix(entry.index)"
            accordion="my-accordion"
            role="tabpanel"
          >
            <text-block
              class="px-3 pb-3"
              :id="getIdPrefix(entry.index) + 'Text'"
              :index="listIndex"
              v-on:save="save($event, listIndex)"
              :content="entry.content"
            >
            </text-block>
          </b-collapse>
        </b-col>
      </b-row>
    </div>
    <b-button v-if="isContentManager()" class="mt-3" @click="addRow">
      <b-icon-plus></b-icon-plus>
    </b-button>
  </div>
</template>

<script setup>

import { usePageContent } from "@/mixins/pageContentMixin2";
import { inject, onMounted, ref, toRefs, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { editorProps as props } from "@tinymce/tinymce-vue/lib/es2015/main/ts/components/EditorPropTypes";
import TextBlock from "./TextBlock.vue";


const { savePageContentBatch, deletePageContentBatch } = usePageContent();
const { t, locale } = useI18n();
const auth = inject("auth");
const idPrefix = ref("");
const { localData, page } = toRefs(props);

watchEffect(() => {
  localData.value = props.list;
});

onMounted(() => {
  idPrefix.value = props.id;
});

const getIdPrefix = (index) => {
  return idPrefix.value + "Accordion" + index;
};
const isContentManager = () => {
  return auth.hasRole("CONTENT_MANAGER");
};
const addRow = () => {
  const index = localData.value[localData.value.length - 1].index;
  localData.value.push({
    index: index + 1,
    title: '<div class="text16-blue bold">' + t("TITLE") + "</div>",
    content: t("CONTENT"),
    needsPersisting: true,
  });
};
const deleteRow = (row, index) => {
  // check if row is stored in the db
  if (row.needsPersisting) {
    localData.value.splice(index, 1);
    return;
  }

  const id = getIdPrefix(row.index);
  const title = { page: page.value, lang: locale.value, element: id + "Title" };
  const content = { page: page.value, lang: locale.value, element: id + "Text" };

  if (deletePageContentBatch(axios, [title, content])) {
    localData.value.splice(index, 1);
  }
};

const save = (elem) => {
  // Just added row, save title and content together
  if (localData.value[elem.index].needsPersisting) {
    let title;
    let content;
    if (elem.element.includes("Title")) {
      title = {
        page: page.value,
        lang: locale.value,
        element: elem.element,
        value: elem.value,
      };
      content = {
        page: page.value,
        lang: locale.value,
        element: elem.element.replace("Title", "Text"),
        value: localData.value[elem.index].content,
      };
    } else {
      title = {
        page: page.value,
        lang: locale.value,
        element: elem.element.replace("Text", "Title"),
        value: localData.value[elem.index].title,
      };
      content = {
        page: page.value,
        lang: locale.value,
        element: elem.element,
        value: elem.value,
      };
    }
    localData.value[elem.index].needsPersisting = false;
    savePageContentBatch(axios, [title, content]);
  } else {
    savePageContentBatch(axios, [title, content]);
  }
};
</script>
