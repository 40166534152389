<template>
  <div>
    <b-container>
      <b-row class="section-header">
        <b-col>
          <div class="title-blue">{{ t("HELP_ADAPTING") }}</div>
        </b-col>
      </b-row>
      <b-row class="section">
        <b-col>
          <text-and-picture :picture-left="false" :data="introduction" />
        </b-col>
      </b-row>
    </b-container>
    <div
      class="section"
      style="
        background-color: #0000f0;
        background-size: cover;
        position: sticky;">
      <b-container>
        <b-row>
          <b-col>
            <text-and-picture :picture-left="true" :data="language" />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div
      class="section"
      style="background-color: #d2c3d4; background-size: cover">
      <b-container>
        <b-row>
          <text-and-picture :picture-left="false" :data="jobBlock" />
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-row class="section">
        <text-and-picture :picture-left="true" :data="education" />
      </b-row>
    </b-container>
    <div
      class="section"
      style="background-color: #f0f1f2; background-size: cover">
      <b-container>
        <b-row class="section">
          <text-and-picture
            :picture-left="false"
            :data="healthcare"
            blue-link-box="true"/>
        </b-row>
        <b-row>
          <div class="col-lg-3 col-md-12">
            <text-block
              content-id="healthRoadmap"
              v-on:save="save"
              :content="content.elements.healthRoadmap"
            ></text-block>
          </div>
          <div class="col-lg-9 col-md-12">
            <img
                alt="healthRoadmap"
                class="mw-100"
                :style="healthRoadmap.style"
                :src="healthRoadmap.image.est"
                v-if="locale === 'EST'"/>
            <img
                alt="healthRoadmap"
                :style="healthRoadmap.style"
                :src="healthRoadmap.image.en"
                v-if="locale === 'ENG'"/>
            <img
                alt="healthRoadmap"
                :style="healthRoadmap.style"
                :src="healthRoadmap.image.rus"
                v-if="locale === 'RUS'"/>
          </div>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-row class="section">
        <text-and-picture :picture-left="true" :data="socialCare" />
      </b-row>
    </b-container>
    <b-container>
      <b-row class="section">
        <text-and-picture :picture-left="false" :data="accommodation" />
      </b-row>
    </b-container>
    <div
      class="section"
      style="background-color: #000096; background-size: cover" >
      <b-container>
        <b-row>
          <text-and-picture :picture-left="true" :data="freeTime"/>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script setup>
import {computed, inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {usePageContent} from "@/mixins/pageContentMixin2.ts";
import TextBlock from "@/views/components/TextBlock.vue";
import TextAndPicture from "@/views/components/TextAndPicture.vue";
import husky from "@/assets/husky.svg";
import winter_house from "@/assets/winter_house.svg";
import winter_house_bg from "@/assets/winter_house_bg.svg";
import tomatoes from "@/assets/tomatoes.svg";
import tomatoes_bg from "@/assets/tomatoes_bg.svg";
import school from "@/assets/school.svg";
import school_bg from "@/assets/school_bg.svg";
import settle_book from "@/assets/settle_book.svg";
import settle_bg1 from "@/assets/settle_bg1.svg";
import forest from "@/assets/forest.svg";
import forest_bg from "@/assets/forest_bg.svg";
import lady_rus from "@/assets/lady_rus.svg";
import lady from "@/assets/lady.svg";
import lady_ee1 from "@/assets/lady_ee1.svg";


const {getPageContent, savePageContent} = usePageContent();
const axios = inject("axios");
const {t, locale} = useI18n();
const content = ref({elements: {}});
const pageName = "adaptation";
const accordions = ref({});

onMounted(() => {
  EventBus.$on("locale", loadPageContent);
  loadPageContent();
});

onBeforeUnmount(() => {
  EventBus.$off("locale", loadPageContent);
});

async function loadPageContent() {
  content.value = await getPageContent(axios, pageName, locale.value);
  accordions.value = {};
  Object.keys(content.value.elements).forEach((elem) => {
    const index = elem.indexOf("Accordion");
    if (index > 0 && elem.endsWith("Title")) {
      const name = elem.substring(0, index);
      if (!accordions.value[name]) {
        accordions.value[name] = [];
      }
      const accordionIndex = parseInt(elem.substring(index + 9), 10);
      accordions.value[name].push({
        index: accordionIndex,
        title:
          content.value.elements[name + "Accordion" + accordionIndex + "Title"],
        content:
          content.value.elements[name + "Accordion" + accordionIndex + "Text"],
      });
    }
  });
  Object.values(accordions.value).forEach((accordionEntries) => {
    accordionEntries.sort((e1, e2) => e1.index - e2.index);
  });
}

const save = (elem) => {
  savePageContent(axios, pageName, locale.value, elem.element, elem.value);
};

const introduction = computed(() => {
  return {
    image: {
      image: settle_book,
      mask: {
        src: settle_bg1,
        style: "position: absolute; right: -228px; top: -26px;",
      },
    },
    page: pageName,
    element: "introduction",
    picture: true,
    text1: content.value ? content.value.elements.introductionText1 : "",
    text2: content.value ? content.value.elements.introductionText2 : "",
  };
});

const language = computed(() => {
  return {
    image: {
      image: forest,
      mask: {
        src: forest_bg,
        style: "position: absolute; right: -68px; top: 0px;",
      },
    },
    page: pageName,
    element: "language",
    picture: true,
    text1: content.value ? content.value.elements.languageText1 : "",
    text2: content.value ? content.value.elements.languageText2 : "",
  };
});
const jobBlock = computed(() => {
  return {
    page: pageName,
    element: "jobBlock",
    picture: false,
    text1: content.value ? content.value.elements.jobBlockText1 : "",
    text2: content.value ? content.value.elements.jobBlockText2 : "",
    linkBox: content.value ? content.value.elements.jobBlockLinkBox : "",
  };
});
const healthcare = computed(() => {
  return {
    page: pageName,
    element: "healthcare",
    picture: false,
    text1: content.value ? content.value.elements.healthcareText1 : "",
    text2: content.value ? content.value.elements.healthcareText2 : "",
    linkBox: content.value ? content.value.elements.healthcareLinkBox : "",
  };
});

const healthRoadmap = computed(() => {
  return {
    image: {
      rus: lady_rus,
      en: lady,
      est: lady_ee1,
    },
    style: "position: center; right: 136px;",
    text1: content.value ? content.value.elements.healthRoadmapText1 : "",
    text2: content.value ? content.value.elements.healthRoadmapText2 : "",
  };
});

const education = computed(() => {
  return {
    image: {
      image: school,
      mask: {
        src: school_bg,
        style: "position: absolute; right: -234px; top: -47px;",
      },
    },
    page: pageName,
    element: "education",
    picture: true,
    text1: content.value ? content.value.elements.educationText1 : "",
    text2: content.value ? content.value.elements.educationText2 : "",
    linkBox: content.value ? content.value.elements.educationLinkBox : "",
    expandable: accordions.value["education"],
  };
});

const socialCare = computed(() => {
  return {
    image: {
      image: tomatoes,
      mask: {
        src: tomatoes_bg,
        style: "position: absolute; right: -250px; top: -40px;",
      },
    },
    page: pageName,
    element: "socialCare",
    picture: true,
    text1: content.value ? content.value.elements.socialCareText1 : "",
    text2: content.value ? content.value.elements.socialCareText2 : "",
    expandable: accordions.value["socialCare"],
  };
});
const accommodation = computed(() => {
  return {
    image: {
      image: winter_house,
      mask: {
        src: winter_house_bg,
        style: "position: absolute; right: -180px; top: 0px;",
      },
    },
    page: pageName,
    element: "accommodation",
    picture: true,
    text1: content.value ? content.value.elements.accommodationText1 : "",
    text2: content.value ? content.value.elements.accommodationText2 : "",
    linkBox: content.value ? content.value.elements.accommodationLinkBox : "",
  };
});

const freeTime = computed(() => {
  return {
    image: {
      image: husky,
    },
    page: pageName,
    element: "freeTime",
    picture: true,
    text1: content.value ? content.value.elements.freeTimeText1 : "",
    text2: content.value ? content.value.elements.freeTimeText2 : "",
    linkBox: content ? content.value.elements.freeTimeLinkBox : "",
  };
});
</script>

<style lang="scss" scoped></style>
