<template>
  <b-modal
      id="remove-group-modal"
      ref="removeModalRef"
      @ok="handleOk"
      @hidden="resetModal"
      :ok-disabled="isLoading"
      body-class="position-static"
  >
    <template v-slot:modal-title>
      {{ t("REMOVE_GROUP_TITLE", {name: group?.title}) }}
    </template>
    <p>{{ t("REMOVE_GROUP_CONFIRM_MSG") }}</p>
    <template #modal-ok>
      {{ t("REMOVE") }}
    </template>
    <template #modal-cancel>
      {{ t("CANCEL") }}
    </template>
  </b-modal>
</template>

<script setup>
import {inject, onBeforeUnmount, onMounted, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import EventBus from '@/util/eventBus'
import {makeToast} from "@/util/toastUtils";

const axios = inject("axios")
const {t} = useI18n()
const removeModalRef = ref(null)
const group = ref(null)
const isLoading = ref(false)
const emit = defineEmits(['group-removed'])

function showModal(g) {
  group.value = g;
  removeModalRef.value?.show();
}

function resetModal() {
  group.value = null;
  isLoading.value = false;
}

async function handleOk(bvModalEvt) {
  bvModalEvt.preventDefault();
  if (!group.value) return;
  isLoading.value = true;
  try {
    await axios.delete(`/training/group/${group.value.id}`);
    makeToast(t("REMOVED_GROUP"), "success", t("OPERATION_SUCCESSFUL"));
    emit("group-removed", group.value.id);
  } catch (e) {
    console.error("Error deleting group:", e);
  } finally {
    isLoading.value = false;
    removeModalRef.value?.hide();
  }
}

onMounted(() => {
  EventBus.$on('show-remove-group-modal', showModal);
});

onBeforeUnmount(() => {
  EventBus.$off('show-remove-group-modal', showModal);
});

</script>