import {ref} from "vue";
import type {App, Plugin} from "@vue/runtime-core";


export const Role = {
  TRAINEE: "TRAINEE",
  TRAINER: "TRAINER",
  ADMIN: "ADMIN",
  CONTENT_MANAGER: "CONTENT_MANAGER",
  SCHOOL_MANAGER: "SCHOOL_MANAGER",
  VIEWER: "VIEWER",
  DISABLED_USER: "DISABLED_USER",
  ALL: "ALL", // any authenticated user
};

class Auth {
  authenticated = ref(false);
  token = "";
  payload = ref({
    acc: "",
    fn: "",
    ln: "",
    roles: [] as string[],
    exp: 0,
    rvk: false,
    temp: false
  });
  error = null;
  onLogin: Function;
  onLogout: Function;

  constructor(onLogin: Function, onLogout: Function) {
    this.onLogin = onLogin;
    this.onLogout = onLogout;
  }
  isAuthenticated() {
    return this.authenticated.value;
  }

  async login(jwt: string) {
    localStorage.setItem("internal-api-token", jwt);
    this.token = jwt;
    await this.loadCredentials();
    await this.onLogin(this.getRoles());
  }

  async logout() {
    this.clearContext();
    await this.onLogout();
  }

  clearContext() {
    this.authenticated.value = false;
    this.token = "";
    this.payload.value = {
      acc: "",
      fn: "",
      ln: "",
      roles: [] as string[],
      exp: 0,
      rvk: false,
      temp: false
    };
    localStorage.removeItem("internal-api-token");
  }

  async isTokenValid() {
    try {
      return new Date().getTime() / 1000 < this.payload.value.exp;
    } catch (e) {
      return false;
    }
  }

  async loadCredentials() {
    if (this.isAuthenticated()) return;
    try {
      this.token = this.getToken();
      this.loadTokenData();
      if (await this.isTokenValid()) {
        this.authenticated.value = true;
      } else {
        this.clearContext();
      }
    } catch (e) {
      this.clearContext();
    }
  }

  getToken() {
    if (this.token && this.token.length > 0) {
      return this.token;
    } else if (localStorage.getItem("internal-api-token") == null) {
      throw new Error("No token");
    }
    return localStorage.getItem("internal-api-token") as string;
  }

  loadTokenData() {
    const parts = this.getToken().split(".");
    if (parts.length != 3) throw new Error("Invalid JWT");
    const base64 = parts[1].replace(/-/g, "+").replace(/_/g, "/");
    const json = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    const obj = JSON.parse(json);
    this.payload.value = {
      acc: obj.acc,
      fn: obj.fn,
      ln: obj.ln,
      exp: obj.exp,
      roles: obj.roles,
      rvk: obj.rvk,
      temp: obj.temp
    };
  }

  getRoles() {
    return this.payload.value.roles;
  }

  getRole() {
    const roles = this.getRoles();
    return roles && roles.length > 0 ? roles[0] : null;
  };

  hasRole(role: string) {
    return this.authenticated && this.getRoles().includes(role);
  }

  hasSomeRole(...roles: string[]) {
    return this.authenticated && roles.some((r) => this.hasRole(r));
  }

  getPayload() {
    return this.payload.value;
  }

  getRVK() {
    return this.payload.value.rvk;
  }

  getTemp() {
    return this.payload.value.temp;
  }

  getAcc() {
    return this.payload.value.acc;
  }
}


const NOOP = async () => ({});

  let
  singleton: any;

  export
  const
  getInstance = () => singleton;

  const
  plugin = ({
              onLogin = async (roles: Array<string>) => ({}),
              onLogout = NOOP,
}) => {
  if (!singleton) {
    singleton = new Auth(onLogin, onLogout);
  }

  return singleton;
};

export const OAuthPlugin: Plugin = {
  install(app: App, options: object) {
    const auth = plugin(options);
    app.config.globalProperties.$auth = auth;
    app.provide("auth", auth);
    app.config.globalProperties.$roles = Role;
  },
};
