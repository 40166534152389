<template xmlns="http://www.w3.org/1999/html">
  <b-container class="login" fluid="sm" style="text-align: left">
    <b-card>
      <h3>{{ t("AUTHENTICATION") }}</h3>
      <form @submit.prevent="login">
      <b-row class="mb-2">


            <b-col>
          <b-input
              type="text"
              :placeholder="t('SSN')"
              oninput="this.value = this.value.replace(/\s/g, '')"
              v-model="creds.username"
              autofocus
          ></b-input>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-input-group>
            <b-input class="form-control rounded"
                     :placeholder="t('PASSWORD')"
                     :type="!passwordHidden ? 'password' : 'text'"
                     v-model="creds.password"
            ></b-input>
            <b-input-group-append>
              <span v-if="creds.password !== ''" class="input-group-text">
                <b-icon-eye-slash v-if="!passwordHidden" @click="toggleShowPassword"></b-icon-eye-slash>
                <b-icon-eye v-if="passwordHidden" @click="toggleShowPassword"></b-icon-eye></span>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      </form>
      <b-row class="mb-2">
        <b-col>
          <b-button variant="primary" v-on:click="login()">{{
              t("ENTER")
            }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<style scoped>
.login {
  margin-top: 64px;
}
</style>

<script setup>
import {inject, ref} from "vue";
import {useI18n} from "vue-i18n";
const passwordHidden = ref(false);
const axios = inject("axios");
const auth = inject("auth");
const {t} = useI18n();
const creds = ref({
  username: "",
  password: "",
});

function toggleShowPassword() {
  passwordHidden.value = !passwordHidden.value;
}

async function login() {
  try {
    const response = await axios.post('/login/plain', creds.value);
    if (response && response.data) {
      await auth.login(response.data.token);
    }
  } catch (e) {
    console.log(e);
  }
  creds.value.password = "";
}
</script>
