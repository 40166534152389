<template>
  <div>
    <div id="header-container">
      <b-container>
        <b-row>
          <b-col>
            <text-block
                content-id="blog-title"
                title="title"
                white="white"
                v-on:save="saveTitle"
                :content="post.titleHtml"
            ></text-block>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="section">
      <b-row v-if="isContentManager()">
        <b-col cols="auto">
          <b-button variant="blue" @click="addLang">{{ t("ADD") }}</b-button>
        </b-col>
        <b-col>
          <b-button variant="blue" @click="showRemoveConfirmation">{{
              t("REMOVE")
            }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="auto">
          <div class="text18-gray">{{ dateFormat(post.createdAt) }}</div>
        </b-col>
        <b-col>
          <text-block
              content-id="blog-content"
              v-on:save="saveContent"
              :content="post.content"
          ></text-block>
        </b-col>
      </b-row>
    </b-container>
    <blog-creation-modal
        v-on:added-blog="newLangAdded"
        :existing-languages="post.availableLanguages"
        :group-id="post.blogGroupId"
    ></blog-creation-modal>
  </div>
</template>

<script setup>

import {inject, nextTick, onBeforeUnmount, onMounted, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {useRouter} from "vue-router";
import {usePageContent} from "@/mixins/pageContentMixin2.ts";
import {useDateFormat} from "@/mixins/dateFormatMixin2.ts";
import TextBlock from "@/views/components/TextBlock.vue";
import blogCreationModal from "@/views/components/modals/blogCreationModal.vue";
import {toast} from "vue3-toastify";

const { getPageContent, savePageContent } = usePageContent();
const {dateFormat} = useDateFormat();
const axios = inject("axios");
const { t, locale } = useI18n();
const auth = inject("auth");
const router = useRouter();
const content = ref({ elements: {} });
const props = defineProps(["slug", "data"]);
const { slug, data } = toRefs(props);
const post = ref(
    {
      id: null,
      slug: "",
      title: "",
      titleHtml: "",
      content: "",
      lang: "",
      blogGroupId: null,
      availableLanguages: [],
    })

onMounted(() => {
  EventBus.$on("locale", switchLang);
  switchLang()
  showPost();
})

onBeforeUnmount(() => {
  EventBus.$off("locale", switchLang);
})

const isContentManager = () => {
  return auth.hasRole("CONTENT_MANAGER");
};

function showPost() {
  if (data.value) {
    post.value = data.value;
  } else if (slug.value) {
    getPost();
  }
}

async function getPost() {
  try {
    const resp = await axios.get("/client/blog?name=" + slug.value);
    if (resp && resp.data) {
      post.value = resp.data;
    }
  } catch (e) {
    console.log(e);
  }
}

async function addLang() {
  await nextTick()
  EventBus.$emit("show-blog-creation-modal");
}

const newLangAdded = (blog) => {
  post.value = blog;
  router.replace({name: "Blog", params: {slug: blog.slug}})
      .then();
  EventBus.$emit("switch-locale", blog.lang);
}

async function switchLang(locale) {
  if (!post.value.availableLanguages.includes(locale) || locale === post.value.lang
  ) {
    return;
  }
  try {
    const resp = await axios.get(
        `/client/blog/switch-lang?lang=${locale}&groupId=${post.value.id}`,
    );
    if (resp && resp.data) {
      const availableLanguages = post.value.availableLanguages;
      post.value = resp.data;
      post.value.availableLanguages = availableLanguages;
      router
          .replace({name: "Blog", params: {slug: post.value.slug}})
          .then();
    }
  } catch (e) {
    console.log(e);
  }
}

function getSlug(raw) {
  return raw
      .toLowerCase()
      .normalize("NFD")
      .trim()
      .replace(/[–!*'();:@&=+$,/?#[\]]/g, "")
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/--+/g, "-"); // Replace multiple - with single -
}

    async function saveBlog() {
      try {
        const resp = await axios.put(
          "/client/blog/" + post.value.id,
          post.value,
        );
        if (resp && resp.data) {
          const availableLanguages = post.value.availableLanguages;
          post.value = resp.data;
          post.value.availableLanguages = availableLanguages;
          makeToast(
              t("SAVED"),
              "success",
              t("OPERATION_SUCCESSFUL"),
          );
        }
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    }

function extractTitle(str) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = str
  return tempDiv.textContent || tempDiv.innerHTML || ''
}

async function saveTitle(elem) {
  post.value.slug = getSlug(extractTitle(elem.value));
  post.value.title = extractTitle(elem.value);
  post.value.titleHtml = elem.value;
  const success = await saveBlog();
  if (success) {
    router
        .push({name: "Blog", params: {slug: post.value.slug}})
        .then();
  }
}

function saveContent(elem) {
  post.value.content = elem.value;
  saveBlog();
}


function showRemoveConfirmation() {
  const data = {
    msg:
        t("REMOVE_BLOG_CONFIRM_MSG"),
    callback: remove(),
  };
  EventBus.$emit("show-confirmation-modal", data);
}

async function remove() {
  try {
        const resp = await axios.delete(`/client/blog/${post.value.id}`);
        if (resp && String(resp.status).charAt(0) === "2") {
          makeToast(
              t("REMOVED_BLOG"),
              "success",
              t("OPERATION_SUCCESSFUL"),
          );
          router.replace({name: "News"}).then();
        }
  } catch (e) {
    console.log(e);
  }
}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};
</script>

<style lang="scss" scoped>
#header-container {
  background:
    linear-gradient(rgba(0, 0, 150, 0.4), rgba(0, 0, 150, 0.4)),
    url("@/assets/saun.jpg") no-repeat center;
  background-size: cover;
  padding-top: 240px;
  padding-bottom: 146px;
  margin-top: -112px;
  min-height: 664px;
}
</style>
