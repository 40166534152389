import moment from "moment-timezone";

const useTimeFormat = () => {
  const timeFormat = (date: string | number | Date) => {
    const d = new Date(date);
    const dm = moment(d);
    return dm.format("HH:mm");
  };
  return {
    timeFormat,
  };
};
export default useTimeFormat;
