<template>
  <b-container class="mt-5" fluid="sm">
    <div class="row section justify-content-center">
      <b-card class="mb-4">
        <h3 class="text24-blue">{{ t("CHOOSE_PASSWORD") }}</h3>
        <p id="password-policy">
          {{ t("SAFE_PW") }}:<br />
          - {{ t("MIN_15_CHARS") }}<br />
          - {{ t("CHARS_NUMS") }}<br />
          - {{ t("LC_UC") }}<br />
          - {{ t("NO_PERSONAL_DATA") }}<br />
          - {{ t("NO_VOCABULARY") }}<br />
        </p>
        <b-form >
          <b-row class="mb-2">
            <b-col>
              <b-form-group   >
                <b-input-group :invalid-feedback="pwFeedback()" :state="passwordState()">
                  <b-input class="form-control rounded"
                           required
                           name="password1"
                           id="password1"
                           :type="!passwordHidden1 ? 'password' : 'text'"
                           :placeholder="t('PASSWORD')"
                           :state="passwordState()"
                           v-model="form.passwordFirst"
                  ></b-input>
                  <b-input-group-append>
              <span v-if="form.passwordFirst !== ''" class="input-group-text">
                <b-icon-eye-slash v-if="!passwordHidden1" @click="toggleShowPassword1"></b-icon-eye-slash>
                <b-icon-eye v-if="passwordHidden1" @click="toggleShowPassword1"></b-icon-eye></span>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-form-group :state="passwordState()" :invalid-feedback="pwFeedback() "  >
                <b-input-group>
                  <b-form-input class="form-control rounded"
                           required
                           name="password2"
                           id="password2"

                           :type="!passwordHidden2 ? 'password' : 'text'"
                           :placeholder="t('PASSWORD')"
                           :state="passwordState() "
                           v-model="form.passwordSecond"
                  ></b-form-input>
                  <b-input-group-append>
              <span v-if="form.passwordSecond !== ''" class="input-group-text">
                <b-icon-eye-slash v-if="!passwordHidden2" @click="toggleShowPassword2"></b-icon-eye-slash>
                <b-icon-eye v-if="passwordHidden2" @click="toggleShowPassword2"></b-icon-eye></span>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <b-row class="mb-2 mt-4">
          <b-col>
            <b-button variant="blue" @click="setPassword" style="width: 100%">
              {{ t("SAVE") }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-container>
</template>

<style scoped>
#password-policy {
  font-size: 14px;
  color: dimgray;
}
#pw-tip {
  padding-top: 10px;
  font-size: 12px;
  color: deeppink;
}
</style>

<script setup>
import {computed, inject, ref} from "vue";
import {useI18n} from "vue-i18n";
import {toast} from "vue3-toastify";
import {useRoute, useRouter} from "vue-router";

const router = useRouter();
const {t} = useI18n();
const axios = inject("axios");
const route = useRoute();
const startValidation = ref(false);
const passwordHidden1 = ref(false);
const passwordHidden2 = ref(false);
const pwReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[^\w\s]).{15,}$/;
const form = ref({
  passwordFirst: '',
  passwordSecond: '',
});

function isStrongPassword(pw) {
  return pw !== "" && pwReg.test(pw);
}
const passwordState = () => {
  if (form.value.passwordFirst !== form.value.passwordSecond ) {
    return false;
  }
  if ((form.value.passwordFirst && !isStrongPassword(form.value.passwordFirst)) && (form.value.passwordSecond && !isStrongPassword(form.value.passwordSecond))) {
    return false;
  }
  if ((form.value.passwordFirst && isStrongPassword(form.value.passwordFirst)) && (form.value.passwordSecond && isStrongPassword(form.value.passwordSecond))) {
    return true;
  }
  return null;
}
function pwFeedback() {
  if ((form.value.passwordFirst && !isStrongPassword(form.value.passwordFirst)) && (form.value.passwordSecond && !isStrongPassword(form.value.passwordSecond))) {
    return t("INVALID_PW_PASSWORD");
  }
  if (form.value.passwordFirst !== form.value.passwordSecond) {
    return t("PASSWORD_MISMATCH");
  }
  return null;
}

const isValidAndMatch = computed(() => {
  return isStrongPassword(form.value.passwordFirst) && form.value.passwordFirst === form.value.passwordSecond;
});

const setPassword = async () => {
  startValidation.value = true;
  if (!isValidAndMatch.value) {
    toast(t("CHECK_INPUT"), {autoHideDelay: 5000, variant: "danger"});
    return;
  }
  try {
    const response = await axios.put("/account/password", {
      password: form.value.passwordFirst,
    });
    form.value.password1 = "";
    form.value.password2 = "";
    if (response.status === 200) {
      toast(t("OPERATION_SUCCESSFUL"), {autoHideDelay: 5000, variant: "success"});
      form.value.passwordFirst = "";
      form.value.passwordSecond = "";
    }
  } catch (e) {
    console.error(e);
  }
};

function toggleShowPassword1() {
  passwordHidden1.value = !passwordHidden1.value;
}

function toggleShowPassword2() {
  passwordHidden2.value = !passwordHidden2.value;
}
</script>
