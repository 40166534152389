<template>
  <b-card v-if="isComponentVisible" class="main">
    <b-card-title class="text24-blue mt-3">{{ t("ADD_FILE") }}</b-card-title>
    <b-card-body class="dropbox" v-if="!isSpinning">
      <b-form-select
        :hidden="fileIdentifier !== 'MIGRATION_STATISTICS'"
        v-model="fileLang"
        :options="chooseStatisticsLanguage"
        size="sm"
        class="mt-3">
      </b-form-select>
      <b-form-input
        v-model="webFileName"
        size="sm"
        class="mt-3"
        v-bind:placeholder="t('FILE_NAME')">
      </b-form-input>
      <div id="app" @drop.prevent @dragover.prevent class="dropbox-area">
        <b-form-file
          v-model="file"
          accept=".doc, .docx, .pdf, .odt"
          id="selectedFile"
          placeholder="Drag & drop here or"
          size="sm"
          ref="file"
          class="mb-2"
        >
        </b-form-file>
        <div class="mt-3" v-if="file">
          Selected file: {{ file ? file.name : "" }}
        </div>
      </div>
    </b-card-body>
    <b-card-body class="dropbox" v-if="isSpinning">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </b-card-body>
    <div class="new-file-buttons">
      <b-button @click="cancel" :disabled="isSpinning">{{
        t("CANCEL")
      }}</b-button>
      <b-button variant="blue" :disabled="uploadDisabled" @click="upload">{{
        t("SAVE")
      }}</b-button>
    </div>
  </b-card>
</template>

<script setup>
import { highlightStyle } from "@/mixins/highlightStyleMixin2";
import EventBus from "@/util/eventBus";
import { useI18n } from "vue-i18n";
import {computed, inject, ref, toRefs} from "vue";

const { missingValueBorderHighlight } =  highlightStyle();
const { t, locale, tm } = useI18n();
const auth = inject("auth");
const axios = inject("axios");
import { toast } from "vue3-toastify";
const props = defineProps({
  fileIdentifier: {
    type: String,
    required: true,
  },
});

const { fileIdentifier } = toRefs(props);
const file = ref(null);
const isComponentVisible = ref(true);
const fileLang = ref(null);
const webFileName = ref(null);
const formData = new FormData();
const isSpinning = ref(false);

const uploadDisabled =computed( () => {
  if (
    (fileIdentifier.value === "MIGRATION_STATISTICS" && !fileLang.value) ||
    !file.value ||
    !webFileName.value ||
    isSpinning.value
  ) {
    return true;
  }
  return !file.value;
})
const langOptions = () => {
  return chooseStatisticsLanguage;
};
const cancel = () => {
  isComponentVisible.value = false;
  return isComponentVisible.value;
};

async function upload() {
  isSpinning.value = true;
  updateSelectedFile();
  const requestConfig = {
    headers: {
      "content-type": "multipart/form-data",
      Accept: "*/*",
    },
  };
  try {
    const resp = await axios.post("/file", formData, requestConfig);
    if (resp && resp.data) {
      isSpinning.value = false;
      EventBus.$emit("FETCH_NEW_FILES");
      cancel();
      makeToast(t("OPERATION_SUCCESSFUL"), "success", t("FILE_ADDED"));
    }
  } catch (e) {
    isSpinning.value = false;
    console.log(e);
  }
}

const updateSelectedFile = () => {
  const requestBody = {
    fileIdentifier: fileIdentifier.value,
    webFileName: webFileName.value,
    lang: fileLang.value,
  };
  formData.append("file", file.value);
  formData.append(
    "request",
    new Blob([JSON.stringify(requestBody)], { type: "application/json" }),
  );
};

const chooseStatisticsLanguage = computed(() => {
  return [
    { value: null, text: t("SELECT_LANG"), disabled: true },
    { value: "EST", text: t("ESTONIAN") },
    { value: "ENG", text: t("ENGLISH") },
    { value: "RUS", text: t("RUSSIAN") },
  ];
});

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
};
</script>

<style lang="less">
.dropbox {
  text-align: center;
  outline-offset: -10px;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px;
  position: relative;
  cursor: pointer;
}

.icon-remove {
  border-color: transparent;
  background-color: transparent;
  color: blue;
}

.new-file-buttons {
  padding-top: 20px;
  float: right;
}

.dropbox:hover {
  background: lightgrey;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.dropbox-area {
  margin-top: 20px;
}
</style>
