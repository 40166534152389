<template>
  <b-overlay :show="show" no-wrap :rounded="true">

    <template v-slot:overlay>
      <div ref="dialog" tabindex="-1" role="dialog" class="text-center">
        <p>
          <strong id="form-confirm-label">{{ t(text) }}</strong>
        </p>
        <div>
          <b-button class="mr-3" @click="handleConfirmCancel">{{
              t("CANCEL")
            }}
          </b-button>
          <b-button variant="danger" @click="handleConfirmOk()">{{
              t("YES")
            }}
          </b-button>
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script setup>
import {useI18n} from "vue-i18n";


const {t} = useI18n();
const props = defineProps({show: Boolean, text: String});

const emit = defineEmits(['yes', 'no'])

async function handleConfirmOk() {
  emit("yes");
}

function handleConfirmCancel() {
  emit("no");

}
</script>
