<template>
  <div class="row">
    <b-alert variant="success" :show="sent" :dismissible="true">{{
      t("PW_SENT")
    }}</b-alert>
    <div v-if="showResetForm">

      <b-row class="mb-2 pl-3">
        <b-col class="text-center">
          <div class="text16-blue font-italic mb-2">{{ t("RESET_TIP") }}</div>
          <b-input
            type="email"
            :placeholder="t('EMAIL')"
            autofocus
            class="mb-2 form-control"
            v-model="reset.email"
          ></b-input>

          <b-input
            :placeholder="t('SSN')"
            class="mb-2 form-control"
            v-model="reset.ssn"
          ></b-input>

          <div v-if="noAccount" style="color: deeppink">{{ t("NO_ACC") }}</div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col class="pl-4">
          <b-button
            variant="blue"
            class="enter-btn"
            v-on:click="requestPasswordReset()"
            >{{ t("SEND") }}</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button variant="white" @click="toggleLogin()">{{
            t("BACK")
          }}</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const props = defineProps(['showResetForm']);
const route = useRoute();
const axios = inject("axios");
const emit = defineEmits(["hide-form"]);

const { t } = useI18n();
const noAccount = ref(false);
const reset = ref({
  email: null,
  ssn: null,
  account: null,
});

const sent = ref(false);


const toggleLogin = () => {

  reset.value.email = null;
  reset.value.ssn = null;


  emit("hide-form", null);
};

const requestPasswordReset = async () => {
  const foo = await checkAccount()
  if (!foo) {
    noAccount.value = true;
  } else {
    noAccount.value = false;
    try {
      const resp = await axios.post(`/account/reset/${foo}`);
      if (resp && resp.data === "sent") {
        sent.value = true;
      }
      if (sent.value) {
        toggleLogin();
      }
    } catch (e) {
      console.log(e);
    }
  }
};

async function checkAccount() {
  try {
    const resp = await axios.get(
      `/account/exists/${reset.value.email}/${reset.value.ssn}`,
    );
    return resp.data;
  } catch (e) {
    console.log(e);
  }
}
</script>
