<template>
  <b-container class="section-p70" fluid="sm">
    <div id="titleRow" class="row">
      <div class="col" style="text-align: left; padding-bottom: 1%">
        <h2 class="title-blue">{{ t("TRAINING_GROUPS") }}</h2>
      </div>
    </div>
    <div class="separator-line separator-margin"></div>
    <div v-if="isTraineeOrAdminAsTrainee" class="row">
      <b-col cols="12">
        <div class="text24-blue">
          {{ t("REGISTERED") }}
        </div>
      </b-col>
      <b-col class="mt-3" cols="12">
        <div class="groups-table">
          <user-table :items="userActiveGroups"></user-table>
        </div>
      </b-col>
    </div>
    <div v-if="isAdmin && !isAdminAsTrainee" class="row section-padding-top">
      <div class="col-12">
        <div class="text24-blue">
          {{ t("UPCOMING") }}
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-6 col-lg-3 mt-3">
            <b-input
              id="keyword"
              v-model="keyword"
              :placeholder="t('FILTER_GROUPS')"
              class="form-control"
              type="text"
            ></b-input>
          </div>
          <div class="col mt-3">
            <b-row align-v="center">
              <b-col class="p-0" cols="4">
                <b-select
                  id="searchStatus"
                  v-model="searchStatus"
                  :options="statusOptions"
                  class="status"
                  name="searchStatus"
                ></b-select>
              </b-col>
              <b-col cols="auto">
                <b-button variant="blue" @click="doSearch()">{{
                  t("SEARCH")
                }}</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-container>
        <div class="col-12 mt-3">
          <div class="row">
            <div class="groups-table col-12">
              <available-groups-table
                  :filterStatus="searchStatus"
                  :filterText="keyword"
              ></available-groups-table>
            </div>
          </div>
        </div>
      </b-container>
    </div>

    <div v-if="isTraineeOrAdminAsTrainee" class="row section-padding-top">
      <div class="col-12">
        <div class="text24-blue">
          {{ t("UPCOMING") }}
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-6 col-lg-3 mt-3">
            <b-input
                id="keyword"
                v-model="keyword"
                :placeholder="t('FILTER_GROUPS')"
                class="form-control"
                type="text"
            ></b-input>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="groups-table">
          <div class="text-right text18-blue bold">
            {{ t("EXPLANATION") }}
            <router-link to="/programme#section-faq">{{ t("READ_MORE") }}</router-link>
          </div>
          <user-table
              :filterStatus="searchStatus"
              :filterText="keyword"
              :eligibleStatus='eligibleStatus'
              :items="availableGroupsToDisplay"
              :register="true"
          ></user-table>
        </div>
      </div>
    </div>

    <language-modal :isModalVisible="isModalVisible"></language-modal>
    <confirm-registration></confirm-registration>
    <cancel-training-registration-modal></cancel-training-registration-modal>

    <div v-if="isTraineeOrAdminAsTrainee" class="separator-line separator-margin"></div>
    <b-row v-if="isTraineeOrAdminAsTrainee">
      <b-col cols="12">
        <div class="text24-blue">
          {{ t("ENDED_GROUPS") }}
        </div>
      </b-col>
      <b-col class="mt-3" cols="12">
        <div class="groups-table">
          <user-table :items="userArchivedGroups"></user-table>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script setup>
import EventBus from "@/util/eventBus";
import "vue-select/dist/vue-select.css";
import availableGroupsTable from "@/views/components/tables/administrativeGroupsTable.vue";
import userTable from "@/views/components/tables/userTable.vue";
import {computed, inject, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import languageModal from "@/views/components/modals/languageModal.vue";
import CancelTrainingRegistrationModal from "@/views/components/modals/cancelTrainingRegistrationModal.vue";
import ConfirmRegistration from "@/views/components/modals/confirmRegistration.vue";
import {useRouter, useRoute} from "vue-router";
import {Role} from "@/auth/oauth";

const auth = inject("auth");
const axios = inject("axios");
const {t} = useI18n();
const route = useRoute();
const router = useRouter();

const isEligible = ref(false);
const keyword = ref("");
const searchStatus = ref("PUBLIC");
const isModalVisible = ref(false);
const eligibleStatus = ref("");
const emptyTableText = ref(t("NO_RESULTS"));
const title = ref("");
const registeredGroups = ref([]);
const availableGroups = ref([]);
const traineeRegisteredGroups = ref([]);
const traineeAvailableGroups = ref([]);
const availableGroupsForStudent = ref([]);
const studentId = ref(route.params.studentId || null);

const isAdmin = computed(() => {
  return (
      auth.isAuthenticated() &&
      (auth.hasRole("ADMIN") ||
          auth.hasRole("SCHOOL_MANAGER") ||
          auth.hasRole("TRAINER") ||
          auth.hasRole("VIEWER"))
  );
});

const isTrainee = computed(() => {
  return auth.isAuthenticated() && auth.hasRole("TRAINEE");
});

const isAdminAsTrainee = computed(() => {
  return isAdmin.value && studentId.value;
});

const isTraineeOrAdminAsTrainee = computed(() => {
  return isTrainee.value || isAdminAsTrainee.value;
});

const statusOptions = computed(() => {
  return [
    {value: "ARCHIVED", text: t("ARCHIVED")},
    {value: "DRAFT", text: t("DRAFT")},
    {value: "PRIVATE", text: t("PRIVATE")},
    {value: "PUBLIC", text: t("PUBLIC")},
    {value: "ALL_GROUP_STATUSES", text: t("ALL_GROUP_STATUSES")},
  ];
});

function fetchGroups() {
  if (studentId.value) {
    // Administrator views student groups
    title.value = `Группы студента ${studentId.value}`;
    fetchStudentGroups(studentId.value);
    getAvailableGroupsForStudent(studentId.value);
  } else if (auth.hasRole(Role.TRAINEE)) {
    // Trainee views their own groups
    title.value = "Все группы";
    getRegisteredGroups();
    getAvailableGroups();
  }
}

function handleRegistrationSuccess() {
  getRegisteredGroups();
  getAvailableGroups();
}

async function fetchStudentGroups(sId) {
  try {
    const resp = await axios.get(`/account/student_groups/${sId}`);
    if (resp.data) {
      console.log(resp.data);
      traineeRegisteredGroups.value = resp.data;
      traineeAvailableGroups.value = resp.data;
    }
  } catch (e) {
    console.error(e);
  }
}

const userActiveGroups = computed(() => {
  const groupsList = isAdminAsTrainee.value
      ? traineeRegisteredGroups.value
      : registeredGroups.value;
  return groupsList
      ? groupsList.filter((group) => group.status !== "ARCHIVED")
      : [];
});

const userArchivedGroups = computed(() => {
  const groupsList = isAdminAsTrainee.value
      ? traineeRegisteredGroups.value
      : registeredGroups.value;
  return groupsList
      ? groupsList.filter((group) => group.status === "ARCHIVED")
      : [];
});

const availableGroupsToDisplay = computed(() => {
  return isAdminAsTrainee.value
      ? availableGroupsForStudent.value
      : availableGroups.value;
});

onMounted(() => {
  fetchGroups();
  EventBus.$on("registrationSuccess", handleRegistrationSuccess);
  EventBus.$on("cancalationSuccess", handleRegistrationSuccess);
});

onBeforeUnmount(() => {
  EventBus.$off("registrationSuccess", handleRegistrationSuccess);
  EventBus.$off("cancalationSuccess", handleRegistrationSuccess);
});

watch(
    () => route.params.studentId,
    (newStudentId) => {
      studentId.value = newStudentId || null;
      fetchGroups();
    }
);

const getRegisteredGroups = async () => {
  try {
    const resp = await axios.get("/account/groups");
    if (resp.data) {
      registeredGroups.value = resp.data;
    }
  } catch (e) {
    console.error(e);
  }
};

const getAvailableGroups = async () => {
  try {
    const resp = await axios.get("/training/groups/foreknown");
    if (resp.data) {
      isEligible.value = resp.data.eligible;
      if (!isEligible.value) {
        eligibleStatus.value = "NOT_ELIGIBLE";
        emptyTableText.value = "NOT_ELIGIBLE";
        availableGroups.value = null;
      } else {
        showLanguageModalIfNecessary();
        eligibleStatus.value = "ELIGIBLE";
        emptyTableText.value = t("NO_RESULTS");
        availableGroups.value = resp.data.groups;
      }
    }
  } catch (e) {
    console.error(e);
  }
};


const getAvailableGroupsForStudent = async (studentId) => {
  try {
    const resp = await axios.get(`/training/groups/foreknown_student/${studentId}`);
    if (!resp.data) {
      console.warn("No response data available for the student.");
      return;
    }

    isEligible.value = resp.data.eligible;
    console.log('getAvailableGroupsForStudent ', isEligible.value)
    availableGroupsForStudent.value = isEligible.value ? resp.data.groups : null;
    if (isEligible.value) {
      eligibleStatus.value = "ELIGIBLE";

    } else {
      eligibleStatus.value = "NOT_ELIGIBLE";
    }
    emptyTableText.value = t("NO_RESULTS");
  } catch (e) {
    console.error("Error fetching available groups for student:", e);
  }
};


const doSearch = () => {
  EventBus.$emit("GROUP_SEARCH");
};

const canShowLanguageModalToUser = () => {
  return isTrainee.value && (auth.getRVK() || auth.getTemp()) && isEligible.value;
};

const showLanguageModalIfNecessary = () => {
  if (!canShowLanguageModalToUser()) {
    return;
  }

  if (!["/login", "/oauth"].includes(router.options.history.state?.back?.split("?")[0])) {
    return;
  }

  const maxModalShowCount = 5;
  const modalShowCountStorageKey = "language-modal-show-count__" + auth.getAcc();

  let modalShowCount = Number.parseInt(window.localStorage.getItem(modalShowCountStorageKey) || "0");
  if (Number.isNaN(modalShowCount)) {
    modalShowCount = 0;
  }

  if (modalShowCount >= maxModalShowCount) {
    return;
  }

  window.localStorage.setItem(modalShowCountStorageKey, `${modalShowCount + 1}`);
  isModalVisible.value = true;
};
</script>

<style lang="less" scoped>
.groups-table {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: left;
}

.form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background-color: white;
  border-bottom-color: #f0f1f2;
  border-bottom-width: 2px;
}

.status {
  background-color: white;
  border-bottom-color: #f0f1f2;
  border-bottom-width: 2px;
}

.separator-margin {
  margin-top: 48px;
  margin-bottom: 48px;
}
</style>
