<template>
  <b-row>
    <b-col
      cols="12"
      xl="4"
      :order="pictureLeft ? 0 : 1"
      :class="{ 'text-right': !pictureLeft }"
    >
      <div v-if="data.image">
        <img :src="data.image.image" alt="image" />
        <img
          v-if="data.image.mask"
          class="background d-none d-xl-block"
          :style="data.image.mask.style"
          :src="data.image.mask.src"
          alt="image"
        />
      </div>
      <div
        v-if="data.linkBox"
        class="mt-3 px-3 pb-3 no-gutters"
        :class="getLinkBoxBackground()"
      >
        <text-block
          :content-id="getIdPrefix() + 'LinkBox'"
          v-on:save="save"
          :content="data.linkBox"
        ></text-block>
      </div>
    </b-col>
    <b-col cols="12" lg="8" :order="pictureLeft ? 1 : 0">

      <text-block
        :content-id="getIdPrefix() + 'Text1'"
        v-on:save="save"
        :content="data.text1"
      >
      </text-block>

      <collapse-list
        v-if="data.expandable"
        :page="data.page"
        :content-id="getIdPrefix()"
        :list="data.expandable"
      ></collapse-list>
      <text-block
        class="mt-3"
        :content-id="getIdPrefix() + 'Text2'"
        v-on:save="save"
        :content="data.text2"
      >
      </text-block>
    </b-col>
  </b-row>
</template>

<style lang="scss">
#link-container {
  background: $color-gray-light;
}

.border-bottom {
  border-bottom: $separator-line;
}

.collapsed > div > .opened,
:not(.collapsed) > div > .closed {
  display: none;
}

.background {
  z-index: -1;
}

.link-box-background-white {
  background: $color-white;
}

.link-box-background-blue {
  background: $color-blue;
}

.link-box-background-gray {
  background: $color-gray-light;
}
</style>
<script setup>
import TextBlock from "@/views/components/TextBlock.vue";

import collapseList from "@/views/components/collapseList.vue";
import {inject, toRefs} from "vue";
import { useI18n } from "vue-i18n";
import { usePageContent } from "@/mixins/pageContentMixin2";

const axios = inject("axios");
const props = defineProps([
  "pictureLeft",
  "data",
  "linkBoxVariant",
  "title",

]);
const { pictureLeft, data, linkBoxVariant, title, white, blue } = toRefs(props);
const { savePageContent } = usePageContent();
const { t, locale } = useI18n();

function getIdPrefix(){
  return data.value.element;
}

function getLinkBoxBackground() {
  switch (linkBoxVariant.value) {
    case "white":
      return "link-box-background-white";
    case "blue":
      return "link-box-background-blue";
    default:
      return "link-box-background-gray";
  }
}
const save = (elem) => {
  savePageContent(
    axios,
    data.value.page,
    locale.value,
    elem.element,
    elem.value,
  );
};
</script>
