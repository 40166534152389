<template>
  <b-modal id="confirm-modal" ref="confirmModalRef" @ok="callback">
    <template v-slot:modal-title>
      {{ t("CONFIRM_TITLE") }}
    </template>
    <template v-slot:modal-ok>
      {{ t("CONFIRM") }}
    </template>
    <template v-slot:modal-cancel>
      {{ t("CANCEL") }}
    </template>
    {{ msg }}
  </b-modal>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import EventBus from "@/util/eventBus";

const { t } = useI18n();
const emits = defineEmits(['confirm-modal'])
const msg = ref("");
const callback = ref(() => {});
const confirmModalRef = ref(null)

onMounted(() => {
  EventBus.$on("show-confirmation-modal", showConfirmationModal);
});
onBeforeUnmount(() => {
  EventBus.$off("show-confirmation-modal", showConfirmationModal);
});
const showConfirmationModal = (data) => {
  confirmModalRef.value.show("confirm-modal")
   msg.value = data.msg;
  callback.value = data.callback;

};
</script>
