<template>
  <b-container class="section-p70">
    <b-row>
      <b-col cols="auto">
        <h2 class="title-blue">{{ t("SEARCH_QUERY") }}</h2>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-col>
          <loader :active="loaderActive" :message="t('PLEASE_WAIT')" />
        </b-col>
        <form class="col-4" @submit.prevent="search">
          <div class="row">
            <span style="display: inline-block">
              <b-input
                  id="keyword"
                  v-model="searchTerm"
                  @input="handleSearchInput"
                  :placeholder="t('SEARCH')"
                  type="text"
              ></b-input>
            </span>
            <span style="display: inline-block">
              <b-button type="submit" variant="white">
                <img :src="iconSpyglass" alt="Search icon" />
              </b-button>
            </span>
          </div>
        </form>
      </b-col>
    </b-row>
    <div class="separator-line mt-3"></div>
    <div class="row">
      <div class="col">
        <b-table
            no-local-sorting
            id="search-table"
            :borderless="true"
            :empty-text="t('NO_RESULTS')"
            :fields="fields"
            :items="items"
            responsive
            show-empty
            @head-clicked="sortTable"
            striped
        >
          <template v-slot:cell(name)="{ item }">
            <div>
              {{ item.firstName }} {{ item.lastName }}
              <b-badge v-if="item.isArchived" variant="warning">{{
                  t("ARCHIVED")
                }}</b-badge>
            </div>
            <span></span>
          </template>
          <template v-slot:cell(view)="{ item }">
            <div>
              <b-button
                  :disabled="item.isArchived === true && !isAdminOrViewer"
                  :to="{
                  name: 'Trainee',
                  params: { id: `${item.id}`, isArchived: item.isArchived },
                }"
              >
                {{ t("TRAINING_CARD") }}
              </b-button>
            </div>

          </template>
          <template v-slot:cell(see)="{ item }">
            <div>
              <b-button
                  :disabled="item.role !== 'TRAINEE'"
                  :to="{
                  name: 'Groups',
                  params: { studentId: `${item.id}` },
                }"
              >
                <b-icon-eye-fill></b-icon-eye-fill>
              </b-button>
            </div>

          </template>
          <template v-slot:cell(role)="{ item }">
            <div class="input-group input-group-sm">
              <b-select v-model="item.role" class="border-0 no-shadow p-1">
                <option
                    v-for="option in optionsToShow()"
                    :key="option.id"
                    :value="option.value"
                >
                  {{ option.text }}
                </option>
              </b-select>

              <b-button
                  style="border-radius: 0"
                  variant="blue"
                  @click="changeRole(item.role, item.ssn)"
              >
                {{ t("SAVE") }}
              </b-button>
            </div>
          </template>
        </b-table>
        <b-pagination
            @change="changePage"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRowsCount"
            align="center"
        >
        </b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script setup>
import iconSpyglass from "@/assets/icon/icon_spyglass.svg?inline";
import { computed, inject, nextTick, onBeforeMount, ref } from "vue";
import Loader from "@/components/Loader.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import * as $bvToast from "vue3-toastify";
import {Role, Role as $roles} from "@/auth/oauth";

const route = useRoute();
const { t, locale } = useI18n();
const auth = inject("auth");
const axios = inject("axios");
onBeforeMount(async () => {
  await search();
});

const roleOptions = () => {
  return [
    { value: $roles.TRAINEE, text: t("TRAINEE") },
    { value: $roles.TRAINER, text: t("TRAINER") },
    { value: $roles.ADMIN, text: t("ADMIN") },
    { value: $roles.CONTENT_MANAGER, text: t("CONTENT_MANAGER") },
    { value: $roles.SCHOOL_MANAGER, text: t("SCHOOL_MANAGER") },
    { value: $roles.VIEWER, text: t("VIEWER") },
    { value: $roles.DISABLED_USER, text: t("DISABLED_USER") },
  ];
};

const limitedOptions = () => {
  return [
    { value: $roles.TRAINER, text: t("TRAINER") },
    { value: $roles.TRAINEE, text: t("TRAINEE") },
  ];
};

const items = ref([]);
const searchTerm = ref("");
const perPage = ref(15);
const currentPage = ref(0);
const totalSize = ref(0);
const totalRowsCount = ref(0);
const loaderActive = ref(false);
const sortBy = ref("name");
const isDescending = ref(false);

function makeToast(deed = null, variant = null, title = null) {
  $bvToast.toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
}

const changeRole = async (role, ssn) => {
  try {
    const resp = await axios.patch("/account/role", { role: role, ssn: ssn });
    if (resp.data && resp) {
      makeToast(
          `${t("SAVED")} - ${t(resp.data.role)} ${resp.data.role}`,
          "success",
          t("OPERATION_SUCCESSFUL"),
      );
    }
  } catch (e) {
    console.log(e);
  }
};

function handleSearchInput() {
  isDescending.value = false;
  search();
}

const sortTable = (key) => {
  if (sortBy.value === key) {
    isDescending.value = !isDescending.value;
  } else {
    sortBy.value = key;
    isDescending.value = false;
  }
  search();
};

const showLoader = () => {
  loaderActive.value = true;
};

const isAdminOrViewer = () => {
  return (auth.hasRole("ADMIN") || auth.hasRole("VIEWER"));
}

const hideLoader = () => {
  loaderActive.value = false;
};

const changePage = (cur) => {
  currentPage.value = cur;
  search();
};

const search = async () => {
  showLoader();

  let roles = "";

  if (auth.hasRole(Role.ADMIN)) {
    roles = "TRAINEE,TRAINER,ADMIN,SCHOOL_MANAGER,CONTENT_MANAGER,VIEWER,DISABLED_USER";
  } else if (auth.hasRole(Role.VIEWER)) {
    roles = "TRAINEE,TRAINER";
  } else if (auth.hasSomeRole(Role.TRAINER, Role.SCHOOL_MANAGER)) {
    roles = "TRAINEE";
  }

  const queryParams = {
    fields: "SSN,NAME,EMAIL,PHONE",
    roles: roles,
    searchValue: searchTerm.value,
    page: currentPage.value - 1,
    size: perPage.value,
    currentSort: sortBy.value,
    currentDir: isDescending.value,
  };

  try {
    const resp = await axios.post("/account/search/all", {}, {params: queryParams});
    await nextTick();

    if (resp?.data) {
      items.value = resp.data.content;
      totalRowsCount.value = resp.data.totalSize;
    }
  } catch (e) {
    console.log(e);
  } finally {
    hideLoader();
  }
};


const fields = computed(() => {
  const fields = [
    { key: "name", label: t("NAME"), sortable: true },
    { key: "ssn", label: t("SSN"), sortable: true },
    { key: "email", label: t("EMAIL"), sortable: true },
    { key: "phone", label: t("PHONE"), sortable: true },
    { key: "view", label: "", sortable: false },
  ];
  if (auth.hasRole("SCHOOL_MANAGER") || auth.hasRole("ADMIN")) {
    const field = {
      key: "role",
      label: t("ROLE"),
      sortable: false,
      thStyle: { minWidth: "250px" },
    };
    fields.splice(-1, 0, field);
  }
  if (auth.hasRole("ADMIN") || auth.hasRole("VIEWER")) {
    const field = {
      key: "see",
      label: "",
      sortable: false,
    };
    fields.splice(-1, 0, field);
  }

  return fields;
});

const optionsToShow = () => {
  if (auth.hasRole("ADMIN")) {
    return roleOptions();
  } else {
    return limitedOptions();
  }
};
</script>
