<template>
  <b-container class="section-p70">
    <b-row>
      <b-col>
        <h2 class="title-blue">{{ t("MODULES") }}</h2>
      </b-col>
    </b-row>
    <b-row align-h="between">
      <b-col cols="auto">
        <b-form-checkbox switch v-model="showArchived">{{
            t("ARCHIVED")
          }}</b-form-checkbox>
      </b-col>
      <b-col cols="auto">
<!--        <b-button variant="blue" @click="showModuleCreationModal">{{-->
<!--            t("ADD")-->
<!--          }}</b-button>-->
      </b-col>
    </b-row>
    <div class="separator-line mt-3"></div>
    <div class="row">
      <div class="col">
        <b-table
            :fields="fields"
            :items="items"
            striped
            :borderless="true"
            responsive
            show-empty
            :empty-text="t('NO_RESULTS')">
          <template v-slot:cell(view)="{ item }">
            <div class="text-center">
              <b-button
                  :to="{
                  name: 'ManageModule',
                  params: { id: item.id, module: item },
                }"
              >
                <!--                              <b-button @click="redirectToManageModule(item , item.id)">-->
                {{ t("VIEW") }}
              </b-button>
            </div>
          </template>
          <template v-slot:cell(archived)="val">
            <b-form-checkbox
                v-model="val.item.archived"
                disabled
            ></b-form-checkbox>
          </template>
        </b-table>
      </div>
    </div>
    <module-creation-modal @added-module="addModule"></module-creation-modal>
  </b-container>
</template>

<script setup>
import moduleCreationModal from "@/views/components/modals/moduleCreationModal.vue";

import {computed, inject, onMounted, ref} from "vue";
import {getInstance} from "@/auth/oauth";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

const router = useRouter();
const auth = getInstance();
const {t, locale} = useI18n();
const modules = ref([]);
const showArchived = ref(false);
const axios = inject("axios");
const item = ref([]);

onMounted(async () => {
  await getData();
});

async function getData() {
  try {
    const response = await axios.get(`/training`);
    if (response.data && response) {
      modules.value = response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

const getModuleName = (value /*key, item*/) => {
  return value[locale.value];
};

function getTypeName(value) {
  return t(value);
}

const fields = computed(() => {
  const fields = [
    { key: "code", label: t("CODE_NAME"), sortable: true },
    {
      key: "translations",
      label: t("NAME"),
      sortable: true,
      formatter: getModuleName,
    },
    {
      key: "type",
      label: t("MODULE_TYPE"),
      sortable: true,
      formatter: getTypeName,
    },
    { key: "view", label: "", sortable: false },
  ];
  if (showArchived.value) {
    const field = { key: "archived", label: t("ARCHIVED"), sortable: true };
    fields.splice(-1, 0, field);
  }
  return fields;
});
const items = computed(() => {
  return showArchived.value
    ? modules.value
    : modules.value.filter((m) => !m.archived);
});
// const isAdmin = computed(() => auth.getRoles().includes("ADMIN"));
// function addModule(module) {
//   if (isAdmin) {
//     modules.value.push(module);
//   }
// }

// const redirectToManageModule = (module, id)=>{
// router.push({
//   name: 'ManageModule',
//   params: {module, id}
// })};

// const showModuleCreationModal = () => {
//   EventBus.$emit("show-module-creation-modal", true);
// }
</script>


