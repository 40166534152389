<template>
  <div class="pl-20">
    <b-container>
      <b-row class="section-header">
        <b-col>
          <div class="title-blue">{{ t("SETTLE_PROGRAMME") }}</div>
        </b-col>
      </b-row>
      <b-row class="padding-top96">
        <b-col>
          <div class="second_title">{{ t("HOME_JUMBOTRON_HEADER") }}</div>
        </b-col>
      </b-row>
      <b-row class="padding-top96">
        <b-col>
          <text-and-picture
            :data="introduction"
            :picture-left="false"
          ></text-and-picture>
        </b-col>
      </b-row>
      <b-row class="section mx-auto" align-v="center">
        <b-col class="col-xl-4 col-12">
          <img id="rafael-image" :src="rafael.image" alt=""/>
          <img
              class="background d-none d-xl-block"
              :style="rafael.mask.style"
              :src="rafael.mask.src"
              alt=""/>
        </b-col>
        <b-col class="col-8 mx-auto mt-3 mr-4">
          <div class="text18-black">{{ t("RAFAEL_SHARES") }}</div>
          <div class="title-blue">{{ t("RAFAEL_QUOTE") }}</div>
        </b-col>
      </b-row>
    </b-container>

    <div id="section-blue" class="section">
      <b-container>
        <b-row>
          <b-col sm="auto" cols="auto">
            <text-block
              content-id="whocanjoinBlock"
              v-on:save="save"
              :content="content.elements.whocanjoinBlock"
            ></text-block>
          </b-col>
          <b-col sm="auto" cols="auto">
            <text-block
              content-id="howToRegisterBlock"
              v-on:save="save"
              :content="content.elements.howToRegisterBlock"
            ></text-block>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div id="section-modules" class="section">
      <b-container>
        <b-row>
          <b-col>
            <div class="text24-black mb-4 text-center text-md-left">
              {{ t("MODULES") }}
            </div>
          </b-col>
        </b-row>
        <b-row cols="12">
          <b-col
            class="col-10 mx-auto col-md-6 mt-3 py-2"
            v-for="entry in showModules"
            :key="entry.title"
          >
            <b-row>
              <b-col cols="4" lg="3">
                <img :src="entry.icon" />
              </b-col>
              <b-col cols="8" lg="9" class="pl-sm-0 pl-md-3 pl-xl-0">
                <b-link class="text16-blue" :to="{ name: entry.module }"
                  >{{ t(entry.title) }}
                </b-link>
                <p class="text14-black">{{ entry.desc }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
<!--    <div id="section-calendar" class="section">-->
<!--      <b-container>-->
<!--        <b-row>-->
<!--          <b-col>-->
<!--            <b-row>-->
<!--              <b-col>-->
<!--                <div class="text24-black">{{ t("CALENDAR") }}</div>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--            <b-row class="mt-3">-->
<!--              <b-col cols="12" class="text-center">-->
<!--                <month-selector-->
<!--                  v-on:month-changed="loadCalendar"-->
<!--                ></month-selector>-->
<!--              </b-col>-->
<!--              <b-col class="col-12 table-responsive">-->
<!--                <b-table-->
<!--                  :items="courses"-->
<!--                  :fields="fields"-->
<!--                  :borderless="true"-->
<!--                  :sort-by.sync="sortBy"-->
<!--                  :sort-desc.sync="sortDesc"-->
<!--                  v-if="courses.length > 0"-->
<!--                >-->
<!--                  <template v-slot:cell(signUp)>-->
<!--                    <b-btn-->
<!--                      v-if="!isAuthenticated()"-->
<!--                      variant="blue"-->
<!--                      @click="showLoginModal"-->
<!--                    >-->
<!--                      {{ t("REGISTER") }}-->
<!--                    </b-btn>-->
<!--                  </template>-->
<!--                </b-table>-->
<!--                <login-modal></login-modal>-->
<!--              </b-col>-->
<!--              <b-col cols="12" class="mt-3">-->
<!--                <b-btn>{{ t("MORE_COURSES") }}</b-btn>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-container>-->
<!--    </div>-->
    <div id="section-other" class="section">
      <b-container>
        <b-row>
          <b-col cols="6">
            <div class="text24-white">{{ t("OTHER_SOURCES") }}</div>
            <div class="text18-white">
              {{ t("TRAINING_MATERIALS") }}
              <b-link
                class="text18-light-gray"
                :href="'https://issuu.com/settleinestonia/stacks'"
                target="_blank"
                rel="noopener noreferrer"
                >{{ t("HERE") }}
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div id="section-faq" class="section">
      <b-container>
        <b-row>
          <b-col>
            <div class="text24-black">{{ t("FAQ") }}</div>
            <div
              v-for="(q, index) in showQuestions"
              :key="q.question"
              class="border-bottom no-gutters white-space"
            >
              <b-row v-b-toggle="'accordion-' + index" class="py-3">
                <b-col cols="auto">
                  <div class="text16-blue bold">{{ q.question }}</div>
                </b-col>
                <b-col cols="1" class="ml-auto text-right">
                  <b-icon
                    icon="chevron-down"
                    style="color: blue"
                    class="closed"
                  ></b-icon>
                  <b-icon
                    icon="chevron-up"
                    style="color: blue"
                    class="opened"
                  ></b-icon>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-collapse
                    :id="'accordion-' + index"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <div class="text16-black px-3 pb-3">{{ q.answer }}</div>
                  </b-collapse>
                </b-col>
              </b-row>
            </div>

            <b-button variant="blue" class="mt-3" @click="showMore = !showMore">
              <span v-if="showMore">{{ t("SHOW_LESS") }}</span>
              <span v-else>{{ t("SHOW_MORE") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script setup>

import {computed, inject, onBeforeMount, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {useRouter} from "vue-router";
import {usePageContent} from "@/mixins/pageContentMixin2";
import moment from "moment-timezone";

import TextBlock from "@/views/components/TextBlock.vue";
import TextAndPicture from "@/views/components/TextAndPicture.vue";
import MonthSelector from "@/views/components/MonthSelector.vue";
import LoginModal from "@/views/components/modals/loginModal.vue";


import programme_classroom from "@/assets/programme_classroom.svg";
import programme_classroom_background from "@/assets/programme_classroom_background.svg";
import icon_world from "@/assets/icon/icon_world.svg";
import icon_shield from "@/assets/icon/icon_shield.svg";
import icon_book from "@/assets/icon/icon_book.svg";
import icon_briefcase from "@/assets/icon/icon_briefcase.svg";
import icon_family from "@/assets/icon/icon_family.svg";
import icon_research from "@/assets/icon/icon_research.svg";
import icon_flag from "@/assets/icon/icon_flag.svg";
import icon_children from "@/assets/icon/icon_children.svg";
import programme_rafael from "@/assets/programme_rafael.svg";
import programme_rafael_background from "@/assets/programme_rafael_background.svg";

const {getPageContent, savePageContent} = usePageContent();
const axios = inject("axios");
const {t, locale, tm} = useI18n();
const content = ref({elements: {}});
const pageName = "programme";
const auth = inject("auth");
const router = useRouter();

const sortBy = ref("dates");
const sortDesc = false;
const courses = ref([]);
const showMore = ref(false);
const section = ref("");
const rafael = ref({
  image: programme_rafael,
  mask: {
    src: programme_rafael_background,
    style: "position: absolute; left: -167px; top: -145px;",
  },
});
const learningSources = [
  {title: "Õppematerjali nimetus", href: "", desc: "Lühikirjeldus"},
  {title: "Õppematerjali nimetus2", href: "", desc: "Lühikirjeldus"},
  {title: "Õppematerjali nimetus3", href: "", desc: "Lühikirjeldus"},
  {title: "Õppematerjali nimetus4", href: "", desc: "Lühikirjeldus"},
  {title: "Õppematerjali nimetus5", href: "", desc: "Lühikirjeldus"},
];
onBeforeMount(() => {
  EventBus.$on("locale", loadPageContent);
  loadPageContent();
});
onMounted(() => {
  section.value = router.currentRoute.value.hash.replace("#", "");
  if (section.value) {
    window.document.getElementById(section.value).scrollIntoView();
  }
});
onBeforeUnmount(() => {
  EventBus.$off("locale", loadPageContent);
});

async function loadPageContent() {
  content.value = await getPageContent(axios, pageName, locale.value);
}

const save = (elem) => {
  savePageContent(axios, pageName, locale.value, elem.element, elem.value);
};

const isAuthenticated = () => {
  return auth.isAuthenticated();
};


const introduction = computed(() => {
  return {
    page: pageName,
    element: "introduction",
    image: {
      image: programme_classroom,
      mask: {
        src: programme_classroom_background,
        style: "position: absolute; right: -417px; top: -141px;",
      },
    },
    text1: content.value ? content.value.elements.introductionText1 : "",
    text2: content.value ? content.value.elements.introductionText2 : "",
  };
});
const fields = computed(() => {
  return [
    {
      key: "training.translations",
      label: t("COURSE"),
      sortable: true,
      formatter: getModuleName,
    },
    {
      key: "dates",
      label: t("DATES"),
      sortable: true,
      sortByFormatted: true,
      formatter: getGroupDates,
    },
    {
      key: "weekdays",
      label: t("WEEKDAYS"),
      sortable: true,
      sortByFormatted: true,
      formatter: getGroupWeekdays,
    },
    {
      key: "time",
      label: t("TIME_ESTONIAN"),
      sortable: true,
      sortByFormatted: true,
      formatter: getGroupTime,
    },
    {
      key: "language",
      label: t("LANGUAGE"),
      sortable: true,
    },
    {
      key: "location",
      label: t("LOCATION"),
      sortable: true,
    },
    {
      key: "signUp",
      label: "",
      thClass: "table-th",
      class: "table-btn-cell",
      sortable: false,
    },
  ];
});
const showQuestions = computed(() => {
  return getQuestions();
});

const showModules = computed(() => {
  return getModuleDescription();
});

const showLoginModal = () => {
  EventBus.$emit("show-login-modal");
};

const loadCalendar = async (date) => {
  try {
    const queryStr = "month=" + date.month + "&year=" + date.year;
    const resp = await axios.get("/training/groups/upcoming?" + queryStr);
    if (resp && resp.data) {
      courses.value = resp.data;
      return courses.value;
    }
  } catch (e) {
    console.log(e);
  }
};
const getGroupDates = (value, key, item) => {
  if (!item.events || item.events.length === 0) {
    return "";
  }
  if (item.events.length === 1) {
    return moment(item.events[0].startTime).format("DD.MM");
  }

  const first = item.events[0];
  const last = item.events[item.events.length - 1];

  return `${moment(first.startTime).format("DD.MM")} - ${moment(
    last.endTime,
  ).format("DD.MM")}`;
};

const getGroupWeekdays = (value, key, item) => {
  if (!item.events || item.events.length === 0) {
    return "";
  }

  const days = [
    t("MONDAY_SHORT"),
    t("TUESDAY_SHORT"),
    t("WEDNESDAY_SHORT"),
    t("THURSDAY_SHORT"),
    t("FRIDAY_SHORT"),
    t("SATURDAY_SHORT"),
    t("SUNDAY_SHORT"),
  ];

  const exists = [0, 0, 0, 0, 0, 0, 0];
  item.events.forEach((e) => {
    exists[moment(e.startTime).isoWeekday() - 1] = 1;
  });
  const strList = [];
  exists.forEach((value, index) => {
    if (value === 1) {
      strList.push(days[index]);
    }
  });
  return strList.join(", ");
};
const getGroupTime = (value, key, item) => {
  if (!item.events || item.events.length === 0) {
    return "";
  }
  const event = item.events[0];
  return `${moment(event.startTime).format("HH:mm")} - ${moment(
    event.endTime,
  ).format("HH:mm")}`;
};
const getModuleName = (value /*key, item*/) => {
  return value[locale.value];
};

const getQuestions = () => {
  const questions = tm("FAQ_QUESTIONS");
  console.log(questions);
  if (showMore.value) {
    return questions;
  }
  return questions.slice(0, 5);
};
const getModuleDescription = () => {
  return [
    {
      title: "BASIC_MODULE",
      icon: icon_world,
      desc: t("BASIC_MODULE_DESCRIPTION"),
      module: "BasicModule",
    },
    {
      title: "PROTECTION_MODULE",
      icon: icon_shield,
      desc: t("PROTECTION_MODULE_DESCRIPTION"),
      module: "ProtectionModule",
    },
    {
      title: "STUDY_MODULE",
      icon: icon_book,
      desc: t("STUDY_MODULE_DESCRIPTION"),
      module: "StudyModule",
    },
    {
      title: "WORK_MODULE",
      icon: icon_briefcase,
      desc: t("WORK_MODULE_DESCRIPTION"),
      module: "WorkModule",
    },
    {
      title: "FAMILY_MODULE",
      icon: icon_family,
      desc: t("FAMILY_MODULE_DESCRIPTION"),
      module: "FamilyModule",
    },
    {
      title: "ENTREPRENEURSHIP_MODULE",
      icon: icon_children,
      desc: t("ENTREPRENEURSHIP_MODULE_DESCRIPTION"),
      module: "EntrepreneurshipModule",
    },
    {
      title: "RESEARCH_MODULE",
      icon: icon_research,
      desc: t("RESEARCH_MODULE_DESCRIPTION"),
      module: "ResearchModule",
    },
    {
      title: "TEMP_MODULE",
      icon: icon_family,
      desc: t("TEMP_MODULE_DESCRIPTION"),
      module: "TemporaryProtectionModule",
    },
    {
      title: "LANG_MODULE",
      icon: icon_flag,
      desc: t("LANG_MODULE_DESCRIPTION"),
      module: "LanguageModule",
    },
    {
      title: "TEMP_LANG_MODULE",
      icon: icon_flag,
      desc: t("TEMP_LANG_MODULE_DESCRIPTION"),
      module: "TemporaryProtectionLangModule",
    },
  ];
};
</script>

<style lang="scss" scoped>
#section-blue {
  background:
    linear-gradient(
      90deg,
      rgba(0, 0, 150, 0.98) 35.15%,
      rgba(0, 0, 240, 0.55) 99.98%
    ),
    url("@/assets/programme_cafe.jpg") no-repeat center;
  background-size: cover;
}

#section-modules {
  background-color: $color-gray-light;
}

#section-other {
  background:
    linear-gradient(90deg, #3c0078 0%, rgba(60, 0, 120, 0.35) 100%),
    url("@/assets/programme_writing.jpg") no-repeat right top;
  background-size: cover;
  padding-left: 20px;
}

:deep(.table-btn-cell) {
  max-width: 100px;
  text-align: right;
}

.padding-top96 {
  padding-top: 96px;
  padding-left: 20px;
}

.white-space {
  white-space: pre-line;
}

.second_title {
  font-size: 25px;
  line-height: 2;
  color: $color-blue;
  text-transform: capitalize;
  font-family: "Aino Headline";
}
</style>
