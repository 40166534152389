<template>
  <b-modal
    size="xl"
    id="registration-modal"
    ref="registrationModalRef"
    @ok="handleOk"
    header-class="p-2 border-0 text18-black"
    footer-class="p-2 border-0"
    content-class="p-2 rounded-0"
    body-class="p-2 border-0"
    role="dialog"
    :title="t('CONFIRM_REGISTRATION')"
  >
    <b-row>
      <div class="col">
        <b-card v-if="groupData" class="h-50">
          <b-card-title
            >{{ groupData.title }} -
            {{
              groupData.training.translations[locale]
            }}</b-card-title
          >
          <b-card-body>
            <b-row class="align-items-stretch">
              <div class="col-4">
                <p class="font-weight-bold">{{ t("LOCATION") }}:</p>
                <p class="font-weight-bold" v-if="groupData.address">
                  {{ t("ADDRESS") }}:
                </p>
                <p class="font-weight-bold">{{ t("LANGUAGE") }}:</p>
                <p class="font-weight-bold">{{ t("COURSE") }}:</p>
                <p class="font-weight-bold">{{ t("TIME_ESTONIAN") }}:</p>
                <p
                  class="font-weight-bold"
                  v-if="weekdayNames(groupData.events).length > 1"
                >
                  {{ t("DAYS") }}:
                </p>
              </div>
              <div class="col">
                <p>{{ groupData.location }}</p>
                <p>{{ groupData.address }}</p>
                <p>{{ groupData.language }}</p>
                <p>
                  <span v-if="groupData.events.length > 1">
                    {{ dateFormat(groupData.events[0].startTime) }} -
                    {{
                      dateFormat(
                        groupData.events[groupData.events.length - 1]
                          .startTime,
                      )
                    }}</span
                  >
                  <span v-else>{{
                    dateFormat(groupData.events[0].startTime)
                  }}</span>
                </p>
                <p>
                  {{ timeFormat(groupData.events[0].startTime) }} -
                  {{ timeFormat(groupData.events[0].endTime) }}
                </p>
                <ul v-if="weekdayNames(groupData.events).length > 1">
                  <li
                    v-for="wd in weekdayNames(groupData.events)"
                    v-bind:key="wd"
                  >
                    {{ t(wd.toUpperCase()) }}
                  </li>
                </ul>
              </div>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card class="h-50">
          <b-card-title style="padding-bottom: 20px">{{
              t("EXPECTATIONS")
            }}
          </b-card-title>
          <b-textarea
              class="textarea"
              no-resize
              id="textarea"
              v-model="expectations.expectations"
              rows="10"
              max-rows="10"
              :placeholder="t('WRITE_STH')"
          ></b-textarea>
        </b-card>
      </div>
      <div class="col">
        <profile-form ref="profileFormRef" class="h-100" @updateField="handleFieldUpdate" @update:validity="isValid = $event" @registrationSuccess="handleRegistrationSuccess"></profile-form>
      </div>
    </b-row>
    <template v-slot:modal-footer>
      <div class="w-100">
        <div id="modal-buttons" class="btn-group" style="float: right">
          <b-button class="btn btn-outline-success btn-lg" type="submit" :disabled="!isValid" @click="handleOk">
            <b-icon-forward-fill></b-icon-forward-fill>
            {{ t("REGISTER") }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import moment from "moment-timezone";
import ProfileForm from "../forms/ProfileForm.vue";
import EventBus from "@/util/eventBus";
import {inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useDateFormat} from "@/mixins/dateFormatMixin2.ts";
import useTimeFormat from "@/mixins/timeFormatMixin2";
import useWeekdayFormat from "@/mixins/weekdayMixin2";
import {toast} from "vue3-toastify";


const {t, locale} = useI18n();
const axios = inject("axios");
const {dateFormat} = useDateFormat();
const {timeFormat} = useTimeFormat();
const {niceWeekday} = useWeekdayFormat();
const registrationModalRef = ref(null)
const viewData = ref({
  firstName: '',
  lastName: '',
  ssn: '',
  email: '',
  phone: '',
  dialCode: '',
  address: '',
  education: '',
  details: '',
  origin: '',
  isArchived: false
});
const isValid = ref(false);
const profileFormRef = ref(null);

const groupData = ref(null)

const expectations = ref({
  expectations: null
})

onMounted(() => {
  EventBus.$on("show-registration-modal", showRegistrationModal);

})
onBeforeUnmount(() => {
  EventBus.$off("show-registration-modal", showRegistrationModal);
})

function showRegistrationModal(group) {
  groupData.value = group;
  registrationModalRef.value.show();
}
function handleFieldUpdate({ field, value }) {
  viewData.value[field] = value;
}

async function handleOk() {
  if (isValid.value && profileFormRef.value) {
    await profileFormRef.value.updateAccount();
    registrationModalRef.value.hide();
    await groupRegister();
  } else {
    makeToast(t("ERRORS.FORM_INVALID"), "danger", t("ATTENTION"));
  }
}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    title: title,
    variant: variant,
    autoHideDelay: 5000,
  });
};

async function groupRegister() {
  const group = groupData.value.id;
  try {
      const resp = await axios.post(
          `/training/group/${group}/register`, expectations.value,
      );
      if (resp && resp.data) {
        EventBus.$emit("FETCH_REGISTERED");
        EventBus.$emit("FETCH_AVAILABLE");
        EventBus.$emit('registrationSuccess');
        expectations.expectations = null;
        makeToast(t("REGISTERED"), "success", t("OPERATION_SUCCESSFUL"));
        registrationModalRef.value.hide("registration-modal");
      }
    } catch (e) {
      console.log(e);
    }
}

function weekdayNames(events) {
  const days = [];
  events.slice(0, 6).map((i) => {
    const date = new Date(i.startTime);
    const dt = moment(date, "YYYY-MM-DD");
    days.push(dt.format("dddd"));
  });
  return days.filter(function (x, i, a) {
    return a.indexOf(x) === i;
  });
}

</script>
