<template>
  <div>
    <b-container>
      <b-row class="section-header">
        <b-col>
          <div class="title-blue">{{ t("MOVING_TO_ESTONIA") }}</div>
        </b-col>
      </b-row>
      <b-row class="section">
        <b-col
          cols="6"
          class="py-4"
          sm="4"
          v-for="(entry, index) in iconLinks"
          :key="entry.title"
          :class="{ 'mt-0': index > 2 }"
        >
          <b-row>
            <b-col>
              <img
                class="pb-4"
                :src="entry.icon"
                alt="icon Links for Training Module description"
              />
            </b-col>
            <b-col>
              <div class="text24-black">{{ entry.title }}</div>
              <div v-for="link in entry.links" :key="link">
                <a :href="link" class="text16-blue">{{
                  link.slice(8, getPosition(link, "/", 3))
                }}</a>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <div
        class="section"
        style="background-color: #f0f1f2; background-size: cover;"
    >
      <b-container>
        <b-row>
          <b-col>
            <text-and-picture
              :picture-left="true"
              :data="lawBlock"
              link-box-variant="blue"
            ></text-and-picture>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-row class="section">
        <b-col>
          <text-and-picture
            class="mw-100"
            :picture-left="false"
            :data="firstResponsibilitiesBlock"
          ></text-and-picture>
        </b-col>
      </b-row>
    </b-container>
    <div
      class="section"
      style="background-color: #d2c3d4; background-size: cover"
    >
      <b-container>
        <b-row>
          <b-col>
            <text-and-picture
              :picture-left="false"
              :data="authoritiesBlock"
            ></text-and-picture>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <text-and-picture
              :picture-left="true"
              :data="eResidencyBlock"
            ></text-and-picture>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="section">
      <b-row>
        <b-col>
          <b-collapse id="infoCollapse">
            <separator class="mt-4" />
          </b-collapse>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <text-block
              content-id="servicesBlock"
              v-on:save="save"
              :content="content.elements.servicesBlock"
          ></text-block>
        </b-col>
        <b-col cols="6">
          <text-block
              content-id="helpBlock"
              v-on:save="save"
              :content="content.elements.helpBlock"
          ></text-block>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script setup>

import {computed, inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";
import {usePageContent} from "@/mixins/pageContentMixin2";
import TextBlock from "@/views/components/TextBlock.vue";
import TextAndPicture from "@/views/components/TextAndPicture.vue";
import Separator from "@/views/components/Separator.vue";
import firstResponsibilitiesBlockIcon from "@/assets/icon/icon_apply.svg";
import researchInEstoniaIcon from "@/assets/icon/icon_research.svg";
import businessIcon from "@/assets/icon/icon_briefcase.svg";
import lawBlockIcon from "@/assets/icon/icon_court.svg";
import authoritiesBlockIcon from "@/assets/icon/icon_temple.svg";
import eResidencyBlockIcon from "@/assets/icon/icon_canadian.svg";
import studyInEstoniaIcon from "@/assets/icon/icon_book.svg";
import familyMemberIcon from "@/assets/icon/icon_family.svg";
import returningIcon from "@/assets/icon/icon_return.svg";
import otherCitizenIcon from "@/assets/icon/icon_eu.svg";

const { getPageContent, savePageContent } = usePageContent();
const axios = inject("axios");
const { t, locale } = useI18n();
const content = ref({ elements: {} });
const pageName = "movingToEstonia";

onMounted(async () => {
  EventBus.$on("locale", loadPageContent);
  await loadPageContent();
});
onBeforeUnmount(() => {
  EventBus.$off("locale", loadPageContent);
});

async function loadPageContent() {
  content.value = await getPageContent(axios, pageName, locale.value);
}
const save = (elem) => {
  savePageContent(axios, pageName, locale.value, elem.element, elem.value);
};

const lawBlock = computed(() => {
  return {
    page: pageName,
    element: "lawblock",
    image: {image: lawBlockIcon},
    linkBox: content.value ? content.value.elements.lawblockLinkBox : "",
    text1: content.value ? content.value.elements.lawblockText1 : "",
    text2: content.value ? content.value.elements.lawblockText2 : "",
  };
});

const firstResponsibilitiesBlock = computed(() => {
  return {
    page: pageName,
    element: "firstResponsibilitiesBlock",
    image: { image: firstResponsibilitiesBlockIcon },
    text1: content.value
      ? content.value.elements.firstResponsibilitiesBlockText1
      : "",
    text2: content.value
      ? content.value.elements.firstResponsibilitiesBlockText2
      : "",
  };
});

const authoritiesBlock = computed(() => {
  return {
    page: pageName,
    element: "authoritiesBlock",
    image: { image: authoritiesBlockIcon },
    text1: content.value ? content.value.elements.authoritiesBlockText1 : "",
    text2: content.value ? content.value.elements.authoritiesBlockText2 : "",
  };
});

const eResidencyBlock = computed(() => {
  return {
    page: pageName,
    element: "eResidencyBlock",
    image: { image: eResidencyBlockIcon },
    text1: content.value ? content.value.elements.eResidencyBlockText1 : "",
    text2: content.value ? content.value.elements.eResidencyBlockText2 : "",
  };
});

const iconLinks = computed(() => {
  return [
    {
      title: t("WORK_OR_BUSINESS"),
      links: ["https://www.workinestonia.com", "https://www.eas.ee/?lang=en"],
      icon: businessIcon,
    },
    {
      title: t("FOR_SCIENCE"),
      links: ["https://researchinestonia.eu"],
      icon: researchInEstoniaIcon,
    },
    {
      title: t("TO_STUDY"),
      links: ["https://www.studyinestonia.ee"],
      icon: studyInEstoniaIcon,
    },
    {
      title: t("FAMILY_MEMBER"),
      links: ["https://www.settleinestonia.ee"],
      icon: familyMemberIcon,
    },
    {
      title: t("RETURNING_TO_ESTONIA"),
      links: [
        "https://integratsioon.ee/en/return-support",
        "https://www.eesti.ee/en/citizenship-and-documents/return-to-estonia/",
      ],
      icon: returningIcon,
    },
    {
      title: t("OTHER_CITIZEN"),
      links: ["https://www.settleinestonia.ee"],
      icon: otherCitizenIcon,
    },
  ];
});

const getPosition = (str, subStr, i) => {
  return str.split(subStr, i).join(subStr).length;
};
</script>

<style lang="scss" scoped>
.collapsed > div > .opened,
:not(.collapsed) > div > .closed {
  display: none;
}
#picture {
  max-width: 100%;
}
</style>
