<template>
  <b-nav-item-dropdown id="locale-dropdown" right :text="locale">
    <b-dropdown-item-button @click="switchLocale('ENG')">eng</b-dropdown-item-button>
    <b-dropdown-item-button @click="switchLocale('EST')">est</b-dropdown-item-button>
    <b-dropdown-item-button @click="switchLocale('RUS')">rus</b-dropdown-item-button>
  </b-nav-item-dropdown>
</template>

<script setup>
import {onMounted, onUnmounted} from "vue";
import {useI18n} from "vue-i18n";
import EventBus from "@/util/eventBus";

const {t, locale} = useI18n();

onMounted(() => {
  EventBus.$on("switch-locale", switchLocale);
  if (localStorage.lang != null) {
    locale.value = localStorage.lang;
  }
});
onUnmounted(() => {
  EventBus.$off("switch-locale", switchLocale);
});
const switchLocale = (newLocale) => {
  if (locale.value !== newLocale) {
    locale.value = newLocale;
    localStorage.setItem("lang", newLocale);
    EventBus.$emit("locale", locale.value);
  }
};
</script>

<style lang="scss" scoped>
#locale-dropdown :deep(.dropdown-menu) {
  min-width: 0;
}
</style>
