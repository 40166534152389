<template>
  <b-form id="groupForm" name="groupForm" >
    <b-row>
      <b-col>
        <b-form-group label-for="groupTitle-grp">
          <label class="form-label">{{ t("GROUP_TITLE") }}*</label>
          <b-form-input
              id="groupTitle"
              name="groupTitle"
              v-model="groupForm.title"
              required
              type="text"
              class="form-control"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <label class="form-label">{{ t("GROUP_SIZE") }}*</label>
          <b-form-input
              required
              type="number"
              v-model="groupForm.groupSize"
              class="form-control"
              id="groupSize"
              min="1"
              step="1"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-if="module" label-for="moduleTrainers">
          <label class="form-label">{{ t("TRAINER") }}</label>
          <b-form-select
              v-model="groupForm.trainers"
              multiple
              :select-size="trainers.length"
              :disabled="isAdmin">
            <b-form-select-option
                v-for="opt in trainers"
                :value="opt"
                v-bind:key="opt.id">
              {{ opt.firstName }} {{ opt.lastName }}
            </b-form-select-option>
          </b-form-select>
          <ul>
            <li v-for="trainer in groupForm.trainers" v-bind:key="trainer.id">
              {{ trainer.firstName }}
              {{ trainer.lastName }}
            </li>
          </ul>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="location">
          <label class="form-label">{{ t("LOCATION") }}*</label>
          <b-form-select
              v-model="groupForm.location"
              required
              id="location"
              name="location"
              :options="showCityOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-for="address">
          <label class="form-label">{{ t("ADDRESS") }}*</label>
          <b-form-input
              v-model="groupForm.address"
              type="text"
              id="address"
              name="address"
              :disabled="groupForm.location === 'Online'"
              v-bind:class="disableAddressWhileOnline(groupForm.location)"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="language">
          <label class="form-label">{{ t("LANGUAGE") }}*</label>
          <b-form-select
              v-model="groupForm.language"
              required
              id="language"
              name="language"
              :options="getGroupLangOptions(t)"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-for="groupStatus">
          <label class="form-label">{{ t("STATUS") }}*</label>
          <b-form-select
              v-if="groupForm.location !== 'In-house'"
              v-model="groupForm.status"
              required
              id="groupStatus"
              name="groupStatus"
              :options="statusOptions"
          ></b-form-select>
          <b-form-input
              disabled
              v-else
              class="form-control"
              id="groupStatus"
              name="groupStatus"
              :placeholder="t('PRIVATE')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="event-grp" :invalid-feedback="invalidFeedback">
          <b-form-group>
            <label class="form-label">
              {{
                t(
                    module && module.type === "RECURRING" ? "START_DATE" : "DATE",
                )
              }}*
            </label>
            <b-form-datepicker
                v-model="groupForm.startDate"
                id="startDate"
                :start-weekday="1"
                :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
                required
                :hide-header="true"
                reset-button
                close-button
                value-as-date
                :min="new Date()"
                :placeholder="t('SELECT_DATE')"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group v-if="module.type === 'RECURRING'">
            <label class="form-label">{{ t("END_DATE") }}*</label>
            <b-form-datepicker
                v-model="groupForm.endDate"
                id="endDate"
                :start-weekday="1"
                :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
                required
                :hide-header="true"
                reset-button
                close-button
                value-as-date
                :disabled="!groupForm.startDate"
                :min="groupForm.startDate"
                :placeholder="t('SELECT_DATE')"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group>
            <label class="form-label">{{ t("START") }}*</label>
            <b-form-timepicker
                :placeholder="t('SELECT_TIME')"
                reset-button
                required
                v-model="groupForm.startTime"
                :hide-header="true"
                :hour12="false"
            ></b-form-timepicker>
            <label class="form-label mt-3">{{ t("END") }}*</label>
            <b-form-timepicker
                :placeholder="t('SELECT_TIME')"
                reset-button
                required
                v-model="groupForm.endTime"
                :hide-header="true"
                :hour12="false"
            ></b-form-timepicker>
          </b-form-group>
        </b-form-group>
        <b-form-group
            name="days-grp"
            id="days-grp"
            v-if="module && module.type === 'RECURRING'">
          <b-form-checkbox-group
              id="days"
              :options="weekDays"
              name="days"
              v-model="groupForm.days"
              inline
              size="sm"
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script setup>
import {computed, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import {toast} from "vue3-toastify";
import moment from "moment-timezone";
import {easter} from "date-easter";
import {getGroupLangOptions} from "@/model/group";


const requiredFormat = "YYYY-MM-DD";
const props = defineProps({module: Object, trainers: Array, isAdmin: Boolean});
defineExpose({submitForm})
const {module, trainers} = toRefs(props);
const {t, locale} = useI18n();
const emit = defineEmits(['submit-group'])

const groupForm = ref({
  title: '',
  groupSize: null,
  trainers: [],
  trainee: null,
  numberOfTrainees: 0,
  location: '',
  address: '',
  language: '',
  status: '',
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  days: [],
  trainingEvents: [],
})
const weekDays = computed(() => {
  return [
  {value: 1, text: t("MONDAY")},
  {value: 2, text: t("TUESDAY")},
  {value: 3, text: t("WEDNESDAY")},
  {value: 4, text: t("THURSDAY")},
  {value: 5, text: t("FRIDAY")},
  {value: 6, text: t("SATURDAY")},
  {value: 7, text: t("SUNDAY")},
]})

const statusOptions = [
  {value: "ARCHIVED", text: t("ARCHIVED")},
  {value: "DRAFT", text: t("DRAFT")},
  {value: "PRIVATE", text: t("PRIVATE")},
  {value: "PUBLIC", text: t("PUBLIC")},
];

const invalidFeedback = computed(() => {
  if (!groupForm.value.startDate || !groupForm.value.endDate) {
    return t("TIME_TIP");
  }
  return null;
})

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    autoHideDelay: 5000,
    variant: variant,
    title: title,
  });
}

function submitForm() {
  if (isValid()) {
    if (createGroup()) {
      emit('submit-group', groupForm.value)
    }
  }
}

function isValid() {
  const form = document.getElementById("groupForm");
  if (!form.checkValidity()) {
    form.reportValidity();
    return false;
  }
  if (
      !groupForm.value.startDate ||
      !groupForm.value.startTime ||
      !groupForm.value.endTime ||
      (props.module.type === "RECURRING" && !groupForm.value.endDate)
  ) {
    makeToast(t("EMPTY_FIELDS"), "error", t("ATTENTION"));
    return false;
  }
  return true;
}

function createGroup() {
  const timeStart = moment(groupForm.value.startTime, "HH:mm");
  const timeEnd = moment(groupForm.value.endTime, "HH:mm");
  if (timeEnd.isBefore(timeStart)) {
    makeToast(t("TIME_MISMATCH"), "error", t("ATTENTION"));
    return false;
  }

  if (props.module.type === "SINGLE") {
    const date = moment(groupForm.value.startDate);

    const startDate = moment(date).set({
      hour: timeStart.get("hour"),
      minute: timeStart.get("minute"),
    });
    const endDate = moment(date).set({
      hour: timeEnd.get("hour"),
      minute: timeEnd.get("minute"),
    });
    const dict = {
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
    };
    groupForm.value.trainingEvents.push(dict);
  }

  if (props.module.type === "RECURRING") {
    if (
        moment(groupForm.value.endDate).isBefore(
            moment(groupForm.value.startDate),
        )
    ) {
      makeToast(t("DATE_MISMATCH"), "error", t("ATTENTION"));
      return false;
    }

    if (groupForm.value.days.length < 1) {
      makeToast(t("DAYS_MISSING"), "error", t("ATTENTION"));
      return false;
    }

    const trainingDays = getTrainingDays(
        groupForm.value.startDate,
        groupForm.value.endDate,
    );
    for (const day of trainingDays) {
      const startDate = moment(day).set({
        hour: timeStart.get("hour"),
        minute: timeStart.get("minute"),
      });
      const endDate = moment(day).set({
        hour: timeEnd.get("hour"),
        minute: timeEnd.get("minute"),
      });
      const dict = {
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString(),
      };
      groupForm.value.trainingEvents.push(dict);
    }
  }

  if (groupForm.value.location === ("In-house").toString()) {
    groupForm.value.status = "PRIVATE";
  }
  return true;
}

function getTrainingDays(start, end) {
  const trainingDays = [];
  let holidays = getHolidays(start, end);

  start = moment(start);
  end = moment(end);
  while (start <= end) {
    const mDate = start.format(requiredFormat);
    if (
        !holidays.includes(mDate) &&
        groupForm.value.days.includes(start.isoWeekday())
    ) {
      trainingDays.push(start.format(requiredFormat));
    }
    start = start.add(1, "days");
  }
  return trainingDays;
}

function getHolidays(start, end) {
  let years = [];
  years.push(start.getFullYear());
  if (!years.includes(end.getFullYear())) {
    years.push(end.getFullYear());
  }

  let holidays = [];

  for (const year of years) {
    const easterDate = easter(year).toString();

    let goodFriday = moment(easterDate);
    goodFriday = goodFriday.subtract(2, "days").toDate();
    holidays.push(moment(goodFriday).format(requiredFormat));
    holidays.push(moment(easterDate).format(requiredFormat));

    holidays.push(
        moment({year: year, month: 0, day: 1}).format(requiredFormat),
    );
    holidays.push(
        moment({year: year, month: 1, day: 24}).format(requiredFormat),
    );
    holidays.push(
        moment({year: year, month: 4, day: 1}).format(requiredFormat),
    );

    let pentecost = moment(easterDate);
    pentecost = pentecost.add(49, "days").toDate();
    holidays.push(moment(pentecost).format("YYYY-MM-DD"));

    holidays.push(
        moment({year: year, month: 5, day: 23}).format(requiredFormat),
    );
    holidays.push(
        moment({year: year, month: 5, day: 24}).format(requiredFormat),
    );
    holidays.push(
        moment({year: year, month: 7, day: 20}).format(requiredFormat),
    );
    holidays.push(
        moment({year: year, month: 11, day: 24}).format(requiredFormat),
    );
    holidays.push(
        moment({year: year, month: 11, day: 25}).format(requiredFormat),
    );
    holidays.push(
        moment({year: year, month: 11, day: 26}).format(requiredFormat),
    );
  }
  return holidays;
}


function disableAddressWhileOnline(value) {
  if (!value || value === "Online") return "disabled-color";
}

const showCityOptions  = computed(() => {
  return [
    {value: "Tallinn", text: t("TALLINN")},
    {value: "Tartu", text: t("TARTU")},
    {value: "Narva", text: t("NARVA")},
    {value: "Alutaguse", text: t("ALUTAGUSE")},
    {value: "Anija", text: t("ANIJA")},
    {value: "Antsla", text: t("ANTSLA")},
    {value: "Aseri", text: t("ASERI")},
    {value: "Elva", text: t("ELVA")},
    {value: "Haapsalu", text: t("HAAPSALU")},
    {value: "Haljala", text: t("HALJALA")},
    {value: "Harku", text: t("HARKU")},
    {value: "Hiiumaa", text: t("HIIUMAA")},
    {value: "Häädemeeste", text: t("HAADEMEESTE")},
    {value: "Jõelähtme", text: t("JOELAHTME")},
    {value: "Jõgeva", text: t("JOGEVA")},
    {value: "Jõhvi", text: t("JOHVI")},
    {value: "Järva", text: t("JARVA")},
    {value: "Jüri", text: t("JURI")},
    {value: "Kadrina", text: t("KADRINA")},
    {value: "Kambja", text: t("KAMBJA")},
    {value: "Kanepi", text: t("KANEPI")},
    {value: "Kastre", text: t("KASTRE")},
    {value: "Kehtna", text: t("KEHTNA")},
    {value: "Keila", text: t("KEILA")},
    {value: "Kihnu", text: t("KIHNU")},
    {value: "Kiili", text: t("KIILI")},
    {value: "Kiviõli", text: t("KIVIOLI")},
    {value: "Kohila", text: t("KOHILA")},
    {value: "Kohtla-Järve", text: t("KOHTLA_JARVE")},
    {value: "Kose", text: t("KOSE")},
    {value: "Kunda", text: t("KUNDA")},
    {value: "Kuusalu", text: t("KUUSALU")},
    {value: "Loksa", text: t("LOKSA")},
    {value: "Luunja", text: t("LUUNJA")},
    {value: "Lääne-Nigula", text: t("LAANE_NIGULA")},
    {value: "Lääneranna", text: t("LAANERANNA")},
    {value: "Lüganuse", text: t("LUGANUSE")},
    {value: "Maardu", text: t("MAARDU")},
    {value: "Muhu", text: t("MUHU")},
    {value: "Mulgi", text: t("MULGI")},
    {value: "Mustvee", text: t("MUSTVEE")},
    {value: "Märjamaa", text: t("MARJAMAA")},
    {value: "Narva-Jõesuu", text: t("NARVA_JOESUU")},
    {value: "Nõo", text: t("NOO")},
    {value: "Otepää", text: t("OTEPAA")},
    {value: "Paide", text: t("PAIDE")},
    {value: "Paldiski", text: t("PALDISKI")},
    {value: "Peipsiääre", text: t("PEIPSIAARE")},
    {value: "Põhja-Sakala", text: t("POHJA_SAKALA")},
    {value: "Põltsamaa", text: t("POLTSAMAA")},
    {value: "Põlva", text: t("POLVA")},
    {value: "Pärnu", text: t("PARNU")},
    {value: "Raasiku", text: t("RAASIKU")},
    {value: "Rae", text: t("RAE")},
    {value: "Rakvere", text: t("RAKVERE")},
    {value: "Rapla", text: t("RAPLA")},
    {value: "Ruhnu", text: t("RUHNU")},
    {value: "Rõuge", text: t("ROUGE")},
    {value: "Räpina", text: t("RAPINA")},
    {value: "Saarde", text: t("SAARDE")},
    {value: "Saaremaa ", text: t("SAAREMAA")},
    {value: "Saku", text: t("SAKU")},
    {value: "Saue", text: t("SAUE")},
    {value: "Setomaa", text: t("SETOMAA")},
    {value: "Sillamäe", text: t("SILLAMAE")},
    {value: "Tapa", text: t("TAPA")},
    {value: "Toila", text: t("TOILA")},
    {value: "Tori", text: t("TORI")},
    {value: "Tõrva", text: t("TORVA")},
    {value: "Türi", text: t("TURI")},
    {value: "Valga", text: t("VALGA")},
    {value: "Viimsi", text: t("VIIMSI")},
    {value: "Viljandi", text: t("VILJANDI")},
    {value: "Vinni", text: t("VINNI")},
    {value: "Viru-Nigula", text: t("VIRU_NIGULA")},
    {value: "Vormsi", text: t("VORMSI")},
    {value: "Võru", text: t("VORU")},
    {value: "Väike-Maarja", text: t("VAIKE_MAARJA")},
    {value: "Online", text: t("ONLINE")},
    {value: "In-house", text: t("IN_HOUSE")},
    {value: "Laev Isabelle", text: t("SHIP_ISABELLE")},
  ];
})
</script>

<style lang="less" scoped>
.disabled-color {
  background-color: #575a5d;
}
</style>
