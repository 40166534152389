<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="container-fluid section-p70" v-if="groupData">
        <div class="row">
            <b-col cols="auto" class="title-blue">
                {{ t("MANAGE_GROUP") }} - {{ groupData.title }}
            </b-col>
            <b-col align-self="center">
                <b-button
                    v-if="isArcButtonVisible"
                    variant="blue"
                    @click="showArchiveConfirmation"
                    :disabled="!canBeArchived() || isViewer()"
                >
                    {{ t("ARCHIVE") }}
                </b-button>
            </b-col>
            <b-col align-self="center">
                <b-button
                    v-show="isUnArcButtonVisible "
                    variant="blue"
                    @click="showUnArchiveConfirmation"
                    :disabled="isViewer() || isTrainer() || isSchoolManager()"
                >
                    {{ t("UNARCHIVE") }}
                </b-button>
            </b-col>
        </div>
        <b-row class="mt-3">
            <div class="col-xl-3">
                <b-card style="text-align: left">
                    <b-card-title>{{ t("BASE_DATA") }}</b-card-title>
                    <b-form id="groupForm" ref="form">
                        <b-form-group label-for="module">
                            <label class="form-label">{{ t("MODULE") }}</label>
                            <b-form-input
                                v-if="groupData.training"
                                id="module"
                                v-model="groupData.training.translations[locale]"
                                disabled
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="moduleTrainers">
                            <label class="form-label">{{ t("TRAINER") }}</label>
                            <v-select
                                label="lastName"
                                multiple
                                v-model="groupData.trainers"
                            />
                            <b-button
                                variant="blue"
                                @click="getModuleTrainers"
                                :disabled="!isAdminOrSchoolManager()"
                                :hidden="groupData.status === 'ARCHIVED'"
                                v-model="moduleTrainers"
                            >
                                {{ t("ADD") }}
                            </b-button>
                        </b-form-group>
                        <b-form-group>
                            <b-row class="moreTrainers">
                                <div v-for="trainer in moduleTrainers" :key="trainer.id">
                                    <b-link
                                        v-on:mouseenter="hover = true"
                                        v-on:mouseleave="hover = false"
                                        @click="addTrainerToGroup(trainer.id)"
                                    >
                                        {{ trainer.firstName + " " + trainer.lastName }}
                                    </b-link>
                                </div>
                            </b-row>
                        </b-form-group>
                        <b-form-group label-for="groupTitle">
                            <label class="form-label">{{ t("GROUP_TITLE") }}*</label>
                            <b-form-input
                                id="groupTitle"
                                name="groupTitle"
                                required
                                type="text"
                                class="form-control"
                                v-model="groupData.title"
                                :disabled="isArchived() || !isAdminOrSchoolManager()"
                            ></b-form-input>
                        </b-form-group>
                        <div class="form-group">
                            <label class="form-label" for="groupSize">{{
                                    t("GROUP_SIZE")
                                }}</label>
                            <input
                                type="number"
                                class="form-control"
                                id="groupSize"
                                min="1"
                                step="1"
                                v-model="groupData.groupSize"
                                :disabled="isArchived()"
                            />
                        </div>
                        <b-form-group label-for="location">
                            <label class="form-label">{{ t("LOCATION") }}</label>
                            <b-form-select
                                id="location"
                                :options="cityOptions"
                                v-model="groupData.location"
                                :disabled="isArchived() || !isAdminOrSchoolManager()"
                            ></b-form-select>
                        </b-form-group>
                        <b-form-group label-for="address">
                            <label class="form-label">{{ t("ADDRESS") }}*</label>
                            <b-form-input
                                type="text"
                                class="form-control"
                                id="address"
                                name="address"
                                v-model="groupData.address"
                                :disabled="isArchived() || !isAdminOrSchoolManager() || groupData.location === 'Online'"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="language">
                            <label class="form-label">{{ t("LANGUAGE") }}</label>
                            <b-form-select
                                id="language"
                                :options="getGroupLangOptions(t)"
                                v-model="groupData.language"
                                :disabled="isArchived() || !isAdminOrSchoolManager()"
                            ></b-form-select>
                        </b-form-group>
                        <b-form-group label-for="groupStatus">
                            <label class="form-label">{{ t("STATUS") }}</label>
                            <b-form-select
                                v-if="groupData.location !== 'In-house'"
                                id="groupStatus"
                                name="groupStatus"
                                :options="statusOptions"
                                v-model="groupData.status"
                                :disabled="isArchived() || !isAdminOrSchoolManager()"
                            >
                                <b-form-select-option v-if="isArchived()" value="ARCHIVED">
                                    {{ t("ARCHIVED") }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-input
                                v-else
                                id="groupStatus"
                                name="groupStatus"
                                disabled
                                :placeholder="t('PRIVATE')"
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-form>
                    <b-form-group
                        v-if="groupData.training !== 'SINGLE'"
                        label-for="conditions"
                    >
                        <div class="row">
                            <div class="col">
                                <label class="form-label">{{ t("MIN_HOURS") }}</label>
                                <b-form-input
                                    id="lessons"
                                    type="number"
                                    min="1"
                                    step="1"
                                    @input="validateLessonsNumber()"
                                    v-model="groupData.lessons"
                                    :disabled="groupData.status === 'ARCHIVED' || !isAdmin"
                                ></b-form-input>
                            </div>
                            <div class="col">
                                <label class="form-label">{{ t("MAX_HOMEWORKS") }}</label>
                                <b-form-input
                                    id="homeworks"
                                    type="number"
                                    min="0"
                                    @input="validateHWNumber()"
                                    step="1"
                                    v-model="groupData.homeworks"
                                    :disabled="groupData.status === 'ARCHIVED' || !isAdmin"
                                ></b-form-input>
                            </div>
                        </div>
                    </b-form-group>
                    <div class="text-right">
                        <b-button
                            variant="white"
                            @click="updateData()"
                            :disabled="isArchived() || !isAdminOrSchoolManager"
                            style="width: 100%"
                        >{{ t("SAVE") }}
                        </b-button>
                    </div>
                </b-card>
            </div>
            <confiramtion-modal></confiramtion-modal>
            <div class="col-xl-9">
                <b-tabs card active-nav-item-class="bold">
                    <b-tab
                        :title="t('REGISTRANTS')"
                        title-link-class="text16-black"
                        active
                    >
                        <b-row>
                            <div class="text24-blue ml-3">
                                {{ t("OCCUPANCY") }}
                                <span v-if="groupData.trainees"
                                >{{ groupData.trainees.length }} /</span>
                                <span v-else>0 / </span>
                                <span>{{ groupData.groupSize }}</span>
                            </div>
                            <b-col class="text-right">
                                <b-dropdown
                                    variant="blue"
                                    :disabled="!groupData.trainees"
                                    :text="t('PRINT')"
                                >
                                    <b-dropdown-item
                                        :disabled="!groupData.trainees"
                                        @click="createPdf()"
                                    >PDF
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="createExcel">EXCEL</b-dropdown-item>
                                </b-dropdown>
                                <b-button
                                    variant="blue"
                                    :disabled="!groupData.trainees || eligibilityCheckInProgress || isArchived()"
                                    @click="checkEligibility"
                                >{{ t("ELIGIBLE") }}
                                </b-button>
                                <b-button
                                    v-if="isAdminOrSchoolManager()"
                                    variant="blue"
                                    v-model="addTrainee"
                                    @click="toggleSearchForm"
                                    :disabled="isArchived()"
                                >{{ t("ADD") }}
                                </b-button>
                                <b-button
                                    v-if="isAdminOrSchoolManager() || isTrainer()"
                                    variant="blue"
                                    @click="showGroupNotificationModal"
                                    :disabled="isViewer()"
                                >{{ t("NOTIFICATION") }}
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form v-if="addTrainee" class="searchForm">
                                    <b-form-group label-for="search-trainees">
                                        <label class="form-label">{{ t("SEARCH_TIP2") }}</label>
                                        <b-form-input
                                            id="search-trainees"
                                            v-model="searchValue"
                                            type="text"
                                            class="active-cyan-3 active-cyan-4 mb-3 add-me"
                                            label="Search"
                                            minlength="3"
                                            v-on:input="searchTrainee"
                                        ></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        v-if="searchValue && searchValue.length >= 3"
                                        class="flex"
                                    >
                                        <div v-for="trainee in searchResult" :key="trainee.id">
                                            <b-row>
                                                <b-link
                                                    v-on:mouseenter="hover = true"
                                                    v-on:mouseleave="hover = false"
                                                    @click="submitTrainee(trainee.id)"
                                                >
                                                    {{
                                                        trainee.lastName +
                                                        " " +
                                                        trainee.firstName +
                                                        " " +
                                                        trainee.ssn
                                                    }}
                                                </b-link>
                                                <b-form-group class="space"></b-form-group>
                                                <p v-show="hover" class="italic">
                                                    {{ t("REGISTER") }}
                                                </p>
                                            </b-row>
                                        </div>
                                    </b-form-group>
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table
                                    striped
                                    :items="groupData.trainees"
                                    :fields="traineeFields"
                                    :borderless="true"
                                    class="text-small"
                                    responsive
                                >
                                    <template v-slot:cell(name)="{ item }">
                                        {{ item.trainee.firstName }} {{ item.trainee.lastName }}
                                        <span v-if="item.registrationType !== 'ID_CARD'" class="red"
                                        >*</span
                                        >
                                    </template>
                                    <template v-slot:cell(isInvitationSent)="{ item }">
                                        <b-form-checkbox
                                            size="lg"
                                            v-model="item.trainee.isInvitationSent"
                                            :disabled="isViewer()"
                                            @change="updateInvitationData(item.trainee.id, item.trainee.isInvitationSent)"
                                        >
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(isCovidProof)="{ item }">
                                        <b-form-checkbox
                                            size="lg"
                                            v-model="item.trainee.isCovidProof"
                                            :disabled="isViewer()"
                                            @change="updateCovidData(item.trainee.id, item.trainee.isCovidProof)"
                                        >
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(details)="{ item }">
                                        <b-row>
                                            <div v-if="item.expectations">
                                                <b-button
                                                    v-show="canViewGroup"
                                                    size="sm"
                                                    variant="blue"
                                                    @click="info(item)"
                                                    class="mr-1"
                                                >
                                                    {{ t("VIEW") }}
                                                </b-button>
                                            </div>
                                            <span id="trainee-card-wrapper">
                        <b-button
                            :disabled="item.trainee.isArchived"
                            :to="{
                            name: 'Trainee',
                            params: { id: `${item.trainee.id}` },
                          }"
                            class="ml-3"
                        >
                          {{ t("TRAINING_CARD") }}
                        </b-button>
                      </span>
                                            <b-tooltip
                                                v-if="item.trainee.isArchived"
                                                target="trainee-card-wrapper"
                                                triggers="hover"
                                                variant="info"
                                                placement="topright"
                                            >
                                                {{ t("ARCHIVED") }}
                                            </b-tooltip>
                                            <img
                                                v-if="isAdminOrSchoolManager() && groupData.training.type === 'RECURRING'"
                                                :src="iconTransfer"
                                                @click="showTransferTraineeModal(item)"
                                                class="ml-3 clickable"
                                                :disabled="isArchived()"
                                                alt="icon Transfer with two sided arrows"
                                            />
                                            <img
                                                v-if="isAdminOrSchoolManager()"
                                                :src="iconRemove"
                                                @click="showRemoveTraineeModal(item)"
                                                class="ml-3 clickable"
                                                :disabled="isArchived()"
                                                alt="icon Remove with X"
                                            />
                                        </b-row>
                                    </template>
                                    <b-icon-circle></b-icon-circle>
                                    >
                                    <template v-slot:cell(eligibilityResultIcon)="{item}">
                                        <div class="text-center">
                                            <b-spinner small v-if="eligibilityCheckInProgress"></b-spinner>
                                            <span v-else>
                        <b-icon-check-circle v-if="item.eligibilityResultIcon?.href === 'check-circle'"
                                             variant="success" class="align-middle"
                                             style="width: 24px; height: 24px;"></b-icon-check-circle>
                        <b-icon-x-circle v-else-if="item.eligibilityResultIcon?.href === 'x-circle'" variant="danger"
                                         class="align-middle" style="width: 24px; height: 24px;"></b-icon-x-circle>
                      </span>
                                        </div>
                                    </template>
                                    <template v-slot:table-caption>
                                        <small class="red"
                                        >* {{ t("IDENTITY_REQUIRES_VERIFICATION") }}</small
                                        >
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <b-modal
                            :id="infoModal.id"
                            :ref="infoModal.id"
                            :title="infoModal.title"
                            ok-only
                            header-text-variant="light"
                            size="lg"
                            @hide="resetInfoModal"
                        >
                            <div class="modal-body">
                                {{ infoModal.content }}
                            </div>
                        </b-modal>

                        <remove-trainee-modal
                            v-if="isAdminOrSchoolManager()"
                            v-on:remove-trainee="removeTrainee"
                        ></remove-trainee-modal>
                        <confiramtion-modal>
                        </confiramtion-modal>
                        <transfer-trainee-modal
                            :current="route.params.id"
                            v-if="isAdminOrSchoolManager()"
                            v-on:transfer-trainee="transferTrainee"
                        ></transfer-trainee-modal>
                        <group-notification-modal></group-notification-modal>
                    </b-tab>

                    <b-tab :title="t('TOR')" title-link-class="text16-black">
                        <b-card>
                            <b-list-group flush v-if="trainingEvents.length">
                                <b-list-group-item
                                    button
                                    v-for="(event, index) in trainingEvents"
                                    :key="event.id"
                                    class="py-1"
                                    style="background-color: #f0f1f2"
                                >
                                    <b-row v-b-toggle="event.id">

                                        <b-icon-gear-wide-connected></b-icon-gear-wide-connected>
                                        <div class="col">
                                            {{ t(niceWeekday(event.startTime)) }}
                                        </div>
                                        <div class="col">
                                            {{ dateFormat(event["startTime"]) }}
                                        </div>
                                        <div class="col">
                                            {{ timeFormat(event["startTime"]) }} -
                                            {{ timeFormat(event["endTime"]) }}
                                        </div>
                                    </b-row>
                                    <b-collapse v-if="!isArchived()" class="mt-2" :id="event.id">
                                        <b-form-group>
                                            <b-form-datepicker
                                                size="sm"
                                                :id="'event' + index"
                                                :start-weekday="1"
                                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                                                class="mb-2"
                                                required
                                                :hide-header="true"
                                                reset-button
                                                :initial-date="new Date(event['startTime'])"
                                                v-model="changeDate"
                                                close-button
                                                value-as-date
                                                :placeholder="t('SELECT_DATE')"
                                            ></b-form-datepicker>
                                            <b-form-timepicker
                                                size="sm"
                                                class="mb-2"
                                                reset-button
                                                required
                                                v-model="startTimeNew"
                                                :hide-header="true"
                                                :locale="locale"
                                                :hour12="false"
                                                :placeholder="t('SELECT_TIME')"
                                            ></b-form-timepicker>
                                            <b-form-timepicker
                                                size="sm"
                                                reset-button
                                                required
                                                v-model="endTimeNew"
                                                :hide-header="true"
                                                :locale="locale"
                                                :hour12="false"
                                                :placeholder="t('SELECT_TIME')"
                                            ></b-form-timepicker>
                                        </b-form-group>
                                        <span
                                            id="disabled-wrapper"
                                            class="d-inline-block"
                                            tabindex="0"
                                        >
                      <b-button
                          variant="blue"
                          @click="updateTrainingEvent(event)"
                          style="float: right"
                          :disabled="!changeDate || !startTimeNew || !endTimeNew"
                      >{{ t("SAVE") }}</b-button
                      >
                    </span>
                                        <b-tooltip
                                            target="disabled-wrapper"
                                            triggers="hover"
                                            variant="warning"
                                            placement="bottomright"
                                        >
                                            {{ t("ALL_FIELDS") }}
                                        </b-tooltip>
                                    </b-collapse>
                                </b-list-group-item>
                            </b-list-group>
                            <b-pagination
                                v-if="showPaginationForGroupEvents"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                @change="getGroupEvents"
                                aria-controls="group-events-pagination"
                                align="center"
                            />
                        </b-card>
                    </b-tab>
                    <b-tab :title="t('ATTENDANCE')" title-link-class="text16-black">
                        <abacus :group="groupData"></abacus>
                    </b-tab>
                </b-tabs>
            </div>
        </b-row>
    </div>
</template>

<script setup>

import {computed, inject, onBeforeMount, ref} from "vue";
import {useI18n} from "vue-i18n";
import eventBus from "@/util/eventBus";
import {useRoute} from "vue-router";
import {toast} from "vue3-toastify";

import JsPDF from "jspdf";
import autotable from "jspdf-autotable";
import moment from "moment";
import Abacus from "@/views/Abacus.vue";
import {PTSansFont} from "@/PTSansFontString";
import removeIcon from "@/assets/icon/icon_x.svg";
import transferIcon from "@/assets/icon/icon_transfer.svg";
import "vue-select/dist/vue-select.css";

import removeTraineeModal from "@/views/components/modals/removeTraineeModal.vue";
import transferTraineeModal from "@/views/components/modals/transferTraineeModal.vue";
import groupNotificationModal from "@/views/components/modals/groupNotificationModal.vue";
import {getGroupLangOptions} from "@/model/group";

const axios = inject("axios");
const {t, locale} = useI18n();
const auth = inject("auth");
const route = useRoute();
const props = defineProps(["group"]);
const groupData = ref(null);
const totalRows = ref(null);
const addTrainee = ref(false);
const searchValue = ref("");
const searchResult = ref([]);
const hover = ref(false);
const trainerOptions = ref(null);
const showEligibilityCheckColumn = ref(false);
const eligibilityCheckInProgress = ref(false);
const moduleTrainers = ref([]);
const invitations = ref([]);
const trainingEvents = ref([]);
const canViewGroup = ref(false);
const isArcButtonVisible = ref(false);
const isUnArcButtonVisible = ref(false);
const startTimeNew = ref(null);
const endTimeNew = ref(null);
const changeDate = ref(null);
const eligibilityData = ref([]);
const perPage = ref(15);
const currentPage = ref(1);
const iconTransfer = transferIcon;
const iconRemove = removeIcon;

const infoModal = {
  id: "info-modal",
  title: "",
  content: "",
};

const isAdmin = () => {
  return auth.hasRole("ADMIN");
};

const isAdminOrSchoolManager = () => {
  return auth.hasRole("ADMIN") || auth.hasRole("SCHOOL_MANAGER");
};

const isViewer = () => {
  return auth.hasRole("VIEWER");
};

const isSchoolManager = () => {
  return auth.hasRole("SCHOOL_MANAGER")
};

const isTrainer = () => {
  return auth.hasRole("TRAINER");
};

onBeforeMount(async () => {
  await getGroupData();
  await getGroupEvents(1);
  await getInvitationsData();
  createTrainerList();
  buttonVisibility(groupData.value.status)
});

const statusOptions = computed(() => {
  return [
    {value: "DRAFT", text: t("DRAFT")},
    {value: "PRIVATE", text: t("PRIVATE")},
    {value: "PUBLIC", text: t("PUBLIC")},
  ];
});

const traineeFields = computed(() => {
  const res = [
    { key: "name", label: t("NAME"), sortable: true },
    { key: "trainee.ssn", label: t("SSN"), sortable: true },
    { key: "trainee.phone", label: t("PHONE"), sortable: true },
    { key: "trainee.email", label: t("EMAIL"), sortable: true },
    { key: "isInvitationSent", label: t("INV_SENT"), sortable: true },
    { key: "isCovidProof", label: t("COVID_PROOF"), sortable: true },
    { key: "details", label: t("DETAILS"), sortable: false },
  ];
  if (showEligibilityCheckColumn) {
    res.push({
      key: "eligibilityResultIcon",
      label: t("ELIGIBLE"),
      sortable: false,
      thClass: "text-center",
    });
  }
  return res;
});

const cityOptions = computed(() => {
  return showCityOptions();
});

function buttonVisibility(status) {
  if( status === 'ARCHIVED'){ isArcButtonVisible.value = false;
  isUnArcButtonVisible.value = true;}
  else{ isUnArcButtonVisible.value = false;
  isArcButtonVisible.value = true;}
}

const showPaginationForGroupEvents = computed(() => {
  return Math.ceil(totalRows.value / perPage.value) > 1;
});

const getGroupEvents = async (page) => {
  try {
    const params = {page: page - 1, size: perPage.value};
    const resp = await axios.get(`/training/group/events/${route.params.id}`, {params: params});
    if (resp.data && resp) {
      currentPage.value = page;
      totalRows.value = resp.data.totalSize;
      trainingEvents.value = resp.data.content;
    }
  } catch (e) {
    console.log(e);
  }
};

const updateTrainingEvent = async (event) => {
  const timeStart = moment(startTimeNew.value, "HH:mm");
  const timeEnd = moment(endTimeNew.value, "HH:mm");
  const date = moment(changeDate.value);
  const startTime = moment(date).set({
    hour: timeStart.get("hour"),
    minute: timeStart.get("minute"),
  });
  const endTime = moment(date).set({
    hour: timeEnd.get("hour"),
    minute: timeEnd.get("minute"),
  });
  const dict = {
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
  };
  try {
    const resp = await axios.patch(`/training/event/${event.id}`, dict);
    if (resp.data && resp) {
      event = resp.data;
      eventBus.$emit("bv::toggle::collapse", event.id);
      await getGroupData();
      await getGroupEvents(1);
      makeToast(t("DATA_UPDATE"), "success", t("DATA_UPDATE"));
    }
  } catch (e) {
    console.log(e);
  }
};

function isArchived() {
  return groupData.value.status === "ARCHIVED";
}

function dateFormat(date) {
  const d = new Date(date);
  const dm = moment(d);
  return dm.format("DD-MM-YYYY");
}

function timeFormat(date) {
  const d = new Date(date);
  const dm = moment(d);
  return dm.format("HH:mm");
}

function niceWeekday(date) {
  const d = new Date(date);
  const dm = moment(d);
  const day = dm.format("dddd");
  return day.toUpperCase();
}

function createTrainerList() {
  trainerOptions.value = groupData.value.trainers ? groupData.value.trainers : [];
}

function info(item) {
  infoModal.title = "DETAILS";
  infoModal.content = item.expectations;
  $bvModal.show(infoModal.id);
}

function resetInfoModal() {
  infoModal.title = "";
  infoModal.content = "";
}

function showGroupNotificationModal() {
  if (groupData.value.status === "ARCHIVED") {
    return;
  }
  eventBus.$emit("show-group-notification-modal", groupData.value.trainees);
}

function showRemoveTraineeModal(trainee) {
  if (groupData.value.status === "ARCHIVED") {
    return;
  }
  eventBus.$emit("show-remove-trainee-modal", trainee.trainee );
}

function showTransferTraineeModal(trainee) {
  if (groupData.value.status === "ARCHIVED") {
    return;
  }
  eventBus.$emit(
    "show-transfer-trainee-modal",
      trainee.trainee,
      groupData.value.training.code
  );
}

function canBeArchived() {
  return (isAdminOrSchoolManager() || isViewer()) && groupData.value?.canBeArchived;
}

function showArchiveConfirmation() {
  const data = {
    msg: t("GROUP_ARCHIVE_CONFIRM_MSG"),
    callback: archiveGroup,
  };
  eventBus.$emit("show-confirmation-modal", data);
}

function showUnArchiveConfirmation() {
  const data = {
    msg: t("GROUP_UNARCHIVE_CONFIRM_MSG"),
    callback: unArchiveGroup,
  };
  eventBus.$emit("show-confirmation-modal", data);

}

const archiveGroup = async () => {
  try {
    const response = await axios.post(
        `/training/group/${groupData.value.id}/archive`,
    );
    if (response.data && response) {
      groupData.value = response.data;
      buttonVisibility(groupData.value.status);
      toast.success(t("OPERATION_SUCCESSFUL"))
    }
  } catch (error) {
      console.error(error);
  }
};

const unArchiveGroup = async () => {
  try {
    const response = await axios.post(
        `/training/group/${groupData.value.id}/unarchive`,
    );
    if (response.data && response) {
      groupData.value = response.data;
      buttonVisibility(groupData.value.status);
      toast.success(t("OPERATION_SUCCESSFUL"))
    }
  } catch (error) {
      console.error(error);
  }
};

const getInvitationsData = async () => {
  if (!isViewer()) {
    try {
      const response = await axios.get(
          `/training/group/${route.params.id}/invitations`,
      );
      if (response.data && response) {
        invitations.value = response.data;
        refreshGroupDataState();
        return invitations.value;
      }
    } catch (e) {
      console.log(e);
    }
  }
};

function validateHWNumber() {
  if (groupData.value.homeworks < 0) {
    groupData.value.homeworks = 0;
  }
}

function validateLessonsNumber() {
  if (groupData.value.lessons < 1) {
    groupData.value.groupData.lessons = 1;
  }
}

function refreshGroupDataState() {
  if (groupData.value.trainees) {
    groupData.value.trainees.map((trainee) => {
      const foundInvitation = invitations.value.find(
          (invitation) => trainee.trainee.id === invitation.accountId,
      );
      trainee.trainee = {
        ...trainee.trainee,
        isInvitationSent: foundInvitation.isInvitationSent,
      };
      return trainee;
    })
  }

  return groupData;
}

const updateCovidData = async (traineeId, isCovidProof) => {
  try {
    await axios.put("/account/covid", {
      accountId: traineeId,
      isCovidProof: isCovidProof,
    });
  } catch (e) {
    console.log(e);
  }
};

const updateInvitationData = async (traineeId, isInvitationSent) => {
  try {
    const response = await axios.patch(`/training/group/invitation`, {
      accountId: traineeId,
      groupId: route.params.id,
      isInvitationSent,
    });
    if (response.data && response) {
      invitations.value = response.data;
      refreshGroupDataState();
    }
  } catch (e) {
    console.log(e);
  }
};

function toggleSearchForm() {
  addTrainee.value = addTrainee.value === false;
}

const searchTrainee = async () => {
  try {
    if (searchValue.value.length >= 3) {
      const response = await axios.post(
        `/account/search/trainee?searchValue=${searchValue.value}&fields=SSN,NAME`,
      );
      const data = response.data;
      searchResult.value = data.filter((t) => t.isArchived === false);
      return searchResult.value;
    }
  } catch (e) {
    console.log(e);
  }
};

const submitTrainee = async function (id) {
  try {
    const response = await axios.post(
        `/training/group/${route.params.id}/trainee?accountId=${id}`,
    );
    if (response && response.data) {
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));

      if (!groupData.value.trainees) {
        groupData.value.trainees = [];
      }

      groupData.value.trainees.push(response.data);
      addTrainee.value = false;
      searchValue.value = "";
    }
  } catch (e) {
    if (e.response && e.response.data) {
      switch (e.response.data) {
        case "INVALID_OP_ALREADY_REGISTERED":
          searchValue.value = "";
          break;
        case "INVALID_OP_ADDING_IS_NOT_POSSIBLE":
          searchValue.value = "";
          break;
      }
    } else {
      console.error("Error:", e);
    }
  }
};

const transferTrainee = async (dataObj) => {
    try {
    const body = {
      accountId: dataObj.trainee.id,
      destinationGroupId: dataObj.destination,
      reason: dataObj.reason,
    };
    const response = await axios.post(
      `/training/group/${route.params.id}/transfer`,
      body,
    );
    if (response.data && response) {
      groupData.value = response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

const removeTrainee = async (dataObj) => {
  try {
    const body = {
      accountId: dataObj.trainee.id,
      reasonType: dataObj.type,
      reason: dataObj.reason,
    };
    const response = await axios.delete(
      `/training/group/${route.params.id}/trainee`,
      { data: body },
    );
    if (response.data && response) {
      groupData.value = response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

const getGroupData= async () => {
  try {
    const response = await axios.get(`/training/group/${route.params.id}`);
    if (response && response.data) {
      groupData.value = response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

const getModuleTrainers = async () => {
  try {
    const response = await axios.get(
        `/training/${groupData.value.training.id}/group/${route.params.id}/school_trainers`,
    );
    if (response.data && response) {
      moduleTrainers.value = response.data;
      return moduleTrainers.value;

    }
  } catch (e) {
    console.log(e);
  }
};

const addTrainerToGroup = async (trainerId) => {
  try {
    const response = await axios.post(
        `/training/group/${route.params.id}/trainer/${trainerId}`,
    );
    if (response.data && response) {
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));
      groupData.value.trainers = response.data.trainers;
      moduleTrainers.value = "";
      return groupData.trainers;
    }
  } catch (e) {
    console.log(e);
  }

};

const updateData = async () => {
  if (groupData.value.location === "In-house") {
    groupData.value.status = "PRIVATE";
  }
  try {
    const response = await axios.patch(
      `/training/group/` + groupData.value.id,
      groupData.value,
    );
    if (response.data && response) {
      makeToast(t("SAVED"), "success", t("OPERATION_SUCCESSFUL"));
      groupData.value = response.data;
      moduleTrainers.value = "";
      refreshGroupDataState();
      return groupData.value;
    }
  } catch (e) {
    console.log(e);
  }
};

const makeToast = (deed = null, variant = null, title = null) => {
    toast(`${deed}`, {
        autoHideDelay: 5000,
        variant: variant,
        title: title,
    });
};

const checkEligibility = async () => {
  showEligibilityCheckProgress();

  try {
    const response = await axios.get(
        `/training/group/` + groupData.value.id + `/eligibility`,
    );
    if (response) {
      eligibilityData.value = response.data
      showEligibilityCheckResult(eligibilityData.value);
    }
  } catch (e) {
    console.log(e);
    eligibilityCheckInProgress.value = false;
  }
};

const createExcel = async () => {
  const response = await axios.get(
    "/attendance/group/" + groupData.value.id + "/excel",
    { responseType: "blob" },
  );
  const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement("a");
  fileLink.href = fileUrl;
  fileLink.setAttribute("download", "Keelegruppide_arvestustabel.xlsx");
  document.body.appendChild(fileLink);
  fileLink.click();
};

const showEligibilityCheckProgress=()=> {
  showEligibilityCheckColumn.value = true;
  eligibilityCheckInProgress.value = true;
};

const showEligibilityCheckResult=(eligibilityData)=> {
    groupData.value.trainees.forEach((w) => {
    w.eligibilityResultIcon = {};
    if (eligibilityData[w.trainee.ssn]) {
      w.eligibilityResultIcon.href = "check-circle";
      w.eligibilityResultIcon.variant = "success";
    } else {
      w.eligibilityResultIcon.href = "x-circle";
      w.eligibilityResultIcon.variant = "danger";
    }
  });
  eligibilityCheckInProgress.value = false;
};

function createPdf() {
  const trainees = getPdfTraineeData();
  const imagePromises = getImages();

  const doc = new JsPDF("landscape");
  doc.addFileToVFS("PTSans.ttf", PTSansFont);
  doc.addFont("PTSans.ttf", "PTSans", "normal");
  Promise.all(imagePromises).then((images) => {
    const isLang = groupData.value.training.type === "RECURRING";
    const head = isLang
      ? [
          [
            "",
            "Given name(s) / Имя",
            "Surname / Фамилия",
            "Estonian personal code / \nЭстонский личный код",
            "Signature / Подпись \nparticipation in class ac/h / \nучастие в классе ак.ч",
            "Signature / Подпись \npractical work ac/h / \nсамостоятельная работа ак.ч",
          ],
        ]
      : [
          [
            "",
            "Given name(s) / Имя",
            "Surname / Фамилия",
            "Estonian personal code / Эстонский личный код",
            "Signature / Подпись",
          ],
        ];
    autotable(doc, {
      head: head,
      headStyles: { fontSize: 8, font: "PTSans" },
      body: trainees,
      bodyStyles: { font: "PTSans" },
      theme: "plain",
      styles: { lineWidth: 0.1, lineColor: "black", minCellHeight: 1.2 },
      margin: { top: 100 },
      didDrawPage: function (data) {
        if (doc.internal.getNumberOfPages() === 1) {
          data.settings.margin.top = 15;

          doc.addImage(
            images[0],
            "JPG",
            data.settings.margin.left,
            15,
            52.5,
            21,
          );
          doc.addImage(
            images[1],
            "JPG",
            data.settings.margin.left + 115,
            15,
            29,
            21,
          );

          doc.setFontSize(11);
          doc.setFont("PTSans");
          doc.text(
            "Supporting the adaptation of newly arrived foreigners \n" +
              "Поддержка адаптации новых мигрантов\n" +
              "Uussisserändajate kohanemise toetamine\n",
            doc.internal.pageSize.getWidth() / 2,
            45,
            "center",
          );

          doc.setFont("PTSans");
          doc.setFontSize(12);
          doc.text(
            "REGISTRATION FORM / ФОРМА РЕГИСТРАЦИИ",
            doc.internal.pageSize.getWidth() / 2,
            64,
            "center",
          );
          doc.line(14, 66, 283, 66);

          doc.setFont("PTSans");
          doc.setFontSize(11);
          doc.text("Activity / Мероприятия: ", data.settings.margin.left, 74);
          doc.text(
            "Date, time and location / Дата, время и место: ",
            data.settings.margin.left,
            82,
          );
          doc.text(
            "Teacher's name / Имя и фамилия учителя: ",
            data.settings.margin.left,
            90,
          );
          doc.line(14, 95, 283, 95);
        }
        doc.setFontSize(9);
        doc.text(
          doc.internal.getNumberOfPages().toString(),
          doc.internal.pageSize.getWidth() - data.settings.margin.right,
          doc.internal.pageSize.getHeight() - 10,
        );
      },
    });
    doc.save("table.pdf");
  });
}

function getPdfTraineeData() {
  let i = 1;
  return groupData.value.trainees.map((t) => {
    return [i++, t.trainee.firstName, t.trainee.lastName, t.trainee.ssn];
  });
}

function getImages() {
  const images = [];
  const imagePaths = ["kultuurimin_3lovi_est.jpg", "el_sotsiaalfond.jpg"];
  imagePaths.forEach((path) => {
    images.push(
      new Promise((resolve) => {
        const img = new Image();
        img.src = new URL(`../assets/${path}`, import.meta.url).href;
        img.onload = function () {
          resolve(img);
        };
      }),
    );
  });
  return images;
}

function showCityOptions() {
  return [
    {value: 'Tallinn', text: t("TALLINN")},
    {value: 'Tartu', text: t("TARTU")},
    {value: 'Narva', text: t("NARVA")},
    {value: 'Alutaguse', text: t("ALUTAGUSE")},
    {value: 'Anija', text: t("ANIJA")},
    {value: 'Antsla', text: t("ANTSLA")},
    {value: 'Aseri', text: t("ASERI")},
    {value: 'Elva', text: t("ELVA")},
    {value: 'Haapsalu', text: t("HAAPSALU")},
    {value: 'Haljala', text: t("HALJALA")},
    {value: 'Harku', text: t("HARKU")},
    {value: 'Hiiumaa', text: t("HIIUMAA")},
    {value: 'Häädemeeste', text: t("HAADEMEESTE")},
    {value: 'Jõelähtme', text: t("JOELAHTME")},
    {value: 'Jõgeva', text: t("JOGEVA")},
    {value: 'Jõhvi', text: t("JOHVI")},
    {value: 'Järva', text: t("JARVA")},
    {value: 'Jüri', text: t("JURI")},
    {value: 'Kadrina', text: t("KADRINA")},
    {value: 'Kambja', text: t("KAMBJA")},
    {value: 'Kanepi', text: t("KANEPI")},
    {value: 'Kastre', text: t("KASTRE")},
    {value: 'Kehtna', text: t("KEHTNA")},
    {value: 'Keila', text: t("KEILA")},
    {value: 'Kihnu', text: t("KIHNU")},
    {value: 'Kiili', text: t("KIILI")},
    {value: 'Kiviõli', text: t("KIVIOLI")},
    {value: 'Kohila', text: t("KOHILA")},
    {value: 'Kohtla-Järve', text: t("KOHTLA_JARVE")},
    {value: 'Kose', text: t("KOSE")},
    {value: 'Kunda', text: t("KUNDA")},
    {value: 'Kuusalu', text: t("KUUSALU")},
    {value: 'Loksa', text: t("LOKSA")},
    {value: 'Luunja', text: t("LUUNJA")},
    {value: 'Lääne-Nigula', text: t("LAANE_NIGULA")},
    {value: 'Lääneranna', text: t("LAANERANNA")},
    {value: 'Lüganuse', text: t("LUGANUSE")},
    {value: 'Maardu', text: t("MAARDU")},
    {value: 'Muhu', text: t("MUHU")},
    {value: 'Mulgi', text: t("MULGI")},
    {value: 'Mustvee', text: t("MUSTVEE")},
    {value: 'Märjamaa', text: t("MARJAMAA")},
    {value: 'Narva-Jõesuu', text: t("NARVA_JOESUU")},
    {value: 'Nõo', text: t("NOO")},
    {value: 'Otepää', text: t("OTEPAA")},
    {value: 'Paide', text: t("PAIDE")},
    {value: 'Paldiski', text: t("PALDISKI")},
    {value: 'Peipsiääre', text: t("PEIPSIAARE")},
    {value: 'Põhja-Sakala', text: t("POHJA_SAKALA")},
    {value: 'Põltsamaa', text: t("POLTSAMAA")},
    {value: 'Põlva', text: t("POLVA")},
    {value: 'Pärnu', text: t("PARNU")},
    {value: 'Raasiku', text: t("RAASIKU")},
    {value: 'Rae', text: t("RAE")},
    {value: 'Rakvere', text: t("RAKVERE")},
    {value: 'Rapla', text: t("RAPLA")},
    {value: 'Ruhnu', text: t("RUHNU")},
    {value: 'Rõuge', text: t("ROUGE")},
    {value: 'Räpina', text: t("RAPINA")},
    {value: 'Saarde', text: t("SAARDE")},
    {value: 'Saaremaa ', text: t("SAAREMAA")},
    {value: 'Saku', text: t("SAKU")},
    {value: 'Saue', text: t("SAUE")},
    {value: 'Setomaa', text: t("SETOMAA")},
    {value: 'Sillamäe', text: t("SILLAMAE")},
    {value: 'Tapa', text: t("TAPA")},
    {value: 'Toila', text: t("TOILA")},
    {value: 'Tori', text: t("TORI")},
    {value: 'Tõrva', text: t("TORVA")},
    {value: 'Türi', text: t("TURI")},
    {value: 'Valga', text: t("VALGA")},
    {value: 'Viimsi', text: t("VIIMSI")},
    {value: 'Viljandi', text: t("VILJANDI")},
    {value: 'Vinni', text: t("VINNI")},
    {value: 'Viru-Nigula', text: t("VIRU_NIGULA")},
    {value: 'Vormsi', text: t("VORMSI")},
    {value: 'Võru', text: t("VORU")},
    {value: 'Väike-Maarja', text: t("VAIKE_MAARJA")},
    {value: 'Online', text: t("ONLINE")},
    {value: 'In-house', text: t("IN_HOUSE")},
    {value: 'Laev Isabelle', text: t("SHIP_ISABELLE")}
  ];
}

</script>
<style scoped>
.searchForm {
  padding: 20px;
}

.italic {
  font-style: italic;
}

.flex {
  display: flex;
}

.space {
  margin-right: 10px;
}

.form-control:disabled {
  background-color: #bae6e8;
  border: 0;
}

.red {
  color: red;
}

.add-me {
  background-color: white;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.moreTrainers {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
</style>
