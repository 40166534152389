import { getInstance, Role } from "./oauth";

export const AuthGuard = (to: any, from: any) => {
  const service = getInstance();

  const fn = async () => {
    await service.loadCredentials();

    // This should not let authenticated users access login and register pages
    if (!to.meta.roles && service.isAuthenticated() && to.meta.anon) {
      return { name: "Groups" };
    }
    // No roles means no authentication required
    if (!to.meta.roles) {
      return;
    }
    // Not authenticated users are sent to login
    if (!service.isAuthenticated()) {
      return { name: "Login" };
    }
    // All authenticated users can pass if role ALL is specified
    if (to.meta.roles.includes(Role.ALL)) {
      return;
    }
    // Do role based check
    if (to.meta.roles.some((role: string) => service.hasRole(role))) {
      return;
    } else {
      return { name: "Home" };
    }
  };

  return fn();
};
