import moment from "moment-timezone";

export function useDateFormat() {
  function dateFormat (date: string | number | Date)  {
    const d = new Date(date);
    const dm = moment(d);
    return dm.format("DD.MM.YYYY");
  }
  return {
    dateFormat,
  };
}
