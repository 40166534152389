<template>
  <b-container class="section">
    <b-row>
      <b-col>
        <div class="text24-blue mb-4">{{ t("GDPR_TITLE") }}</div>
      </b-col>
    </b-row>
    <b-row class="ml-4">
      <text-block
        content-id="gdprBlock"
        v-on:save="save"
        :content="content.elements.gdprBlock"
      ></text-block>
    </b-row>
  </b-container>
</template>

<script setup>
import {inject, onBeforeMount, onBeforeUnmount, ref} from "vue";
import { useI18n } from "vue-i18n";
import EventBus from "@/util/eventBus";
import { usePageContent } from "@/mixins/pageContentMixin2";
import TextBlock from "@/views/components/TextBlock.vue";

const { getPageContent, savePageContent } = usePageContent();
const axios = inject("axios");
const { t, locale, tm } = useI18n();
const pageName = "gdpr";
const content = ref({ elements: {} });

onBeforeMount(() => {
  EventBus.$on("locale", loadPageContent);
  loadPageContent();
});

onBeforeUnmount(() => {
  EventBus.$off("locale", loadPageContent);
});

async function loadPageContent() {
  content.value = await getPageContent(axios, pageName, locale.value);
}

const save = (elem) => {
  savePageContent(axios, pageName, locale.value, elem.element, elem.value);
};
</script>
