import type {ComposerTranslation} from "vue-i18n";

export const getGroupLangOptions = (t: ComposerTranslation) => {
    return [
        {value: "ENG", text: t("ENGLISH")},
        {value: "RUS", text: t("RUSSIAN")},
        {value: "EST", text: t("ESTONIAN")},
        {value: "UKR", text: t("UKRAINIAN")},
        {value: "TUR", text: t("TURKEY")},
        {value: "ARA", text: t("ARABIC")},
        {value: "FAR", text: t("FARSI")},
        {value: "DAR", text: t("DARI")},
        {value: "PAS", text: t("PASHTO")},
    ];
};
