<template>

  <b-modal
      id="school-creation-modal"
      ref="schoolCreationModalRef"
      :title="t('CREATE_SCHOOL')"
      header-class="text18-black bold"
      role="dialog"
      size="lg"
      @ok="handleOk()"
  >
    <template v-slot:modal-header-close>
      <b-img :src=removeIcon></b-img>
    </template>
    <b-row>
      <div class="col">
        <add-school-form
            ref="schoolFormRef"
            :manager-options="managerOptions"
            :module-options="moduleOptions"
            @submit-school="createSchool"
        ></add-school-form>
      </div>
    </b-row>
    <template v-slot:modal-footer>
      <b-button variant="blue" @click="handleOk()">
        {{ t("CONFIRM") }}
      </b-button>
    </template>
  </b-modal>
</template>
<script setup>
import removeIcon from "@/assets/icon/icon_x.svg";
import EventBus from "@/util/eventBus";
import addSchoolForm from "@/views/components/forms/AddSchoolForm.vue";
import {inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {toast} from "vue3-toastify";

const router = useRouter();
const route = useRoute();
const axios = inject("axios");
const {t, locale} = useI18n();
const schoolFormRef = ref(null);
const emit = defineEmits(['added-school']);
const schoolForm = ref(null);
const managerOptions = ref([]);
const moduleOptions = ref([]);
const errors = ref({});
const schoolCreationModalRef = ref(null);

onMounted(() => {
  EventBus.$on("show-school-creation-modal", showNewSchoolCreationModal);
  getSchoolManagers();
  getModules();
});

onBeforeUnmount(() => {
  EventBus.$off("show-school-creation-modal", showNewSchoolCreationModal);
});

const showNewSchoolCreationModal = () => {
  if (schoolCreationModalRef.value) {
    schoolCreationModalRef.value.show();
  }
};

async function handleOk() {
  const isValid = await schoolFormRef.value.submitForm();
  if (isValid) {
    emit("added-school", schoolForm.value);
    if (schoolCreationModalRef.value) {
      schoolCreationModalRef.value.hide();
    }
  } else {
    console.error("Form validation failed");
  }
}

async function createSchool(schoolForm) {
  try {
    const response = await axios.post("/school/new-school", schoolForm);
    if (response.data) {
      makeToast(t("ADDED_SCHOOL"), "success", t("OPERATION_SUCCESSFUL"));
      setTimeout(async () => {
        await schoolCreationModalRef.value.hide();


        emit("added-school", response.data);


      }, 500);
    }
  } catch (error) {
    console.error("Error in createSchool: ", error);
  }
}


async function getSchoolManagers() {
  try {
    const resp = await axios.get("/school/schools/managers/");

    managerOptions.value = resp.data;
  } catch (e) {
    console.error(e);
  }
}

async function getModules() {
  try {
    const resp = await axios.get("/training/unarchived");
    moduleOptions.value = resp.data;
  } catch (e) {
    console.log(e);
  }
}


const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    title: title,
    variant: variant,
    autoHideDelay: 5000,
  });
};
</script>
