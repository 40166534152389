<template>
  <div class="container-fluid main-container">
    <div class="row mb-2 ml-1">
      <b-col cols="auto">
        <h2 class="title-blue">{{ t("MANAGE_PROFILE") }}</h2>
        <h2 class="title-blue">{{ t(role) }}</h2>
      </b-col>
      <b-col align-self="center">
        <b-button
            v-if="isArchived"
            v-bind:class="{ 'on-hover-color': hover }"
            variant="blue"
            @click="activateTrainingCard"
            v-on:mouseenter="hover = true"
            v-on:mouseleave="hover = false">
          {{ hover ? t('ACTIVATE') : t('ARCHIVED') }}
        </b-button>
      </b-col>
    </div>
    <div class="row">
      <div class="col-4">
        <profile-form></profile-form>
      </div>
      <div class="col">
        <b-tabs active-nav-item-class="bold" card>
          <b-tab
            v-if="logs"
            :title="t('LOGS')"
            active
            title-link-class="text16-black"
          >
            <b-table
              :borderless="true"
              :current-page="currentPage"
              :empty-text="t('NO_RESULTS')"
              :fields="logFields"
              :items="logs"
              :per-page="perPage"
              :striped="true"
              class="text-small"
              responsive
              show-empty
            >
              <template v-slot:cell(created_at)="{ item }">
                {{
                  dateFormat(item.createdAt) + " " + timeFormat(item.createdAt)
                }}
              </template>
              <template v-slot:cell(training_group)="{ item }">
                <span v-if="item.group">
                  <b-link @click="handleClick(item.group.id)">
                    {{ item.group.title }}
                  </b-link>
                  <span v-if="item.destinationGroup">

                    <b-link
                      :to="{
                        name: 'ManageGroup',
                        params: { id: `${item.destinationGroup.id}` },
                      }"
                    >
                      {{ item.destinationGroup.title }}
                    </b-link>
                  </span>
                </span>
              </template>
              <template v-slot:cell(training)="{ item }">
                <span v-if="item.group">{{
                  item.group.training.translations[locale]
                }}</span>
              </template>
              <template v-slot:cell(created_by)="{ item }">
                <span
                  >{{ item.createdBy.firstName }}
                  {{ item.createdBy.lastName }}</span
                >
              </template>
              <template v-slot:cell(type)="{ item }">
                <span>{{ t(item.type) }}</span>
              </template>
            </b-table>
            <b-pagination
              v-if="logsCount() > perPage"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="logsCount()"
              align="center"
            >
            </b-pagination>
          </b-tab>
          <b-tab
              v-if="training &&(role === 'TRAINEE' || role === 'TRAINER')"
              :title="t('SCHOOLING')"
              title-link-class="text16-black"
          >
            <b-table
                :borderless="true"
                :current-page="currentPageSchool"
                :empty-text="t('NO_RESULTS')"
                :fields="trainingFields"
                :items="training"
                :per-page="perPage"
                :striped="true"
                class="text-small"
              responsive
              show-empty
            >
              <template v-slot:cell(training)="{ item }">
                <span>{{ item.training.translations[locale] }}</span>
              </template>
              <template v-slot:cell(events)="{ item }">
                <span v-if="item.events">{{
                  dateFormat(item.events[0].startTime)
                }}</span>
              </template>
              <template v-slot:cell(view)="{ item }" v-if="hasRoleToViewGroup()">
                <b-button @click="handleClick(item.id)">
                  {{ t("VIEW") }}
                </b-button>
              </template>
              <template v-slot:cell(result)="{ item }">
                <td
                  v-if="badgeType(item) === 'ended'"
                  class="badge badge-success ended-style"
                >
                  {{ t("ENDED") }}
                </td>
                <td
                  v-if="badgeType(item) === 'upcoming'"
                  class="badge badge-info"
                >
                  {{ t("UPCOMING") }}
                </td>
                <td
                    v-if="badgeType(item) === 'ongoing'"
                    class="badge badge-primary white"
                >
                  {{ t("ONGOING") }}
                </td>
              </template>
            </b-table>
            <b-pagination
                v-if="trainingCount() > perPage"
                v-model="currentPageSchool"
                :per-page="perPage"
                :total-rows="trainingCount()"
                align="center"
            >
            </b-pagination>
          </b-tab>
          <b-tab
              v-if="modules && role === 'TRAINER'"
              :title="t('MODULES')"
              title-link-class="text16-black"
          >
            <b-table
                :borderless="true"
                :current-page="currentPageModule"
                :empty-text="t('NO_RESULTS')"
                :fields="moduleFields"
                :items="modules"
                :per-page="perPage"
                show-empty
                striped
            >
            </b-table>
            <b-pagination
                v-if="moduleCount() > perPage"
                v-model="currentPageModule"
                :per-page="perPage"
                :total-rows="moduleCount()"
                align="center"
            >
            </b-pagination>
          </b-tab>
          <b-tab
              v-if="schools && role === 'TRAINER'"
              :title="t('SCHOOLS')"
              title-link-class="text16-black"
          >
            <b-table
                :borderless="true"
                :current-page="currentPageSchools"
                :empty-text="t('NO_RESULTS')"
                :fields="schoolsFields"
                :items="schools"
                :per-page="perPage"
                show-empty
                striped
            >
              <template v-slot:cell(view)="{ item }">
                <div class="text-center">
                  <b-button
                      :to="{
                  name: 'ManageSchool',
                  params: { id: item.id, school: item },
                }"
                  >
                    {{ t("VIEW") }}
                  </b-button>
                </div>
              </template>

            </b-table>
            <b-pagination
                v-if="schoolsCount() > perPage"
                v-model="currentPageSchools"
                :per-page="perPage"
                :total-rows="schoolsCount()"
                align="center"
            >
            </b-pagination>
          </b-tab>
          <b-tab
              v-if="attendance && role === 'TRAINEE'"
              :title="t('ATTENDANCE')"
              style="text-align: left"
              title-link-class="text16-black"
          >
            <b-table
                :borderless="true"
                :current-page="currentPageAttend"
                :empty-text="t('NO_RESULTS')"
                :fields="attendanceFields"
                :items="attendance"
                :per-page="perPage"
                :striped="true"
                class="text-small"
                responsive
                show-empty
            >
              <template v-slot:cell(trainingEvent.startTime)="{ item }">
                <span v-if="item.trainingEvent">{{
                    dateFormat(item.trainingEvent.startTime)
                  }}</span>
                <span v-else>{{ t("TRANSFERRED") }}</span>
              </template>
              <template v-slot:cell(status)="{ item }">
                <span>{{ t(item.status) }}</span>
              </template>
            </b-table>
            <b-pagination
                v-if="attendCount() > perPage"
                v-model="currentPageAttend"
                :per-page="perPage"
                :total-rows="attendCount()"
                align="center"
            >
            </b-pagination>
          </b-tab>
          <b-tab
            v-if="adminTrainers && (role === 'SCHOOL_MANAGER')"
              :title="t('SCHOOL_TRAINERS')"
              title-link-class="text16-black"
          >
            <b-table
                :borderless="true"
                :current-page="currentAdminTrainerPage"
                :empty-text="t('NO_RESULTS')"
                :fields="adminTrainerFields"
                :items="adminTrainers"
                :per-page="perPage"
                show-empty
                striped
            >
            </b-table>
            <b-pagination
                v-if="adminTrainersCount() > perPage"
                v-model="currentAdminTrainerPage"
                :per-page="perPage"
                :total-rows="adminTrainersCount()"
                align="center"
            >
            </b-pagination>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import ProfileForm from "@/views/components/forms/ProfileForm.vue";
import {useDateFormat} from "@/mixins/dateFormatMixin2.ts";
import useTimeFormat from "@/mixins/timeFormatMixin2";
import moment from "moment-timezone";
import {computed, inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import router from "@/router";
import {toast} from "vue3-toastify";
import {Role} from "@/auth/oauth";

const axios = inject("axios");
const route = useRoute();
const {t, locale} = useI18n();
const auth = inject("auth");
const {dateFormat} = useDateFormat();
const {timeFormat} = useTimeFormat();

const logs = ref(null);
const training = ref(null);
const attendance = ref(null);
const schools = ref(null);
const adminTrainers = ref(null);
const perPage = ref(10);
const currentPage = ref(1);
const currentPageSchool = ref(1);
const currentPageSchools = ref(1);
const currentPageAttend = ref(1);
const currentPageModule = ref(1);
const currentAdminTrainerPage = ref(1);
const hover = ref(false);
const role = ref("");
const modules = ref(null);
const isArchived = ref(false);
const id = route.params.id;
onMounted(async () => {
  await fetchIsArchivedById();
  await getRole();

  if (!auth.hasRole("TRAINEE")) {
    await getLogs();
  }

  if (role.value === Role.TRAINER && auth.hasSomeRole(Role.ADMIN, Role.TRAINER, Role.VIEWER, Role.SCHOOL_MANAGER)) {
    await getTrainerModules();
  }

  if (role.value === Role.TRAINER && auth.hasRole(Role.ADMIN)) {
    await getTrainerSchools();
  }

  if (role.value === Role.SCHOOL_MANAGER && auth.hasSomeRole(Role.ADMIN, Role.VIEWER)) {
    await getSchoolAdminTrainers(route.params.id);
  } else if (!route.params.id && auth.hasRole(Role.SCHOOL_MANAGER)) {
    await getCurrentSchoolAdminTrainers();
  }

  if (route.params.id || auth.hasRole("TRAINEE")) {
    await getSchooling();
    await getAttendance();
  }
});

async function fetchIsArchivedById() {
  if (id) {
    try {
      const response = await axios.get(`/account/${id}`);
      isArchived.value = response.data.isArchived
    } catch (error) {
      console.error(error)
    }
  }
}

const schoolsFields = computed(() => {
  return [
    {key: "registryCode", label: t("SCHOOL_CODE"), sortable: true},
    {key: "businessName", label: t("SCHOOL_NAME"), sortable: true},
    {key: "view", label: "", sortable: false},
  ];
  if (showArchived.value) {
    const field = {key: "archived", label: t("ARCHIVED"), sortable: true};
    fields.splice(-1, 0, field);
  }
  return fields;
});


function logsCount() {
  return logs.value ? logs.value.length : 0;
}

function adminTrainersCount() {
  return adminTrainers.value ? adminTrainers.value.length : 0;
}

function moduleCount() {
  return modules.value ? modules.value.length : 0;
}

function schoolsCount() {
  return schools.value ? schools.value.length : 0;
}

function trainingCount() {
  return training.value ? training.value.length : 0;
}

function attendCount() {
  return attendance.value ? attendance.value.length : 0;
}

async function getTrainerModules() {
  if (!route.params.id) {
    return;
  }

  try {
    const resp = await axios.get(`/training/profile-modules/${route.params.id}/`);
    if (resp.data && resp) {
      modules.value = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
}

async function getTrainerSchools() {
  if (!route.params.id) {
    return;
  }

  try {
    const resp = await axios.get(`/school/schools/by-trainer/${route.params.id}/`);
    if (resp.data && resp) {
      schools.value = resp.data;
    }
  } catch (error) {
    console.error(error);
  }
}

const getTypeName = (value /* key, item */) => {
  return t(value);
};

const getContentTypeName = (value /* key, item */) => {
  return value.type;
};

const getContentFullName = (value, key, item) => {
  return value.firstName + " " + value.lastName;
}
const logFields = computed(() => {
  return [
    {key: "createdAt", label: t("TIME"), sortable: true, formatter: dateFormat},
    {key: "createdBy", label: t("AUTHOR"), sortable: true, formatter: getContentFullName},
    {key: "type",      label: t("TYPE"),   sortable: true,    tdClass: cellColor, formatter: getContentTypeName},
    {key: "message", label: t("CONTENT"), sortable: true},
    {key: "training_group", label: t("GROUP"), sortable: true},
    {key: "training", label: t("MODULE"), sortable: true},
  ];
});

const trainingFields = computed(() => {
  return [
    {key: "events", label: t("START"), sortable: true},
    {key: "title", label: t("GROUP"), sortable: true},
    {key: "training", label: t("MODULE"), sortable: true},
    {key: "result", label: t("RESULT")},
    {key: "view", label: ""},
  ];
});

const moduleFields = computed(() => {
  return [
    {key: "code", label: t("CODE_NAME"), sortable: true},
    {
      key: "translations",
      label: t("NAME"),
      sortable: true,
      formatter: getModuleName,
    },
    {
      key: "type",
      label: t("MODULE_TYPE"),
      sortable: true,
      formatter: getTypeName,
    },
    {key: "action", label: "", sortable: false},
  ];
});

const attendanceFields = computed(() => {
  return [
    {
      key: "trainingGroup.trainingTranslation",
      label: t("MODULE"),
      formatter: getModuleName,
    },
    {key: "trainingGroup.title", label: t("GROUP")},
    {key: "trainingEvent.startTime", label: t("TIME")},
    {key: "status", label: t("STATUS"), sortable: true},
    {key: "hoursPresent", label: t("PRESENT"), sortable: true},
    {key: "hoursHomework", label: t("HOMEWORK"), sortable: true},
  ];
});

const hasRoleToViewGroup = () => {
  return auth.hasSomeRole(Role.ADMIN, Role.SCHOOL_MANAGER, Role.TRAINER, Role.VIEWER);
};

async function handleClick(groupId) {
  try {
    const response = await axios.get(
      `/training/group/${groupId}/canViewGroup`,
      { headers: { "Cache-Control": "no-cache" } },
    );
    const canViewGroup = response.data.result;

    if (canViewGroup) {
      router.push({ name: "ManageGroup", params: { id: groupId } });
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
}


const cellColor = (value, key, item) => {
  if (!item) return;
  if (item.type === "DATA_UPDATE") return "table-success";
  if (item.type === "GROUP_LEAVE") return "table-info";
  if (item.type === "GROUP_REMOVE") return "table-warning";
  if (item.type === "ERROR") return "table-danger";
};

const badgeType = (item) => {
  if (!item) return "";
  if (item.status === "ARCHIVED") return "ended";
  if (
    item.status === "PUBLIC" &&
    moment(item.events[0].startTime).isAfter(moment())
  )
    return "upcoming";
  if (
    item.status === "PUBLIC" &&
    moment(item.events[item.events.length - 1].endTime).isBefore(moment())
  )
    return "ended";
  if (
    item.status === "PUBLIC" &&
    item.training.type === "RECURRING" &&
    moment(item.events[item.events.length - 1].endTime).isAfter(moment())
  )
    return "ongoing";
  return "";
};

async function getLogs() {
  try {
    if (route.params.id) {
      const resp = await axios.get(`/account/history/${route.params.id}`);
      if (resp.data && resp) {
        logs.value = resp.data.content;
      }
    }
  } catch (e) {
    console.log(e);
  }
}

const adminTrainerFields = computed(() => {
  return [
    {key: "fullName", label: t("NAME"), sortable: true, formatter: getFullName},
    {key: "ssn", label: t("SSN"), sortable: true},
    {key: "email", label: t("EMAIL"), sortable: true},
    {key: "phone", label: t("PHONE"), sortable: true},
    {key: "action", label: "", sortable: false},
  ];
});
const getFullName = (value, key, item) => {
  return item.firstName + " " + item.lastName;
};

async function getSchooling() {

  try {
    let resp;
    if (route.params.id) {
      resp = await axios.get(`/account/groups/${route.params.id}`);
    } else {
      resp = await axios.get(`/account/groups/`);
    }
    if (resp.data && resp) {
      training.value = resp.data;
    }
  } catch (e) {
    console.log(e);
  }
}

async function getRole() {
  if (!route.params.id) {
    role.value = auth.getRole();
    return;
  }

  try {
    let resp = await axios.get(`/account/role/${route.params.id}`);
    if (resp.data && resp) {
      role.value = resp.data;
    } else {
      role.value = auth.getRole()
    }
  } catch (e) {
    role.value = auth.getRole();
    console.log(e);
  }
}

async function getAttendance() {
  try {
    let resp;
    if (route.params.id) {
      resp = await axios.get(`/account/attendance/${route.params.id}`);
    } else {
      resp = await axios.get(`/account/attendance/`);
    }
    if (resp.data && resp) {
      attendance.value = resp.data.content;
    }
  } catch (e) {
    console.log(e);
  }
}

async function getSchoolAdminTrainers(schoolManagerId) {
  try {
    const resp = await axios.get(`/account/${schoolManagerId}/current_trainers`);
    if (resp?.data) {
      adminTrainers.value = resp.data;
    }
  } catch (e) {
    console.log(e);
  }
}

async function getCurrentSchoolAdminTrainers() {
  try {
    const resp = await axios.get(`/account/current_trainers`);
    if (resp?.data) {
      adminTrainers.value = resp.data;
    }
  } catch (e) {
    console.log(e);
  }
}

function getModuleName(value /* key, item */) {
  return value[locale.value];
}

async function activateTrainingCard() {
  try {
    const resp = await axios.patch(`/account/${route.params.id}/activate`);
    if (resp && resp.status === 204) {
      isArchived.value = false;
      makeToast(t("ACTIVATED"), "success", t("OPERATION_SUCCESSFUL"));
      return isArchived.value;
    }
  } catch (error) {
    return isArchived.value;
  }
}

const makeToast = (deed = null, variant = null, title = null) => {
  toast(`${deed}`, {
    title: title,
    variant: variant,
    autoHideDelay: 5000,
  });
}
</script>

<style scoped>
.main-container {
  padding-top: 2%;
}

.white {
  color: white;
}

.ended-style {
  background-color: #65a580;
  color: white;
}

.on-hover-color {
  background-color: orange;
}
</style>
