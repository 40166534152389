<template>
  <b-row>
    <b-col>
      <b-table
        :fields="fields"
        :per-page="perPage"
        responsive
        :current-page="currentPage"
        show-empty
        small
        striped
        :empty-text="t('NO_RESULTS')"
        :borderless="true"
        :items="tableItems"
      >
        <template v-slot:cell(location)="{ item }">
          <span v-if="item.location === 'In-house'">{{ t("IN_HOUSE") }}</span>

          <span v-else>{{ item.location }}</span>
        </template>
        <template v-slot:cell(action)="{ item }">

          <div class="align-items-center" v-if="register ">

            <b-button
                style="height: 28px"
                variant="white"
                @click="showConfirmRegistrationModal(item)"
            >
              <b-icon-person-plus></b-icon-person-plus>
              {{ t("REGISTER") }}
            </b-button>
          </div>

          <div
            class="align-items-center"
            v-if="!register && item.status !== 'ARCHIVED'"
          >
            <b-button
              style="height: 28px"
              variant="white"
              @click="showCancellationModal(item)"
            >
              <b-icon-x-circle variant="danger"></b-icon-x-circle>
              {{ t("CANCEL") }}
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="text24-blue">
        <span v-if="eligibleStatus === 'NOT_ELIGIBLE'">{{ t("NOT_ELIGIBLE") }}</span>
        <span v-else-if="eligibleStatus === 'ELIGIBLE'">{{ t("ELIGIBLE") }}</span>
      </div>


      <b-pagination
        v-if="tableItems && tableItems.length > perPage"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="tableItems ? tableItems.length : 0"
        align="center"
      >
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import moment from "moment-timezone";
import EventBus from "../../../util/eventBus";
import {computed, inject, ref} from "vue";
import {toast} from "vue3-toastify";

const auth = inject("auth");

const props = defineProps({
  filterText: String,
  filterStatus: String,
  eligibleStatus: String,
  items: Array,
  register: {
    type: Boolean,
    default: false,
  },
});
const { t, locale } = useI18n();
const perPage = 7;
const currentPage = ref(1);
const registrationCancellationModalVisible = ref(false)
const registrationConfirmationModalVisible = ref(false)
const isTrainee = computed(() => auth.hasRole("TRAINEE"));

const showConfirmRegistrationModal =(group)=> {
  if (isTrainee.value) {
    registrationConfirmationModalVisible.value = true
    EventBus.$emit("show-registration-modal", group);
  } else {
    toast.warn(t("ACCESS_RESTRICTED"), {
      position: "top-right",
      autoClose: 5000
    })
  }
}

const showCancellationModal=(group) => {
  if (isTrainee.value) {
    registrationCancellationModalVisible.value = true
    EventBus.$emit("show-registration-cancellation-modal", group);
  } else {
    toast.warn(t("ACCESS_RESTRICTED"), {
      position: "top-right",
      autoClose: 5000
    })
  }
}

const getStatus = computed(() => {
  return t(props.eligibleStatus);
})
const getModuleName = (value /* key, item */) => {
  return value[locale.value];
};

function getGroupWeekdays(value, key, item) {
  if (!item.events || item.events.length === 0) {
    return "";
  }

  const days = [
    t("MONDAY_SHORT"),
    t("TUESDAY_SHORT"),
    t("WEDNESDAY_SHORT"),
    t("THURSDAY_SHORT"),
    t("FRIDAY_SHORT"),
    t("SATURDAY_SHORT"),
    t("SUNDAY_SHORT"),
  ];

  const exists = [0, 0, 0, 0, 0, 0, 0];
  item.events.forEach((e) => {
    exists[moment(e.startTime).isoWeekday() - 1] = 1;
  });
  const strList = [];
  exists.forEach((value, index) => {
    if (value === 1) {
      strList.push(days[index]);
    }
  });
  return strList.join(", ");
}
function getGroupDates(value, key, item) {
  if (!item.events || item.events.length === 0) {
    return "";
  }
  if (item.events.length === 1) {
    return moment(item.events[0].startTime).format("DD.MM.YY");
  }

  const first = item.events[0];
  const last = item.events[item.events.length - 1];

  return `${moment(first.startTime).format("DD.MM.YY")} - ${moment(
    last.endTime,
  ).format("DD.MM.YY")}`;
}

const getGroupTime = (value, key, item) => {
  if (!item.events || item.events.length === 0) {
    return "";
  }
  const event = item.events[0];
  return `${moment(event.startTime).format("HH:mm")} - ${moment(
    event.endTime,
  ).format("HH:mm")}`;
};

const fields = computed(() => {
  return [
    {
      key: "training.translations",
      label: t("MODULE"),
      sortable: true,
      formatter: getModuleName,
    },
    {
      key: "events",
      label: t("DATES"),
      sortable: true,
      formatter: getGroupDates,
    },
    {
      key: "time",
      label: t("TIME_ESTONIAN"),
      formatter: getGroupTime,
    },
    {
      key: "weekdays",
      label: t("WEEKDAYS"),
      formatter: getGroupWeekdays,
    },
    { key: "language", label: t("LANGUAGE"), sortable: true },
    { key: "location", label: t("LOCATION"), sortable: true },
    { key: "action", label: "", sortable: false },
  ];
});
const tableItems = computed(() => {
  if (props.filterText) {
    const kw = props.filterText.toLowerCase();
    return props.filterText
      ? props.items.filter(
          (item) =>
            item.training.translations[locale.value]
              .toLowerCase()
              .includes(kw) ||
            item.language.toLowerCase().includes(kw) ||
            item.location.toLowerCase().includes(kw) ||
            (item.events && item.events[0].startTime.includes(kw)),
        )
      : props.items;
  }
  return props.items;
});

</script>
