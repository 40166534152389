<template>
  <b-modal
      content-class="p-2 rounded-0"
      body-class="p-2 border-0"
      class="modal-content"
      id="group-notification-modal"
      ref="groupNotificationModalRef"
      size="lg"
      role="dialog"
      no-close-on-backdrop
      :cancel-disabled="true"
      :ok-disabled="true"
      header-class="p-2 border-0 text18-black"
      footer-class="p-2 border-0"
      centered
      title-class="ml-auto"
      :title="t('NOTIFICATION')"
      @close="handleClose"
      @ok="handleOk"
  >
    <div class="text-center text14-black">
      <b-form-textarea
        class="textarea"
        id="details"
        name="details"
        rows="6"
        max-rows="12"
        :placeholder="t('WRITE_STH')"
        v-model="traineeMail.message"
      ></b-form-textarea>
      <div>
        {{ t("RECIPIENTS") }}:
        {{ traineeMail.traineeMails }}
      </div>
    </div>
    <template v-slot:modal-header-close>

        <b-img :src="(removeIcon)"></b-img>

    </template>
    <template v-slot:modal-footer>
      <b-button
          variant="blue"
          @click="sendNotification"
          :disabled="traineeMail.message === null"
      >{{ t("SEND") }}
      </b-button
      >
    </template>
  </b-modal>
</template>

<script setup>
import removeIcon from "@/assets/icon/icon_x.svg";
import EventBus from "../../../util/eventBus";
import {inject, onMounted, onUnmounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";

const route = useRoute();
const axios = inject("axios");
const { t } = useI18n();
const traineeMail = ref({
  message: null,
  traineeMails: [],
})
const groupNotificationModalRef = ref(null)
onMounted(() => {
  EventBus.$on(
      "show-group-notification-modal", showGroupNotificationModal);
})
onUnmounted(() => {
  EventBus.$off(
      "show-group-notification-modal", showGroupNotificationModal);
})

function handleClose() {
  traineeMail.value.traineeMails = [];
}

function traineeMails(trainees) {
  trainees.forEach((t) => {
    traineeMail.value.traineeMails.push(t.trainee.email);
  });
}

async function handleOk(bvModalEvt) {
  bvModalEvt.preventDefault();
}

function showGroupNotificationModal(trainees) {
  traineeMails(trainees);
  groupNotificationModalRef.value.show("group-notification-modal");
}

async function sendNotification() {
  await axios.post(
      `/training/group/notification/${route.params.id}`,
      traineeMail.value,
  );
  traineeMail.traineeMails = [];
  traineeMail.message = null;
  groupNotificationModalRef.value.hide("group-notification-modal");
}


</script>
