<template>
  <b-row>
    <b-col>
      <loader :active='loaderActive' :message="t('PLEASE_WAIT')"/>
      <b-table
          id="admin-groups-table"
          :items="items"
          :fields="fields"
          :per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive="true"
          show-empty
          striped
          small
          :empty-text="emptyTableText"
          :borderless="true"
          no-local-sorting
          no-sort-reset
          @update:sortBy="updateSortBy"
          @update:sortDesc="updateSortDesc"
      >
        <template v-slot:cell(action)="{ item }">
          <div class="d-flex align-items-center gap-2">
            <b-button  class="mr-3" :to="{ name: 'ManageGroup', params: { id: `${item.id}` } }">
              {{ t("CHANGE") }}
            </b-button>
            <img
                v-if="isAdmin()"
                :src="iconRemove"
                @click="deleteGroup(item)" :disabled="isDeleting"
                class="clickable"
                alt="icon Remove with X"
            />
          </div>
        </template>
        <template v-slot:cell(location)="{ item }">
          <span v-if="item.location === 'In-house'">{{ t("IN_HOUSE") }}</span>
          <span v-else>{{ item.location }}</span>
        </template>
        <template v-slot:cell(status)="{ item }">
          <span>{{ t(item.status) }}</span>
        </template>
        <template v-slot:cell(title)="{ item }">
          <span>{{ item.title }}</span>
        </template>
        <template v-slot:cell(startEvent)="{ item }">
          <span v-if="item.startEvent">{{ dateFormat(item.startEvent) }}</span>
        </template>
        <template v-slot:cell(endEvent)="{ item }">
          <span v-if="item.endEvent">{{ dateFormat(item.endEvent) }}</span>
        </template>
        <template v-slot:cell(businessName)="{ item }">
          <span>{{ item.businessName }}</span>
        </template>

        <template v-slot:cell(trainers)="{ item }">
          <li v-for="i in item.trainers" :key="i.trainers">
            {{ i }}
          </li>
        </template>
        <template v-slot:cell(groupSize)="{ item }">
          <span v-if="item.numberOfTrainees">{{ item.numberOfTrainees }} /</span>
          <span v-else>0 / </span>
          <span>{{ item.groupSize }}</span>
        </template>
      </b-table>
      <b-pagination
          v-if="totalRows > items.length"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="admin-groups-table"
          align="center"
      />
    </b-col>
  </b-row>
  <RemoveGroupModal @group-removed="handleGroupRemoved" />
</template>

<script setup>

import EventBus from "../../../util/eventBus";
import {useDateFormat} from "@/mixins/dateFormatMixin2.ts";
import Loader from "@/components/Loader.vue";
import {useLoader} from "@/mixins/loaderMixin";
import {inject, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

import removeIcon from "@/assets/icon/icon_x.svg";
import RemoveGroupModal from "@/views/components/modals/removeGroupModal.vue";

const auth = inject("auth");
const {loaderActive, showLoader, hideLoader} = useLoader();
const isDeleting = ref(false)
const {dateFormat} = useDateFormat();
const props = defineProps({
  filterText: {
    type: String,
    default: "",
  },
  filterStatus: {
    type: String,
    default: "PUBLIC",
  },
});
const iconRemove = removeIcon;
const { t, locale } = useI18n();
const axios = inject("axios");
const groups = ref(null);
const perPage = ref(15);
const currentPage = ref(1);
const emptyTableText = t("NO_RESULTS");
const items = ref([]);
const sortBy = ref('title');
const sortDesc = ref(false);
const totalRows = ref(0);

const getGroups = async () => {
  try {
    showLoader();
    const page = currentPage.value - 1;
    const payload = {
      status: props.filterStatus,
      search: props.filterText,
      currentLocale: locale.value,
      pageable: {
        number: page,
        size: perPage.value,
        sort: {
          orderBy: [{
            property: sortBy.value,
            direction: sortDesc.value ? 'DESC' : 'ASC',
            ignoreCase: false
          }]
        }
      }
    };
    const resp = await axios.post('/training/groups/search', payload);
    if (resp && resp.data) {
      groups.value = resp.data;
      totalRows.value = resp.data.totalSize;
      items.value = resp.data.content;
    }
  } catch (e) {
    console.error(e);
  } finally {
    hideLoader();
  }
};

const deleteGroup = (group) => {
  EventBus.$emit('show-remove-group-modal', group)
}
const handleGroupRemoved = (groupId) => {
  items.value = items.value.filter(group => group.id !== groupId)
  getGroups()
}
const updateSortBy = (newSortBy) => {
  sortBy.value = newSortBy;
};

const updateSortDesc = (newSortDesc) => {
  sortDesc.value = newSortDesc;
};

onBeforeMount(() => {
  EventBus.$on("GROUP_SEARCH", getGroups);
  getGroups();
});
onBeforeUnmount(() => {
  EventBus.$off("GROUP_SEARCH", getGroups);
});

watch([currentPage, sortBy, sortDesc, locale], getGroups);

const fields = [
  { key: 'translation',  label: t("MODULE"), sortable: true },
  { key: 'title', label: t("TITLE"),  sortable: true },
  { key: 'startEvent', label: t("START"), sortable: true },
  { key: 'endEvent', label: t("END"), sortable: true },
  {key: 'businessName', label: t("SCHOOL"), sortable: true},
  { key: "language", label: t("LANGUAGE"), sortable: true },
  { key: 'trainers', label: t("TRAINER"), sortable: false },
  { key: 'location', label: t("LOCATION"), sortable: true },
  { key: 'status',  label: t("STATUS"), sortable: false },
  { key: 'groupSize',label: t("OCCUPANCY"), sortable: true },
  {key: 'action', label: "", sortable: false  },
  {key: 'action', label: "", sortable: false  },
];
const isAdmin = () => {
  return auth.hasRole("ADMIN");
};
</script>
