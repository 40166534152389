<template>
  <b-container class="margin-top" fluid="sm" style="text-align: left">
    <b-card v-if="isSuccess">
      <b-alert variant="success" show>
        {{ t("ACTIVATION_SUCCESS") }}
      </b-alert>
      <b-button variant="primary" v-on:click="toLogin()">{{
        t("PW_LOGIN")
      }}</b-button>
    </b-card>
  </b-container>
</template>

<style scoped>
.margin-top {
  margin-top: 64px;
}
</style>

<script setup>
import {inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

import {useRoute, useRouter} from "vue-router";

const {t} = useI18n();
const axios = inject("axios");
const router = useRouter();
const route = useRoute();

const isSuccess = ref(false);

onMounted(() => {
    if (route.query.username && route.query.token) {
      activate(route.query.username, route.query.token);
  } else {
    router.push({ path: "/" });
  }
});

const activate = async (username, token) => {
  try {
    const response = await axios.post(`/account/activate`, {username, token});
    if (response && response.status === 204) {
      isSuccess.value = true;
    }
  } catch (e) {
    console.error(e);
  }
}

const toLogin = () => {
  router.push({name: "LoginPassword"});
}
</script>
